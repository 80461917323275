import getDate from "./getDate";
import {GOOGLE_API_KEY, GOOGLE_CLIENT_ID} from "../config/constants";
import {getCookie} from "../components/auth/functions";
const CLIENT_ID = GOOGLE_CLIENT_ID;
const API_KEY = GOOGLE_API_KEY;
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';
const token = getCookie('sessionid')
let SHEET;
let client;
let access_token;
let tokenClient;
let gapiInited = false;
let gisInited = false;



function gapiLoaded() {
  window.gapi.load('client', initializeGapiClient);
  // window.gapi.client.setToken(token)
}
  async function initializeGapiClient() {
      await window.gapi.client.init({
        // client_id: CLIENT_ID,
        discoveryDocs: [DISCOVERY_DOC],
        // scope:SCOPES
      });
      gapiInited = true;
    }
function gisLoaded() {
      tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
      gisInited = true;
}

async function handleAuthClick(data,typeOfSheet) {

  return new Promise((resolve, reject) => {
    let stuff_data = data;
    let stuff_typeOfSheet= typeOfSheet;
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        // console.log("response issue")
        console.log(resp.error);
        reject(resp.error)
      }
      try{
        let link = generateGoogleSheet(stuff_data, stuff_typeOfSheet);
        // console.log(`Handle Link:${link}`)
        resolve(link)
      }catch(error){
        console.log(error);
        reject(error)
      }
    };

    if (window.gapi.client.getToken() === null) {
      console.log("In null token")
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      console.log("Token found")
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({prompt: ''});
    }
  });
}

const generateGoogleSheet = (data, typeOfSheet) => {

  const createSheet = async () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    let link = ''

    // SHEET = window.gapi.client.sheets.spreadsheets;
    SHEET = window.gapi.client.sheets.spreadsheets;
    try{
      const response = await SHEET.create({ // Use await to wait for the promise to resolve
        properties: {
          title: typeOfSheet === 'branches' ? `Phoninbook Branch Directory - ${today}` : `Phoninbook Oninite Directory - ${today}`
        }
      });
      typeOfSheet === 'branches' ? generateBranchSheetData(response) : generateOniniteSheetData(response);
      // console.log(`https://docs.google.com/spreadsheets/d/${response.result.spreadsheetId}`);
      link = `https://docs.google.com/spreadsheets/d/${response.result.spreadsheetId}`;
      return link; // Now this will return the updated link
    }catch(error){
      console.error("Error creating spreadsheet", error);
      return ""
    }
  }

  //Generate data for Branch List Download
  const branchDataEntries = data.branchData.map(branch => {
    let empList = '';
    for (let ent of data.entData) {
      if (ent.id === branch.entity_id) {
        if (ent.parent_entity_id !== 0) {
          let corpList = '';

          for (let corp of data.corpData) {
            if (branch.corps) {
              branch.corps.forEach(b_corp => {
                if (corp.corp_ss_id === b_corp.corp_ss_id) {
                  corpList += `${corp.corp_name}, \n`
                }
              })
            }
          }
          for (let parEnt of data.entData) {
            if (parEnt.id === ent.parent_entity_id) {
              if (!branch.inactive_ind && branch.display_ind === 1) {
                if (branch.employees) {
                  for (let emp of data.oniniteData) {
                    if (branch.employees.includes(emp.id)) {
                      let startDateStatus = getDate(emp?.start_date);
                      if (startDateStatus === 'now' && !emp.inactive_ind) {
                        empList += `${emp.first_name} ${emp.last_name}, \n`
                      }
                    }
                  }
                }

                return (
                  [branch.nickname ? branch.nickname : branch.name, branch.code, corpList, parEnt.nickname?parEnt.nickname:parEnt.name,ent.nickname?ent.nickname:ent.name, (branch.addr.address_1 + (branch.addr.address_2 ? "\n" + branch.addr.address_2 : "") + "\n" + branch.addr.city + ", " + branch.addr.state_abbrv + " " + branch.addr.zip), branch.addr.state_abbrv, branch.phone, branch.email, branch.fax, empList]
                )
              }
            }
          }
        }else if (!branch.inactive_ind && branch.display_ind === 1) {
          let corpList = '';

          for (let corp of data.corpData) {
            if (branch.corps) {
              branch.corps.forEach(b_corp => {
                if (corp.corp_ss_id === b_corp.corp_ss_id) {
                  corpList += `${corp.corp_name}, \n`
                }
              })
            }
          }
          if (branch.employees) {
            for (let emp of data.oniniteData) {
              if (branch.employees.includes(emp.id)) {
                let startDateStatus = getDate(emp?.start_date);
                if (startDateStatus === 'now' && !emp.inactive_ind) {
                  empList += `${emp.first_name} ${emp.last_name}, \n`
                }
              }
            }
          }
          return (
            [branch.nickname ? branch.nickname : branch.name, branch.code, corpList, ent.nickname?ent.nickname:ent.name,"", (branch.addr.address_1 + (branch.addr.address_2 ? "\n" + branch.addr.address_2 : "") + "\n" + branch.addr.city + ", " + branch.addr.state_abbrv + " " + branch.addr.zip), branch.addr.state_abbrv, branch.phone, branch.email, branch.fax, empList]
          )
        }
      }
    }
  })
  
  //Actually generate Branch List from G-sheet API
  const generateBranchSheetData = response => {
    let values = [["Branch Name", "Branch Code", "Corporation", "Region Name", "Area or Sub-Region Name", "Address", "State", "Phone Number", "Email", "Fax", "Oninites"]];
    for (let branch of branchDataEntries) {
      values.push(branch);
    }

    let body = {
      values: values
    };
    SHEET.values.update({
      spreadsheetId: response.result.spreadsheetId, 
      range: "Sheet1!A:L",
      valueInputOption: 'RAW',
      resource: body
    }).then(() => {
      formatColumnWidths(response);
    })
  }

  //Generate Data for Oninite List Download
  const oniniteDataEntries = data.oniniteData.map(emp => {
    let branchList = '';
    let homeBranch;
    let department;
    let startDateStatus = getDate(emp?.start_date);
    for (let branch of data.branchData) {
      if (emp.branches) {
        for (let empBranch of emp.branches) {
          if (empBranch.branch_id === branch.id && empBranch.home_branch_ind === true) {
            if (!branch.inactive_ind && branch.display_ind === 1) {
              homeBranch = branch.nickname ? branch.nickname : branch.name;
            }
          }
          if (empBranch.branch_id === branch.id && !branch.inactive_ind && branch.display_ind === 1) {
            branchList += `${branch.nickname ? branch.nickname : branch.name}, \n`;
          }
        }
      }
      for (let dept of data.deptData) {
        if (dept.id === emp.department_id) {
          department = dept.name;
        }
      }
    }
    if (startDateStatus === 'now') {
      return (
        [emp.first_name, emp.last_name, emp.title, department, homeBranch, emp.email, (emp.display_phone ? emp.phone : ""), emp.manager.first_name + " " + emp.manager.last_name, branchList]
      )
    }
  })

  //Actually generate the Oninite List with the g-sheet API
  const generateOniniteSheetData = response => {
    let values = [["Oninite First Name (Preferred)", "Oninite Last Name", "Job Title", "Department", "Home Branch", "Email", "Phone Number", "Reports to:", "Branches"]];
    for (let emp of oniniteDataEntries) {
      values.push(emp);
    }

    let body = {
      values: values
    };
    SHEET.values.update({
      spreadsheetId: response.result.spreadsheetId, 
      range: "Sheet1!A:I",
      valueInputOption: 'RAW',
      resource: body
    }).then(() => {
      formatColumnWidths(response);
    })
  }

  const formatColumnWidths = response => {
    SHEET.batchUpdate({
      spreadsheetId: response.result.spreadsheetId,
      resource: {
        requests: [{
          autoResizeDimensions: {
            dimensions: {
              dimension: 'COLUMNS',
              startIndex: 0
            }
          }

        }]
      }
    }).then(() => {
      window.open(`https://docs.google.com/spreadsheets/d/${response.result.spreadsheetId}`, '_blank');
    })
  }

  //kickoff the download process
  let spreadsheetlink =""
  createSheet().then((link)=>{spreadsheetlink = link});
  return spreadsheetlink;
}


export {handleAuthClick,gapiLoaded,gisLoaded,generateGoogleSheet}