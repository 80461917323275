import React from "react";
import '../styles/styles.css';

const Header = ({type, view, empID, children, email}) => {
  const navigateWhoContact = () => {
    window.open(`https://sites.lumapps.com/a/oningroup/ozone/who-to-contact`, '_blank');
  }
  if (type === 'primary') {
    return (
      <div className={`listHeaderContainer ${view === 'desktop' ? 'stickyHeader' : 'stickyHeaderMobile'}`}>
        <p className='listHeader'>{children}</p>
      </div>
    )
    }else if (type === 'lvl1') {
    return (
      <div className={`listHeaderContainer_lvl1 ${view === 'desktop' ? 'stickyHeader_lvl1' : 'stickyHeaderMobile'}`}>
        <p className='listHeader_lvl1'>{children}</p>
      </div>
    )
  }else if (type === 'lvl2') {
    return (
      <div className={`listHeaderContainer_lvl2 ${view === 'desktop' ? 'stickyHeader_lvl2' : 'stickyHeaderMobile_subRegion'}`}>
        <p className='listHeader_lvl2'>{children}</p>
      </div>
    )
  }else if (type === 'lvl3') {
    return (
      <div className={`listHeaderContainer_lvl3 ${view === 'desktop' ? 'stickyHeader_lvl3' : 'stickyHeaderMobile_subRegion'}`}>
        <p className='listHeader_lvl3'>{children}</p>
      </div>
    )
  }else if (type === 'lvl4') {
    return (
      <div className={`listHeaderContainer_subRegion2 ${view === 'desktop' ? 'stickyHeader_lvl4' : 'stickyHeaderMobile_subRegion'}`}>
        <p className='listHeader_subRegion'>{children}</p>
      </div>
    )
  }else if (type === 'lvl5') {
    return (
      <div className={`listHeaderContainer_subRegion2 ${view === 'desktop' ? 'stickyHeader_lvl5' : 'stickyHeaderMobile_subRegion'}`}>
        <p className='listHeader_subRegion'>{children}</p>
      </div>
    )
  }else if (type === 'primaryHomeOffice') {
    return (
      <div className={`listHeaderContainer_homeOffice ${view === 'desktop' ? 'stickyHeader_BranchDetail' : 'stickyHeaderMobile_Detail'}`}>
        <div className="homeOfficeListItemContainer">
          <p className='listHeader_homeOffice'>{children}</p>
          <div className="homeOfficeWhoContactContainer" onClick={navigateWhoContact}>
            <p className='listHeader_homeOffice listHeader_homeOffice_Link'>Who to Contact</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path id="Icon_material-open-in-new" data-name="Icon material-open-in-new" d="M15.167,15.167H5.833V5.833H10.5V4.5H5.833A1.333,1.333,0,0,0,4.5,5.833v9.333A1.333,1.333,0,0,0,5.833,16.5h9.333A1.337,1.337,0,0,0,16.5,15.167V10.5H15.167ZM11.833,4.5V5.833h2.393L7.673,12.387l.94.94,6.553-6.553V9.167H16.5V4.5Z" transform="translate(-4.5 -4.5)" fill="#fff"/>
            </svg>
          </div>
        </div>
      </div>
    )
  }else if (type === 'subHomeOffice') {
    return (
      <div className={`listHeaderContainer_subRegion ${view === 'desktop' ? 'stickyHeader_subHomeOffice' : 'stickyHeaderMobile_subHomeOffice'}`}>
        <p className='listHeader_subRegion'>{children}</p>
      </div>
    )
  }else if (type === 'subHomeOffice_dept') {
    return (
      <div className={`listHeaderContainer_homeOffice_IT ${view === 'desktop' ? 'stickyHeader_subHomeOffice' : 'stickyHeaderMobile_subHomeOffice'}`}>
        <div className="homeOfficeListItemContainer">
          <p className='listHeader_homeOffice_IT'>{children}</p>
          <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${email}`} className='listHeader_homeOffice_IT' target='_blank'>{email}</a>
        </div>
      </div>
    )
  }
  else if (type === 'branchDetail') {
    return (
      <div className={`listHeaderContainer ${view === 'desktop' ? 'stickyHeader_BranchDetail' : 'stickyHeaderMobile_Detail'}`}>
        <p className='listHeader'>{children}</p>
      </div>
    )
  }else if (type === 'oniniteDetail') {
    return (
      <div className={`listHeaderContainer ${view === 'desktop' ? 'stickyHeader_OniniteDetail' : 'stickyHeaderMobile_Detail'} ${(empID === 1 || empID === 2271) ? 'rickOrcuttHeader' : ''}`}>
        <p className='listHeader'>{children}</p>
      </div>
    )
  }else if (type === 'oniniteDetailPhone') {
    return (
      <div className={`listHeaderContainer ${view === 'desktop' ? 'stickyHeader_OniniteDetail_Phone' : 'stickyHeaderMobile_Detail'} ${(empID === 1 || empID === 2271) ? 'rickOrcuttHeader' : ''}`}>
        <p className='listHeader'>{children}</p>
      </div>
    )
  } else {
    return (
      <div className={`listHeaderContainer_subRegion2 ${view === 'desktop' ? 'stickyHeader_lvl5' : 'stickyHeaderMobile_subRegion'}`}>
        <p className='listHeader_subRegion'>{children}</p>
      </div>
    )
  }
}

export default Header;