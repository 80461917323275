import React, {useState, useEffect} from "react";
import { useLocation, useNavigate, useParams } from 'react-router';
import BranchCard from "./BranchCard";
import OniniteCard from "./OniniteCard";
import SearchBar from "./SearchBar";
import Header from "./Header";
import EditButton from "./subcomponents/EditButton";
import DeactivateButton from "./subcomponents/DeactivateButton";
import getBranchIcon from "../functions/branchIcon";
import getDate from "../functions/getDate";

import inactiveFilter from '../assets/sort-icon-deactivate.svg';
import backButton from '../assets/desktopBackButton.svg';
import {ORG_API_KEY, ORG_API_URL} from '../config/constants';

const BranchDetail = ({view, data, admin, userType}) => {
  let state = useLocation().state;
  let navigate = useNavigate();
  let params = useParams();
  let increment = 0;
  let branchDeciderID; //Get the branch's id to determine if Home Office distinct page needs to show
  let oniniteCardList;
  let branchObject;
  let regionObject;
  let subEntityObject;
  let branchIcon = [];
  let branch;
  let subEntity;
  let ssBranch;
  let corpName = '';
  let rollupName;

  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false);
  const [showReopenModal, setShowReopenModal] = useState(false);

  const [deactivateSubmit, setDeactivateSubmit] = useState(false);
  const [transferBranch, setTransferBranch] = useState("");
  const [transferBranchErr, setTransferBranchErr] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  let nodeOptions = data.branchData.map(branch => {
    if (!branch.inactive_ind && branch.display_ind === 1) {
      return <option value={branch.id} key={branch.id}>{branch.nickname ? branch.nickname : branch.name}</option>
    }
  });

  let oniniteList = [];
  let oniniteListWithHomeBranch = [];

  //Get a list of oninites for the given branch depending upon how the user got to this page -> search result or from branches main page
  for (let emp of data.oniniteData) {
    if (state) {
      branchDeciderID = state.branch.id;
      if (state.branch?.employees?.includes(emp.id)) {
        oniniteList.push(emp);
        if (emp.branches) {
          for (let branch of emp.branches) {
            if (branch.branch_id === branchDeciderID && branch.home_branch_ind) {
              oniniteListWithHomeBranch.push(emp);
            }
          }
        }
      }
    }else {
      branchObject = data.branchData.find(branch => branch.id == params.branch);
      branchDeciderID = branchObject.id;
      // branchIcon = getBranchIcon(branchObject.name, branchObject?.corp?.corp_ss_id);
      if (branchObject.corps?.length > 0) {
        branchIcon = branchObject.corps.map(corp => {
          return getBranchIcon(branchObject.name, corp?.corp_ss_id);
        })
      }else {
        branchIcon.push(getBranchIcon(branchObject.name, ""));
      }
      if (branchObject?.employees?.includes(emp.id)) {
        oniniteList.push(emp);
        if (emp.branches) {
          for (let branch of emp.branches) {
            if (branch.branch_id === branchDeciderID && branch.home_branch_ind) {
              oniniteListWithHomeBranch.push(emp);
            }
          }
        }
      }
    }
  }
  
  //Set the branch object to be used elsewhere
  branch = state ? state.branch : branchObject;
  
  for (let ssbranch of data.ssBranchData) {
    if (ssbranch.branch_id === branch.ss_id) {
      ssBranch = ssbranch.branch_name;
    }
  };
  for (let corp of data.corpData) {
    if (branch.corps) {
      for (let brCorp of branch?.corps) {
        if (brCorp.corp_ss_id === corp.corp_ss_id) {
          corpName += corp.corp_name + ';' + ' ';
        }
      }
    }
  }
  if (branch.rollup_node_id) {
    for (let rollBranch of data.branchData) {
      if (rollBranch.id === branch.rollup_node_id) {
        rollupName = rollBranch.name;
      }
    }
  }

  if (!state) {
    for (let region of data.orgData) {
      if (region.nodes) {
        for (let branch of region.nodes) {
          if (branch.id == branchObject.id) {
            regionObject = region;
          }
        }
      }
      if (region.entities) {
        for (let entity of region.entities) {
          if (entity.nodes) {
            for (let branch of entity.nodes) {
              if (branch.id == branchObject.id) {
                regionObject = region;
                subEntityObject = entity;
              }
            }
          }
          if (entity.entities) {
            for (let subEntity of entity.entities) {
              if (subEntity.nodes) {
                for (let branch of subEntity.nodes) {
                  if (branch.id == branchObject.id) {
                    regionObject = region;
                    subEntityObject = subEntity;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  subEntity = state ? state.subEntity : subEntityObject;

  const goToEdit = () => {
    navigate(`/EditBranch/${branch.id}`, {state: {region: (state ? state.region : regionObject), subEntity: subEntity, branchIcon: (state ? state.branchIcon : branchIcon), branch: branch}});
  }

  //Not Home Office
  if (branchDeciderID !== 208) {
    oniniteCardList = oniniteList.map(emp => {
      let startDateStatus = getDate(emp?.start_date);
      if (startDateStatus === 'now' || admin === 'yes') {
        increment++;
        return (
          <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment}/>
        )
      }
    });
  }else if (branchDeciderID === 208) {
    oniniteCardList = data.deptData.map(dept => {
      let okHeaderStatus = false;
      let oninites = oniniteList.map(emp => {
        let startDateStatus = getDate(emp?.start_date);
        if (emp.department_id === dept.id) {
          if (startDateStatus === 'now' || admin === 'yes') {
            increment++;
            okHeaderStatus = true;
            return (
              <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment}/>
            )
          }
        }
      })
      return (
        <>
          {okHeaderStatus && dept.name.includes('IT/Support') && <Header type="subHomeOffice_dept" email='support@oningroup.com' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Accounting') && <Header type="subHomeOffice_dept" email='accounting@oningroup.com ' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Accounts Receivable') && <Header type="subHomeOffice_dept" email='ar@oningroup.com ' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Strategic Growth') && <Header type="subHomeOffice_dept" email='fieldsales@oningroup.com' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Marketing') && <Header type="subHomeOffice_dept" email='marketing@oningroup.com' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('National Accounts') && <Header type="subHomeOffice_dept" email='nationalaccounts@oningroup.com' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('People & Culture') && <Header type="subHomeOffice_dept" email='hrservices@oningroup.com' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Risk') && <Header type="subHomeOffice_dept" email='risk@oningroup.com' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Accounts Payable') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Focus') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Branch Operations') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Child Support') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Executive Team') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Momentum Capital Funding') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Offsite') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Payroll') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {okHeaderStatus && dept.name.includes('Workforce') && <Header type="subHomeOffice_dept" email='' view={view} key={dept.id}>{dept.name}</Header>}
          {oninites}
        </>
      )
    })
  }


  // --------------------------------------------------- Close Branch Admin Logic --------------------------------------------------------------- \\

  const handleDeactivateInitial = () => {
    if (oniniteListWithHomeBranch.length !== 0) {
      setTransferBranch("");
      setTransferBranchErr(false);
      setShowCloseModal(true);
    }else {
      //indicates that all total branches have been reassigned
      setShowCloseConfirmModal(true);
    }
  }

  const handleSuccess = () => {
    transferBranch ?  navigate(`/Branch/${transferBranch}`) : navigate(`/Branches`);
    window.location.reload();
  }

  const handleFailure = () => {
    window.location.reload();
  }

  const handleCloseConfirmation = () => {
    setShowCloseModal(false);
    setShowCloseConfirmModal(true);
  }

  useEffect(() => {
    if (transferBranchErr) {
      transferBranch !== "" ? setTransferBranchErr('none') : setTransferBranchErr('yes');
    }
  }, [transferBranch]);

  const handleCloseSubmit = () => {
    transferBranch === "" ? setTransferBranchErr('yes') : setTransferBranchErr('none');

    if (deactivateSubmit) {
      (async () => {
        setDeactivateSubmit(false);
      })().then(() => setDeactivateSubmit(true))
    }else {
      setDeactivateSubmit(true);
    }
  }

  useEffect(() => {
    if (deactivateSubmit) {
      if (transferBranchErr === 'none') {
        handleCloseConfirmation();
      }
    }
  }, [deactivateSubmit]);

  const handleCloseFinal = () => {
    setShowCloseConfirmModal(false);
    sendBranchCloseAPI();
  }

  const sendBranchCloseAPI = async () => {
    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          old_id: branch.id,
          new_id: Number(transferBranch) ?? 0
        })
      });
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  //Reopening a Branch
  const handleOpenFinal = () => {
    setShowReopenModal(false);
    sendBranchOpenAPI();
  }

  const sendBranchOpenAPI = async () => {
    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          id: branch.id,
          ss_id: branch.ss_id,
          name: branch.name,
          display_name: branch.nickname,
          code: branch.code,
          phone: branch.phone,
          fax: branch.fax,
          email: branch.email,
          corps: branch.corps,
          entity_id: branch.entity_id,
          rollup_id: branch?.rollup_node_id ?? 0,
          address_1: branch?.addr?.address_1 ?? '',
          address_2: branch?.addr?.address_2 ?? '',
          city: branch?.addr?.city ?? '',
          state_abbrv: branch?.addr?.state_abbrv ?? '',
          zip_code: branch?.addr?.zip ?? '',
          display_ind: branch.display_ind,
          inactive_ind: false,
          manager_id: branch?.manager_id,
          node_type_id: branch.node_type_id
        })
      });
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {view === 'mobile' &&
        <>
          <div className='mobileSearchFilterContainerDetailPage'>
            <SearchBar data={data}/>
            <img src={inactiveFilter} className='mobileFilterButtonInactive' alt='filter'/>
          </div>
          <BranchCard view={view} admin={admin} data={data} region={state ? state.region : regionObject} subEntity={subEntity} branch={branch} branchIcon={state ? state.branchIcon : branchIcon} increment={1}/>
        </>
      }
      {view === 'desktop' && admin !== 'yes' &&
        <>
          <div className="desktopBackButtonContainer">
            <img src={backButton} alt='back button' style={{ cursor: "pointer" }} onClick={() => navigate(-1)}/>
          </div>
          <div className="detailHeaderStickyContainer">
            <BranchCard view={view} admin={admin} data={data} region={state ? state.region : regionObject} subEntity={subEntity} branch={branch} branchIcon={state ? state.branchIcon : branchIcon} increment={1}/>
          </div>
        </>
      }
      {view === 'desktop' && admin === 'yes' &&
        <>
          <div className="desktopBackButtonContainer">
            <img src={backButton} alt='back button' style={{ cursor: "pointer" }} onClick={() => navigate(-1)}/>
          </div>
          <div className="detailHeaderStickyContainer">
            <BranchCard view={view} admin={admin} data={data} region={state ? state.region : regionObject} subEntity={subEntity} branch={branch} branchIcon={state ? state.branchIcon : branchIcon} increment={1} onBranchDetail={true}/>
          </div>
          <div className="branchDetailAdminInfoContainer">
            <div className="branchDetailGreyDivider"></div>
            <div className="branchDetailAdminInnerContainer">
              <div>
                <p className="branchDetailAdminHeader">Branch Status: {!branch.inactive_ind ? <span className="branchDetailOpenBranch">Open</span> : <span className="branchDetailClosedBranch">Closed</span>}</p>
                <p className="branchDetailAdminHeader">Branch Type: <span className="branchDetailHeaderContent">{branch.node_type_name}</span></p>
                <p className="branchDetailAdminHeader">StaffSuite Branch Name: <span className="branchDetailHeaderContent">{ssBranch ?? 'None'}</span></p>
                <p className="branchDetailAdminHeader">Corporation: <span className="branchDetailHeaderContent">{corpName}</span></p>
                <p className="branchDetailAdminHeaderFinal">Revenue Rolls up to: <span className="branchDetailHeaderContent">{rollupName ?? 'None'}</span></p>
              </div>
              <div className="branchDetailAdminButtonContainer">
                {!branch.inactive_ind && <EditButton type='detail' clickRoute={goToEdit}/>}
                {!branch.inactive_ind && userType === 'admin' && <div style={{ marginLeft: '20px' }}><DeactivateButton clickRoute={handleDeactivateInitial}>Close</DeactivateButton></div>}
                {branch.inactive_ind && userType === 'admin' &&
                  <div className='reopenButtonContainer' onClick={() => setShowReopenModal(true)}>
                    <p>Reopen</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="reopenButton" width="14" height="12" viewBox="0 0 14 12">
                      <path id="Icon_material-settings-backup-restore" className="reopenButton" data-name="Icon material-settings-backup-restore" d="M9.333,10.5A1.333,1.333,0,1,0,8,11.833,1.337,1.337,0,0,0,9.333,10.5ZM8,4.5a6,6,0,0,0-6,6H0l2.667,2.667L5.333,10.5h-2a4.67,4.67,0,1,1,1.96,3.8l-.947.96A6,6,0,1,0,8,4.5Z" transform="translate(0 -4.5)" fill="#004987"/>
                    </svg>
                  </div>
                }
              </div>
            </div>
            {showSuccessModal && 
              <div className='supportConfirmModal'>
                <div className='supportConfirmModalContent'>
                  <p className='supportConfirmModalText'>Branch successfully {!branch.inactive_ind ? 'closed' : 'reopened'}!</p>
                  <button className='supportConfirmModalButton' onClick={handleSuccess}>Ok</button>
                </div>
              </div>
            }
            {showFailureModal && 
              <div className='supportConfirmModal'>
                <div className='supportConfirmModalContent'>
                  <p className='supportConfirmModalText'>Branch unsuccessfully {!branch.inactive_ind ? 'closed' : 'reopened'}. Please try again.</p>
                  <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
                </div>
              </div>
            }
            {showCloseModal && 
              <div className='editRegionDeactivateModal'>
                <div className='editRegionDeactivateModalContent'>
                  <p className='deactivateHeader'>Close {branch.nickname ? branch.nickname : branch.name}</p>
                  <svg id="modal-close-btn" className='xClose' xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" onClick={() => setShowCloseModal(false)}>
                    <path id="Icon_material-close" className='xClose' data-name="Icon material-close" d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z" transform="translate(7.5 7.5)" fill="#a1a3a6"/>
                  </svg>
                  <p className='deactivateMainText'>There are currently Ōninites with this branch as their home branch. Please reassign the Ōninites' home branches to their respective new one or select a branch below to reassign all Ōninites to:</p>
                  <label htmlFor='deactivateRegionsSelect'>Branch:</label>
                  <div className="deactivateRegionsSelect">
                    <select id='deactivateRegionsSelect' className={`branchInput ${transferBranchErr === 'yes' && 'errorInputBorder'}`} value={transferBranch} onChange={(e) => setTransferBranch(e.target.value)} onBlur={(e) => setTransferBranch(e.target.value)}>
                      <option value="">Select</option>
                      {nodeOptions}
                    </select>
                    {transferBranchErr === 'yes' && <p className='errInputSubText'>Please select a branch</p>}
                  </div>
                  <p className='deactivateMainText'>Once closed, this branch will no longer be visible in the PhōninBook to anyone but admins.</p>
                  <p className='deactivateMainText'>Please make sure the proper departments are aware or have been notified of the closing.</p>
                  <button className='branchDetailCloseModalButton' onClick={handleCloseSubmit}>Close Branch</button>
                </div>
              </div>
            }
            {showCloseConfirmModal &&
              <div className='editRegionDeactivateModal'>
                <div className='editRegionDeactivateModalContent'>
                  <p className='deactivateHeader'>Close {branch.nickname ? branch.nickname : branch.name}</p>
                  <svg id="modal-close-btn" className='xClose' xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" onClick={() => setShowCloseConfirmModal(false)}>
                    <path id="Icon_material-close" className='xClose' data-name="Icon material-close" d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z" transform="translate(7.5 7.5)" fill="#a1a3a6"/>
                  </svg>
                  <p className='deactivateMainText'>You are about to close {branch.nickname ? branch.nickname : branch.name}.</p>
                  <p className='deactivateMainText'>This means that this branch will no longer be visible in the PhōninBook to anyone but admins.</p>
                  <p className='deactivateMainText'>Please make sure the proper departments are aware or have been notified of the closing.</p>
                  <button className='branchDetailCloseModalButton' onClick={handleCloseFinal}>Close Branch</button>
                </div>
              </div>
            }
            {showReopenModal && 
              <div className='editRegionDeactivateModal'>
                <div className='editRegionDeactivateModalContent'>
                  <p className='deactivateHeader'>Reactivate Branch</p>
                  <svg id="modal-close-btn" className='xClose' xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" onClick={() => setShowReopenModal(false)}>
                    <path id="Icon_material-close" className='xClose' data-name="Icon material-close" d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z" transform="translate(7.5 7.5)" fill="#a1a3a6"/>
                  </svg>
                  <p className='deactivateMainText'>You are about to reactivate a branch. This means this branch will now be visible again in the PhōninBook to everyone.</p>
                  <p className='deactivateMainText'>Please make sure the proper departments are aware or have been notified of this reopening.</p>
                  <p className='deactivateMainText'>If any information has changed since closing, please make sure to edit the branch after reactivation.</p>
                  <button className='branchDetailReopenModalButton' onClick={handleOpenFinal}>Open Branch</button>
                </div>
              </div>
            }
          </div>
        </>
      }
      <Header type={branchDeciderID === 208 || branchDeciderID === 212 ? 'primaryHomeOffice' : 'branchDetail'} view={view}>Ōninites</Header>
      {oniniteCardList}
    </>
  )
}

export default BranchDetail;