import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilValue, useResetRecoilState } from "recoil";
import {nameState, photoState, emailState, permissionState, loggedInState} from '../data/atoms';
import logo from '../assets/PhoninBook-Light-Desktop.svg';
import gizmos from '../assets/gizmosLogo.svg';
import logoutImage from '../assets/logout.svg';
import '../styles/styles.css';
import SearchBar from './SearchBar';

const NavBar = ({data}) => {
  const [oActive, setOActive] = useState("");
  const [bActive, setBActive] = useState("");
  const [photoClicked, setPhotoClicked] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  const userName = useRecoilValue(nameState);
  const userPhoto = useRecoilValue(photoState);
  const userEmail = useRecoilValue(emailState);
  const resetName = useResetRecoilState(nameState);
  const resetEmail = useResetRecoilState(emailState);
  const resetPerms = useResetRecoilState(permissionState);
  const resetLogged = useResetRecoilState(loggedInState);
  const resetPhoto = useResetRecoilState(photoState);

  useEffect(() => {
    if (location.pathname === '/Branches' || location.pathname === '/') {
      setBActive("activeLink");
      setOActive("");
    }else if (location.pathname === '/Oninites') {
      setOActive("activeLink");
      setBActive("");
    }else {
      setOActive("");
      setBActive("");
    }

  }, [location.pathname]);

  const travel = (location) => {
    navigate(location);
    window.scrollTo(0,0);
  }

  const goHome = () => {
    navigate('/Branches');
    window.scrollTo(0,0);
  }

  const logout = () => {
    resetEmail();
    resetLogged();
    resetName();
    resetPerms();
    resetPhoto();
    window.location.href = `https://auth.oningizmos.com/accounts/logout/?next=${window.location.href}`;
  }

  return (
    <>
      <div className="gizmosHeader">
        <img src={gizmos} className="logo"/>
        <div className={'logoutContainer'}>
            <img src={userPhoto} className={'userPhoto'} onClick={() => setPhotoClicked(!photoClicked)}/>
            {photoClicked &&
              <div className={'userInfoContainer'}>
                <div style={{ display: 'flex', padding: '11px 16px' }}>
                  <img src={userPhoto} className={'userPhotoNonClick'}/>
                  <div>
                    <p style={{ fontWeight: 'bold', marginBottom: '3px' }}>{userName}</p>
                    <p>{userEmail}</p>
                  </div>
                </div>
                <div className={'logoutInnerContainer'}>
                  <div className={'logoutInnerInnerContainer'} onClick={logout}>
                    <img src={logoutImage}/>
                    <p className={'logoutText'}>Logout</p>
                  </div>
                </div>
              </div>
            }
          </div>
      </div>
      <nav className='deskNav'>
        <img src={logo} className='mainLogo' width="255px" alt='logo' onClick={goHome}/>
        <SearchBar data={data}/>
        <div className='navLinkContainer'>
          <a className='navItem' id={bActive} onClick={() => travel('/Branches')}>Branches</a>
          <a className='navItem' id={oActive} onClick={() => travel('/Oninites')}>Ōninites</a>
        </div>
      </nav>
    </>
  )
}

export default NavBar;