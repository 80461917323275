import React, {useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BranchCard from './BranchCard';
import Header from './Header';
import SearchBar from './SearchBar';

import {getCombinedBranchList} from '../functions/combinedBranches';
import getBranchIcon from '../functions/branchIcon';
import { handleAuthClick,gapiLoaded,gisLoaded } from '../functions/sheetDownload';
import { EntityStructure } from '../functions/BranchesFunctions';
import { getCorps, getOninites, getOrgStruct } from '../functions/dataCalls';

import filterButton from '../assets/sort-icon.svg';
import addBranch from '../assets/addNewBranch.svg';
import manageRegion from '../assets/manageRegions.svg';
import '../styles/styles.css';

const Branches = ({view, data, admin, userType}) => {
  const [sortValue, setSortValue] = useState(sessionStorage.getItem('sort') ?? 'Region');
  const [adminSortValue, setAdminSortValue] = useState(sessionStorage.getItem('adminSort') ?? 'Physical');
  const [adminSelectListDisplay, setAdminSelectListDisplay] = useState(false);
  const [selectListDisplay, setSelectListDisplay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showGoogleModal, setShowGoogleModal] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [downloadMsg, setDownloadMsg] = useState("");
  let increment = 0;
  let navigate = useNavigate();
  let branchListItems;

  //When changing the sort value, the page will jump to the top of the page
  const notInitialRender = useRef(false);
  useEffect(() => {
    if(notInitialRender.current) {
      window.scrollTo(0,0);
    }else {
      notInitialRender.current = true;
    }
  }, [sortValue]);
  
  // Initialize Google Client Libraries on load
  useEffect(() => {
    gisLoaded();
    gapiLoaded();
  }, []);
  
  // Currently not working
  useEffect(() => {
    if (downloadLink !=""){
      // console.log("in Use effect")
      // setShowGoogleModal(true);
    }
  }, [downloadLink,downloadMsg]);
  
  //Sorting by Region
  if (sortValue === 'Region') {
    //Loop through all of the top level regions
    branchListItems = data.orgData.map((region) => {
      let branchList;
      let entityList;
      
      //If the region has branches directly under it, loop through them
      if (region.nodes) {
        // TP 5.09.2024
        // branchList = region.nodes.sort((a,b) => ((a.name.toLowerCase()) < (b.name.toLowerCase())) ? -1 : 1).map((branch) => {
        branchList = region.nodes.sort((a,b) => ((a.nickname?a.nickname.toLowerCase():a.name.toLowerCase()) < (b.nickname?b.nickname.toLowerCase():b.name.toLowerCase())) ? -1 : 1).map((branch) => {
          // console.log(branch.name)
          let branchIcon = [];
          let inactiveStatus;
          let displayStatus;
          let addressStatus = false;
          if (branch.corps?.length > 0) {
            branchIcon = branch.corps.map(corp => {
              return getBranchIcon(branch.name, corp?.corp_ss_id)
            })
          }else {
            branchIcon.push(getBranchIcon(branch.name, ""));
          }

          //Determining whether or not the branch should be displayed based on the user's admin status and the admin sort filters applied
          if (admin === 'yes' && adminSortValue === 'Physical') {
            inactiveStatus = !branch.inactive_ind;
            displayStatus = branch.display_ind === 1;
          }else if (admin === 'yes' && adminSortValue === 'Virtual') {
            inactiveStatus = !branch.inactive_ind;
            displayStatus = 1;
            if (branch.node_type_id===3) {
              addressStatus = true;
            }
          }else if (admin === 'yes' && adminSortValue === 'Open') {
            inactiveStatus = !branch.inactive_ind; //to denote that all locations should be true
            displayStatus = 1;
          }else if (admin === 'yes' && adminSortValue === 'Closed') {
            inactiveStatus = branch.inactive_ind;
            displayStatus = 1;
          }else {
            inactiveStatus = !branch.inactive_ind;
            displayStatus = branch.display_ind === 1;
            if (!Object.values(branch.addr).every(addr => addr === '')) {
              addressStatus = true;
            }
          }

          if (inactiveStatus && displayStatus && ((adminSortValue === 'Virtual' || admin !== 'yes') ? addressStatus : 1)) {
            increment++;
            return (
              <BranchCard view={view} admin={admin} data={data} region={region} branch={branch} increment={increment} branchIcon={branchIcon} key={branch.id}/>
            )
          }
      })}
  
      //If the region has sub-entities/areas, loop through them
      if (region.entities) {
        entityList = region.entities.map((entity) => {
            return <EntityStructure entity={entity} adminSortValue={adminSortValue} admin={admin} data={data} view={view} region={region} increment={increment} levelsDeep={0}/>
        })}
  
      //Determine whether or not the region headers should show based on the admin sort value and whether or not the region has direct nodes or subentities
      let entityHeaderOkStatus = false;
      if (region.nodes) {
        if (admin === 'yes' && adminSortValue === 'Physical' && region.nodes.some(node => !node.inactive_ind && node.display_ind === 1)) {
          entityHeaderOkStatus = true;
        }else if (admin === 'yes' && adminSortValue === 'Virtual') {
          if (region.nodes.find(node => (node.node_type_id===3) && !node.inactive_ind)) {
            entityHeaderOkStatus = true;
          }
        }else if (admin === 'yes' && adminSortValue === 'Open' && region.nodes.some(node => !node.inactive_ind)) {
          entityHeaderOkStatus = true;
        }else if (admin === 'yes' && adminSortValue === 'Closed' && region.nodes.some(node => node.inactive_ind)) {
          entityHeaderOkStatus = true;
        }else if (admin === 'no' && region.nodes.some(node => !node.inactive_ind && node.display_ind === 1 && (node.addr ? !Object.values(node.addr).every(addr => addr === '') : true))) {
          entityHeaderOkStatus = true;
        }
      }
      if (region.entities) {
        if (admin === 'yes' && adminSortValue === 'Physical') {
          for (let entity of region.entities) {
            if (entity?.nodes?.some(node => !node.inactive_ind && node.display_ind === 1)) {
              entityHeaderOkStatus = true;
            }
          }
        }else if (admin === 'yes' && adminSortValue === 'Virtual') {
          for (let entity of region.entities) {
            //Include branches that do not have physical addresses
            if (entity?.nodes?.find(node => (node.node_type_id===3) && !node.inactive_ind)) {
              entityHeaderOkStatus = true;
            }
          }
        }else if (admin === 'yes' && adminSortValue === 'Open') {
          for (let entity of region.entities) {
            if (entity?.nodes?.some(node => !node.inactive_ind)) {
              entityHeaderOkStatus = true;
            }
          }
        }else if (admin === 'yes' && adminSortValue === 'Closed') {
          for (let entity of region.entities) {
            if (entity?.nodes?.some(node => node.inactive_ind)) {
              entityHeaderOkStatus = true;
            }
          }
        }else if (admin === 'no') {
          for (let entity of region.entities) {
            if (entity?.nodes?.some(node => !node.inactive_ind && node.display_ind === 1 && (node.addr ? !Object.values(node.addr).every(addr => addr === '') : true))) {
              entityHeaderOkStatus = true;
            }
          }
        }
      }
  
      return (
        <>
        {entityHeaderOkStatus && 
          <Header type="lvl1" view={view} key={region.id}>{region.nickname ? region.nickname: region.name} - {region.manager.first_name} {region.manager.last_name}</Header>
        }
        <div>
          {branchList}
          {entityList}
        </div>
        </>
      )
    });
  }else if (sortValue === 'Name') {
    let combinedBranchList = getCombinedBranchList(data.orgData, admin, adminSortValue);
    const letterArr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

    branchListItems = letterArr.map(letter => {
      let combinedBranchListItems = combinedBranchList.map(branch => {
        let branchIcon = [];
        if (branch.branchKey.corps?.length > 0) {
          branchIcon = branch.branchKey.corps.map(corp => {
            return getBranchIcon(branch.branchKey.name, corp?.corp_ss_id)
          })
        }else {
          branchIcon.push(getBranchIcon(branch.branchKey.name, ""));
        }
        if (branch.branchKey.name[0].toUpperCase() === letter) {
          increment++;
          return (
            <>
              <BranchCard view={view} admin={admin} data={data} subEntity={branch?.entityKey ?? ""} region={branch.regionKey} branch={branch.branchKey} increment={increment} branchIcon={branchIcon} key={branch.branchKey.id}/>
            </>
          )
        }
      })

      let entityHeaderOkStatus = false;
      if (combinedBranchList.some(branch => branch.branchKey.name[0] === letter)) {
        entityHeaderOkStatus = true;
      }

      return (
        <>
          {entityHeaderOkStatus &&
            <Header type="primary" view={view} key={letter}>{letter}</Header>
          }
          {combinedBranchListItems}
        </>
      )
    })

  }else if (sortValue === 'State') {
    let combinedBranchList = getCombinedBranchList(data.orgData, admin, adminSortValue);
    const stateArr = [{name: "Alabama", abbrv: "AL"},{name: "Alaska", abbrv: "AK"},{name: "Arizona", abbrv: "AZ"},{name: "Arkansas", abbrv: "AR"},
    {name: "California", abbrv: "CA"},{name: "Colorado", abbrv: "CO"},{name: "Connecticut", abbrv: "CT"},{name: "Deleware", abbrv: "DE"},
    {name: "Florida", abbrv: "FL"},{name: "Georgia", abbrv: "GA"},{name: "Hawaii", abbrv: "HI"},{name: "Idaho", abbrv: "ID"},
    {name: "Illinois", abbrv: "IL"},{name: "Indiana", abbrv: "IN"},{name: "Iowa", abbrv: "IA"},{name: "Kansas", abbrv: "KS"},
    {name: "Kentucky", abbrv: "KY"},{name: "Louisiana", abbrv: "LA"},{name: "Maine", abbrv: "ME"},{name: "Maryland", abbrv: "MD"},
    {name: "Massachusetts", abbrv: "MA"},{name: "Michigan", abbrv: "MI"},{name: "Minnesota", abbrv: "MN"},{name: "Mississippi", abbrv: "MS"},
    {name: "Missouri", abbrv: "MO"},{name: "Montana", abbrv: "MT"},{name: "Nebraska", abbrv: "NE"},{name: "Nevada", abbrv: "NV"},
    {name: "New Hampshire", abbrv: "NH"},{name: "New Jersey", abbrv: "NJ"},{name: "New Mexico", abbrv: "NM"},{name: "New York", abbrv: "NY"},
    {name: "North Carolina", abbrv: "NC"},{name: "North Dakota", abbrv: "ND"},{name: "Ohio", abbrv: "OH"},{name: "Oklahoma", abbrv: "OK"},
    {name: "Oregon", abbrv: "OR"},{name: "Pennsylvania", abbrv: "PA"},{name: "Rhode Island", abbrv: "RI"},{name: "South Carolina", abbrv: "SC"},
    {name: "South Dakota", abbrv: "SD"},{name: "Tennessee", abbrv: "TN"},{name: "Texas", abbrv: "TX"},{name: "Utah", abbrv: "UT"},
    {name: "Vermont", abbrv: "VT"}, {name: "Virginia", abbrv: "VA"},{name: "Washington", abbrv: "WA"},{name: "West Virginia", abbrv: "WV"},
    {name: "Wisconsin", abbrv: "WI"},{name: "Wyoming", abbrv: "WY"}
    ];
    branchListItems = stateArr.map(state => {
      let combinedBranchListItems = combinedBranchList.map(branch => {
        let branchIcon = [];
        if (branch.branchKey.corps?.length > 0) {
          branchIcon = branch.branchKey.corps.map(corp => {
            return getBranchIcon(branch.branchKey.name, corp?.corp_ss_id)
          })
        }else {
          branchIcon.push(getBranchIcon(branch.branchKey.name, ""));
        }
        if (branch?.branchKey?.addr?.state_abbrv === state.abbrv) {
          increment++;
          return (
            <>
              <BranchCard view={view} admin={admin} data={data} region={branch.regionKey} subEntity={branch?.entityKey ?? ""} branch={branch.branchKey} increment={increment} branchIcon={branchIcon} key={branch.branchKey.id}/>
            </>
          )
        }
      })

      let entityHeaderOkStatus = false;
      if (combinedBranchList.some(branch => branch?.branchKey?.addr?.state_abbrv === state.abbrv)) {
        entityHeaderOkStatus = true;
      }

      return (
        <>
          {entityHeaderOkStatus &&
            <Header type="primary" view={view} key={state.name}>{state.name}</Header>
          } 
          {combinedBranchListItems}
        </>
      )
    })
  }else if (sortValue === 'Corporation') {
    let combinedBranchList = getCombinedBranchList(data.orgData, admin, adminSortValue);

    branchListItems = data.corpData.map(corp => {
      let combinedBranchListItems = combinedBranchList.map(branch => {
        let branchIcon = [];
        if (branch.branchKey.corps?.length > 0) {
          branchIcon = branch.branchKey.corps.map(corp => {
            return getBranchIcon(branch.branchKey.name, corp?.corp_ss_id)
          })
        }else {
          branchIcon.push(getBranchIcon(branch.branchKey.name, ""));
        }

        if (branch.branchKey?.corps?.find(b_corp => b_corp.corp_ss_id === corp.corp_ss_id)) {
          increment++;
          return (
            <>
              <BranchCard view={view} admin={admin} data={data} region={branch.regionKey} subEntity={branch?.entityKey ?? ""} branch={branch.branchKey} increment={increment} branchIcon={branchIcon} key={branch.branchKey.id}/>
            </>
          )
        }
      })

      let entityHeaderOkStatus = false;
      if (combinedBranchList.some(branch => branch.branchKey?.corps?.find(b_corp => b_corp.corp_ss_id === corp.corp_ss_id))) {
        entityHeaderOkStatus = true;
      }

      return (
        <>
          {entityHeaderOkStatus &&
            <Header type="primary" view={view} key={corp?.corp_name}>{corp?.corp_name}</Header>
          } 
          {combinedBranchListItems}
        </>
      )
    })
  }

  const handleSelects = () => {
    if (selectListDisplay) setSelectListDisplay(false);
    if (adminSelectListDisplay) setAdminSelectListDisplay(false);
    if (showGoogleModal) setShowGoogleModal(false)
  }

  const handleGoogleDownload = async () => {  
      try{
        let link = await handleAuthClick(data,'branches');
        // console.log(`link: ${link}`)
        // currently this isn't working
        setDownloadLink(link)
        // console.log(`download link: ${downloadLink}`)
        setDownloadMsg("Success! Your new gsheet will open shortly!")
        setShowGoogleModal(true);
      }catch(error){
        console.log(error);
        setDownloadMsg("OOPS! Something went wrong!")
        setShowGoogleModal(true);
      }
  }

  return (
    <div onClick={handleSelects}>
      {view === 'desktop' &&
        <>
          <div className='sortContainer'>
            <div className='sortInnerContainer'>
              {admin === 'yes' &&
                <div className='branchSupportOuterContainer'>
                  <div className='branchSupportAddLinkContainer'>
                    {userType === 'admin' &&
                      <>
                        <img src={addBranch} className='branchSupportIcon' alt='add branch icon' height='17px' width='17px' onClick={() => navigate('/AddBranch')}/>
                        <Link to='/AddBranch' className='addBranchLink'>Add New Branch</Link>
                        <img src={manageRegion} className='branchSupportIcon' alt='manage region icon' height='17px' width='17px'/>
                        <Link to='/ManageRegion'>Manage Regions</Link>
                      </>
                    }
                  </div>
                  <div className='downloadButtonContainer' onClick={handleGoogleDownload}>
                    <a className='downloadButton'>Download Branch List (Google Sheet)</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.4" viewBox="0 0 12 14.4">
                      <path id="Icon_ionic-md-download" data-name="Icon ionic-md-download" d="M18.75,9.581H15.322V4.5H10.177V9.581H6.75l6,5.929ZM6.75,17.2V18.9h12V17.2Z" transform="translate(-6.75 -4.5)" fill="#004987"/>
                    </svg>
                  </div>
                </div>
              }
              {admin === 'no' && 
                <div className='downloadButtonContainer' onClick={handleGoogleDownload}>
                  <a className='downloadButton'>Download Branch List (Google Sheet)</a>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.4" viewBox="0 0 12 14.4">
                    <path id="Icon_ionic-md-download" data-name="Icon ionic-md-download" d="M18.75,9.581H15.322V4.5H10.177V9.581H6.75l6,5.929ZM6.75,17.2V18.9h12V17.2Z" transform="translate(-6.75 -4.5)" fill="#004987"/>
                  </svg>
                </div>
              }
       
              <div className='branchAllSelectsContainer'>
                {admin === 'yes' &&
                  <div className='select-wrapper'>
                    <div className='selectButtonContainer' onClick={() => setAdminSelectListDisplay(!adminSelectListDisplay)} style={{ boxShadow: adminSelectListDisplay ? "0px 0px 6px rgb(0,0,0,16%)" : '' , borderRadius: adminSelectListDisplay ? "5px 5px 0px 0px" : "5px"}}>
                      <p>Display: {adminSortValue}</p>
                      <svg style={{ transform: adminSelectListDisplay ? "rotate(180deg)" : ""}} xmlns="http://www.w3.org/2000/svg" width="12" height="7.787" viewBox="0 0 12 7.787">
                        <path id="Path_36" data-name="Path 36" d="M8,9.787l-6-6L3.787,2,8,6.213,12.213,2,14,3.787Z" transform="translate(-2 -2)" fill="#a2a2a2"/>
                      </svg>
                    </div>
                    <div className='selectList' style={{ "display": !adminSelectListDisplay ? 'none' : 'block' }}>
                      <p className='selectListItem' onClick={function () {sessionStorage.setItem('adminSort', 'Physical'); setAdminSortValue(sessionStorage.getItem('adminSort')); setAdminSelectListDisplay(false)}}>Physical</p>
                      <p className='selectListItem' onClick={function () {sessionStorage.setItem('adminSort', 'Virtual'); setAdminSortValue(sessionStorage.getItem('adminSort')); setAdminSelectListDisplay(false)}}>Virtual</p>
                      <p className='selectListItem' onClick={function () {sessionStorage.setItem('adminSort', 'Open'); setAdminSortValue(sessionStorage.getItem('adminSort')); setAdminSelectListDisplay(false)}}>Open</p>
                      <p className='selectListItem' onClick={function () {sessionStorage.setItem('adminSort', 'Closed'); setAdminSortValue(sessionStorage.getItem('adminSort')); setAdminSelectListDisplay(false)}}>Closed</p>
                    </div>
                  </div>
                }
                <div className='select-wrapper'>
                  <div className='selectButtonContainer' onClick={() => setSelectListDisplay(!selectListDisplay)} style={{ boxShadow: selectListDisplay ? "0px 0px 6px rgb(0,0,0,16%)" : '' , borderRadius: selectListDisplay ? "5px 5px 0px 0px" : "5px"}}>
                    <p>Sort: {sortValue}</p>
                    <svg style={{ transform: selectListDisplay ? "rotate(180deg)" : ""}} xmlns="http://www.w3.org/2000/svg" width="12" height="7.787" viewBox="0 0 12 7.787">
                      <path id="Path_36" data-name="Path 36" d="M8,9.787l-6-6L3.787,2,8,6.213,12.213,2,14,3.787Z" transform="translate(-2 -2)" fill="#a2a2a2"/>
                    </svg>
                  </div>
                  <div className='selectList' style={{ "display": !selectListDisplay ? 'none' : 'block' }}>
                    <p className='selectListItem' onClick={function () {sessionStorage.setItem('sort', 'Region'); setSortValue(sessionStorage.getItem('sort')); setSelectListDisplay(false)}}>Region</p>
                    <p className='selectListItem' onClick={function () {sessionStorage.setItem('sort', 'Name'); setSortValue(sessionStorage.getItem('sort')); setSelectListDisplay(false)}}>Name</p>
                    <p className='selectListItem' onClick={function () {sessionStorage.setItem('sort', 'State'); setSortValue(sessionStorage.getItem('sort')); setSelectListDisplay(false)}}>State</p>
                    <p className='selectListItem' onClick={function () {sessionStorage.setItem('sort', 'Corporation'); setSortValue(sessionStorage.getItem('sort')); setSelectListDisplay(false)}}>Corporation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showGoogleModal && 
            <div className='googleModal'>
              <div className='googleModalContent'>
                <p className='googleModalText'>{downloadMsg}</p>
                <a href ={downloadLink}>{downloadLink}</a>
                <button className='googleModalButton' onClick={() => setShowGoogleModal(false)}>Ok</button>
              </div>
            </div>
          }
        </>
      }
      {view === 'mobile' &&
        <>
          <div className='mobileSearchFilterContainer'>
            <SearchBar data={data}/>
            <img src={filterButton} className='mobileFilterButton' alt='filter' onClick={() => setShowModal(!showModal)}/>
          </div>
          {showModal &&
            <div className='modal' onClick={() => setShowModal(false)}>
              <div className='modalContent'>
                <p className='modalLineItem'>Sort Branches</p>
                <p className={`modalLineItem ${sortValue === 'Region' && 'modalLineItemActive'}`} onClick={function () {sessionStorage.setItem('sort', 'Region'); setSortValue(sessionStorage.getItem('sort'));}}>Region</p>
                <p className={`modalLineItem ${sortValue === 'Name' && 'modalLineItemActive'}`} onClick={function () {sessionStorage.setItem('sort', 'Name'); setSortValue(sessionStorage.getItem('sort'));}}>Name</p>
                <p className={`modalLineItem ${sortValue === 'State' && 'modalLineItemActive'}`} onClick={function () {sessionStorage.setItem('sort', 'State'); setSortValue(sessionStorage.getItem('sort'));}}>State</p>
                <p className={`modalLineItem ${sortValue === 'Corporation' && 'modalLineItemActive'}`} onClick={function () {sessionStorage.setItem('sort', 'Corporation'); setSortValue(sessionStorage.getItem('sort'));}}>Corporation</p>
              </div>
            </div>
          }
        </>
      }
      {branchListItems}
    </div>
  )
}

export default Branches;