import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import EditButton from '../../subcomponents/EditButton';

import addRegion from '../../../assets/addNewBranch.svg';

const ManageRegion = ({view, data, admin}) => {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  // const handleEdit = (parent, region, entity) => {
  //   navigate('edit', {state: {parent: parent, region: region, entity: entity}});
  // }
  
  const handleEdit = (has_parent, region, has_direct_ent, direct_ent, entity) => {
    console.log({has_parent: has_parent, region: region, has_direct_ent:has_direct_ent, direct_ent: direct_ent, entity: entity})
    navigate('edit', {state: {has_parent: has_parent, region: region, has_direct_ent:has_direct_ent, direct_ent: direct_ent, entity: entity}});
  }

  //lvl1
  //lv12
  const regionList = data.orgData.map(lvl1 => {
    let subEntList;
      if (lvl1.entities) {
        subEntList = lvl1.entities.map(lvl2 => {
            return (
              <>
                <div className='manageRegionEditLineItemContainer'>
                  <ul><li><span style={{ marginLeft: '5px' }}>{lvl2.nickname?lvl2.nickname:lvl2.name} {lvl2.manager ? '-' : ''} {lvl2.manager ? lvl2.manager.first_name : ''} {lvl2.manager ? lvl2.manager.last_name : ''}</span></li></ul>
                  <EditButton clickRoute={() => handleEdit('yes', lvl1, 'no', lvl1, lvl2)} type='region'/>
                </div>
                <div className='regionGreyDivider'></div>
                {lvl2.entities && lvl2.entities.map(lvl3 => {
                  return <>
                    <div className='manageRegionEditLineItemContainer'>
                       <ul style={{ marginLeft: '50px' }}><li><span style={{ marginLeft: '5px' }}>{lvl3.nickname?lvl3.nickname:lvl3.name} {lvl3.manager ? '-' : ''} {lvl3.manager ? lvl3.manager.first_name : ''} {lvl3.manager ? lvl3.manager.last_name : ''}</span></li></ul>
                      <EditButton clickRoute={() => handleEdit('yes', lvl1,'yes',lvl2, lvl3)} type='region'/>
                    </div>
                    <div className='regionGreyDivider'></div>
                    {lvl3.entities && lvl3.entities.map(lvl4 => {
                      return <>
                        <div className='manageRegionEditLineItemContainer'>
                          <ul style={{ marginLeft: '95px' }}><li><span style={{ marginLeft: '5px' }}>{lvl4.nickname?lvl4.nickname:lvl4.name} {lvl4.manager ? '-' : ''} {lvl4.manager ? lvl4.manager.first_name : ''} {lvl4.manager ? lvl4.manager.last_name : ''}</span></li></ul>
                          <EditButton clickRoute={() => handleEdit('yes', lvl1 ,'yes',lvl3, lvl4)} type='region'/>
                        </div>
                        <div className='regionGreyDivider'></div>
                        {lvl4.entities && lvl4.entities.map(lvl5 => {
                          return <>
                            <div className='manageRegionEditLineItemContainer'>
                              <ul style={{ marginLeft: '140px' }}><li><span style={{ marginLeft: '5px' }}>{lvl5.nickname?lvl5.nickname:lvl5.name} {lvl5.manager ? '-' : ''} {lvl5.manager ? lvl5.manager.first_name : ''} {lvl5.manager ? lvl5.manager.last_name : ''}</span></li></ul>
                              <EditButton clickRoute={() => handleEdit('yes', lvl1 ,'yes',lvl4, lvl5)} type='region'/>
                            </div>
                            <div className='regionGreyDivider'></div>
                          </>
                        })}
                      </>
                    })}
                  </>
                })}
              </>
            )
        })
      }
      return (
        <>
          <div className='manageRegionEditLineItemContainer'>
            <p className='manageRegionLineHeader'>{lvl1.nickname?lvl1.nickname:lvl1.name} - {lvl1.manager.first_name} {lvl1.manager.last_name}</p>
            <EditButton clickRoute={() => handleEdit('no', lvl1, 'no','',lvl1)} type='region'/>
          </div>
          <div className='regionGreyDivider'></div>
          {subEntList}
        </>
      )
  })

  return (
    <>
      <div className='regionMainContainer'>
        <div className='regionHeaderLinkContainer'>
          <p className='regionHeader'>Region/Area Manager</p>
          <div className='regionAddRegionContainer'>
            <img src={addRegion} className='branchSupportIcon' alt='add branch icon' height='17px' width='17px' onClick={() => navigate('/AddRegion')}/>
            <Link to='/AddRegion' className='addBranchLink'>Add New Region/Area</Link>
          </div>
        </div>
        <div className='regionGreyDivider'></div>
        {regionList}
      </div>
    </>
  )
}

export default ManageRegion;