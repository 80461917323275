const getCombinedBranchList = (data, admin, sort) => {
  let combinedBranchArr = [];

  //generate the sorted combined list of branches from all nodes and entities to be used later
  for (let region of data) {
    if (region.nodes) {
      for (let branch of region.nodes) {
        let inactiveStatus;
        let displayStatus;
        let addressStatus = false;

        if (admin === 'yes' && sort === 'Physical') {
          inactiveStatus = !branch.inactive_ind;
          displayStatus = branch.display_ind === 1;
        }else if (admin === 'yes' && sort === 'Virtual') {
          inactiveStatus = !branch.inactive_ind;;
          displayStatus = 1;
          if (Object.values(branch.addr).every(addr => addr === '')) {
            addressStatus = true;
          }
        }else if (admin === 'yes' && sort === 'Open') {
          inactiveStatus = !branch.inactive_ind;
          displayStatus = 1;
        }else if (admin === 'yes' && sort === 'Closed') {
          inactiveStatus = branch.inactive_ind;
          displayStatus = 1;
        }else if (admin === 'yes' && sort === 'adminSearch'){
          inactiveStatus = true;
          displayStatus = true;
        }else {
          inactiveStatus = !branch.inactive_ind;
          displayStatus = branch.display_ind === 1;
          if (!Object.values(branch.addr).every(addr => addr === '')) {
            addressStatus = true;
          }
        }

        if (inactiveStatus && displayStatus && ((sort === 'Virtual' || admin !== 'yes') ? addressStatus : 1)) {
          combinedBranchArr.push({
            branchKey: branch,
            regionKey: region
          });
        }
      }
    }
    if (region.entities) {
      for (let entity of region.entities) {
        if (entity.nodes) {
          for (let branch of entity.nodes) {
            let inactiveStatus;
            let displayStatus;
            let addressStatus = false;
    
            if (admin === 'yes' && sort === 'Physical') {
              inactiveStatus = !branch.inactive_ind;
              displayStatus = branch.display_ind === 1;
            }else if (admin === 'yes' && sort === 'Virtual') {
              inactiveStatus = !branch.inactive_ind;;
              displayStatus = 1;
              if (Object.values(branch.addr).every(addr => addr === '')) {
                addressStatus = true;
              }
            }else if (admin === 'yes' && sort === 'Open') {
              inactiveStatus = !branch.inactive_ind;
              displayStatus = 1;
            }else if (admin === 'yes' && sort === 'Closed') {
              inactiveStatus = branch.inactive_ind;
              displayStatus = 1;
            }else if (admin === 'yes' && sort === 'adminSearch'){
              inactiveStatus = true;
              displayStatus = true;
            }else {
              inactiveStatus = !branch.inactive_ind;
              displayStatus = branch.display_ind === 1;
              if (!Object.values(branch.addr).every(addr => addr === '')) {
                addressStatus = true;
              }
            }

            if (inactiveStatus && displayStatus && ((sort === 'Virtual' || admin !== 'yes') ? addressStatus : 1)) {
              combinedBranchArr.push({
                branchKey: branch,
                regionKey: region,
                entityKey: entity
              });
            }
          }
        }
        if (entity.entities) {
          for (let subEntity of entity.entities) {
            if (subEntity.nodes) {
              for (let branch of subEntity.nodes) {
                let inactiveStatus;
                let displayStatus;
                let addressStatus = false;
        
                if (admin === 'yes' && sort === 'Physical') {
                  inactiveStatus = !branch.inactive_ind;
                  displayStatus = branch.display_ind === 1;
                }else if (admin === 'yes' && sort === 'Virtual') {
                  inactiveStatus = !branch.inactive_ind;;
                  displayStatus = 1;
                  if (Object.values(branch.addr).every(addr => addr === '')) {
                    addressStatus = true;
                  }
                }else if (admin === 'yes' && sort === 'Open') {
                  inactiveStatus = !branch.inactive_ind;
                  displayStatus = 1;
                }else if (admin === 'yes' && sort === 'Closed') {
                  inactiveStatus = branch.inactive_ind;
                  displayStatus = 1;
                }else if (admin === 'yes' && sort === 'adminSearch'){
                  inactiveStatus = true;
                  displayStatus = true;
                }else {
                  inactiveStatus = !branch.inactive_ind;
                  displayStatus = branch.display_ind === 1;
                  if (!Object.values(branch.addr).every(addr => addr === '')) {
                    addressStatus = true;
                  }
                }
    
                if (inactiveStatus && displayStatus && ((sort === 'Virtual' || admin !== 'yes') ? addressStatus : 1)) {
                  combinedBranchArr.push({
                    branchKey: branch,
                    regionKey: region,
                    entityKey: subEntity
                  });
                }
              }
            }
          }
        }
      }
    }
  }
  return combinedBranchArr.sort((a,b) => (a.branchKey.name.toLowerCase() < b.branchKey.name.toLowerCase()) ? -1 : 1);
}

const getActiveBranchList = (data) => {
  let branchArr = [];
  for (let branch of data) {
    if (!branch.inactive_ind) {
      branchArr.push(branch);
    }
  }
  return branchArr.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
}
  
export {getCombinedBranchList, getActiveBranchList};