import React from "react";
import { useNavigate } from 'react-router';
import { useRecoilValue } from "recoil";

import EditButton from "./subcomponents/EditButton";
import {emailState} from '../data/atoms';

import addressIcon from '../assets/address.svg';
import phoneIcon from '../assets/phone.svg';
import faxIcon from '../assets/fax.svg';
import emailIcon from '../assets/email.svg';
import homeBranchIndDetail from '../assets/homeBranchIndDetail.svg';

const BranchCard = ({view, admin, data, region, subEntity, branch, increment, branchIcon, homeBranchInd, onBranchDetail}) => {
  let email = useRecoilValue(emailState);
  const navigate = useNavigate();
  const goToDetails = () => {
    navigate(`/Branch/${branch.id}`, {state: {region: region, subEntity: subEntity, branchIcon: branchIcon, branch: branch}});
  }
  const goToEdit = () => {
    navigate(`/EditBranch/${branch.id}`, {state: {region: region, subEntity: subEntity, branchIcon: branchIcon, branch: branch}});
  }

  let branchIconList = branchIcon.map((icon, key) => {
    return <img src={icon} alt='branch icon' className="branchIconOffset" key={branch.id + key}/>
  });
  // console.log(branch)
  let managerName = '';
  for (let emp of data.oniniteData) {
    if (emp.id === branch.manager_id) {
      managerName = emp.first_name + " " + emp.last_name;
    }
  }

  return (
    <div className={`itemContainer ${increment % 2 === 0 ? 'evenNumBranchBackColor' : 'oddNumBranchBackColor'}`}>
      <div className='branchNameIconContainer'>
        {!onBranchDetail && <p id='branchName' onClick={goToDetails}>{branch.nickname?branch.nickname:branch.name} ({branch.code}) {managerName && <span className="branchManagerName">- {managerName}</span>}</p>}
        {onBranchDetail && <p id='branchNameDetail'>{branch.nickname?branch.nickname:branch.name} ({branch.code}) {managerName && <span className="branchManagerNameDetail">- {managerName}</span>}</p>}
        <div>
          {homeBranchInd && <img src={homeBranchIndDetail} alt="home branch icon" className="branchIconOffset"/>}
          {!branchIcon.includes(undefined) && branchIconList}
        </div>
      </div>
      <div className='regionManagerHeader'>
        <p className='branchSubHeader'>{region?.nickname?region.nickname:region.name} -</p>
        <p className='branchSubHeader' id='regionManagerName'>{region?.manager?.first_name} {region?.manager?.last_name}</p>
        {subEntity?.manager &&
          <p className='branchSubHeader' id='regionManagerName'>| {subEntity?.nickname?subEntity.nickname:subEntity.name} - {subEntity?.manager?.first_name} {subEntity?.manager?.last_name}</p>
        }
      </div>
      <div className="branchContactAndEditContainer">
        <div className={view === 'desktop' ? 'desktopBranchContactContainer' : 'mobileBranchContactContainer'}>
          <div className='branchLineItemContainer desktopAddressOuterContainer'>
            <img src={addressIcon} alt='address icon' className={view==='desktop' ? 'branchContactIcon' : 'addressIcon'} width="12.6" height="18"/>
            {(branch.addr?.address_1 || branch.addr?.city) && 
              <div className='addressContainer'>
                <a className='adressLinePrimary' href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(branch.addr?.address_1) + encodeURIComponent(branch.addr?.address_2 ?? "") + encodeURIComponent(branch.addr?.city) + encodeURIComponent(branch.addr?.state_abbrv) + encodeURIComponent(branch.addr?.zip)} target='_blank' rel="noreferrer">{branch.addr?.address_1}</a>
                <a className='adressLinePrimary' href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(branch.addr?.address_1) + encodeURIComponent(branch.addr?.address_2 ?? "") + encodeURIComponent(branch.addr?.city) + encodeURIComponent(branch.addr?.state_abbrv) + encodeURIComponent(branch.addr?.zip)} target='_blank' rel="noreferrer">{branch.addr?.address_2}</a>
                <a className='addressLineSecondary' href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(branch.addr?.address_1) + encodeURIComponent(branch.addr?.address_2 ?? "") + encodeURIComponent(branch.addr?.city) + encodeURIComponent(branch.addr?.state_abbrv) + encodeURIComponent(branch.addr?.zip)} target='_blank' rel="noreferrer">{branch.addr?.city}, {branch.addr?.state_abbrv} {branch.addr?.zip}</a>
              </div>
            }
          </div>
          <div className={view === 'desktop' ? 'desktopBranchItemContainer' : ''}>
            {branch.email &&
              <div className='branchLineItemContainer'>
                <img src={emailIcon} alt='email icon' className='branchContactIcon' width="18.395" height="18"/>
                {view === 'desktop' && <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${branch.email}&from=${email}`} target="_blank" className='branchListItem'>{branch.email}</a>}
                {view === 'mobile' && <a href={`mailto:${branch.email}`} className='branchListItem'>{branch.email}</a>}
              </div>
            }
            {branch.phone &&
              <div className='branchLineItemContainer'>
                <img src={phoneIcon} alt='phone icon' className='branchContactIcon' width="18.395" height="18"/>
                <a className='branchListItem' href={'tel:' + branch.phone}>({branch.phone.substr(0,3)}) {branch.phone.substr(4,3)}-{branch.phone.substr(8,4)}</a>
              </div>
            }
            {branch.fax && 
              <div className='branchLineItemContainer'>
                <img src={faxIcon} alt='fax icon' className='branchContactIcon' width="18" height="18"/>
                <p className='branchListItem'>({branch.fax.substr(0,3)}) {branch.fax.substr(4,3)}-{branch.fax.substr(8,4)}</p>
              </div>
            }
          </div>
        </div>
        {admin === 'yes' && !onBranchDetail && 
          <EditButton clickRoute={goToEdit} type='card' key={branch.id}/>
        }
      </div>
    </div>
  )
}

export default BranchCard;