import React from 'react';

const EditButton = ({clickRoute, type}) => {
  return (
    <>
      {type === 'card' && 
        <div className="branchEditCardContainer" onClick={clickRoute}>
          <p>Edit</p>
          <svg className="editBranchIcon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path className="editBranchIcon" data-name="Icon feather-edit-2" d="M12.078,3.759A1.712,1.712,0,0,1,14.5,6.179l-8.17,8.17L3,15.257l.908-3.329Z" transform="translate(-3 -3.257)" />
          </svg>
        </div>
      }
      {type === 'region' && 
        <div className="regionEditCardContainer" onClick={clickRoute}>
          <p>Edit</p>
          <svg className="editBranchIcon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path className="editBranchIcon" data-name="Icon feather-edit-2" d="M12.078,3.759A1.712,1.712,0,0,1,14.5,6.179l-8.17,8.17L3,15.257l.908-3.329Z" transform="translate(-3 -3.257)" />
          </svg>
        </div>
      }
      {type === 'detail' &&
        <div className="detailEditCardContainer" onClick={clickRoute}>
          <p>Edit</p>
          <svg className="editBranchIcon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path className="editBranchIcon" data-name="Icon feather-edit-2" d="M12.078,3.759A1.712,1.712,0,0,1,14.5,6.179l-8.17,8.17L3,15.257l.908-3.329Z" transform="translate(-3 -3.257)" />
          </svg>
        </div>
      }
    </>
  )
}

export default EditButton;