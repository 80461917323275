import A3_Icon from '../assets/A3-Icon.svg';
import Excelsior_Icon from '../assets/Excelsior-Icon.svg';
import MCF_Icon from '../assets/MCF-Icon.svg';
import Focus_Icon from '../assets/Focus-Icon.svg';
import Onin_Icon from '../assets/OMacron.svg';
import Aero_Icon from '../assets/onin-aerospace-icon.svg';

const getBranchIcon = (name, corpID) => {
  if (name.includes('A3')) {
    return A3_Icon;
  }else {
    switch(corpID) {
      case 1000:
        return Onin_Icon;
      case 1045: 
        return Onin_Icon;
      case 2047: 
        return Onin_Icon;
      case 3047: 
        return Onin_Icon; 
      case 4047: 
        return Aero_Icon;
      case 1046:
        return Excelsior_Icon;
      case 1048:
        return Focus_Icon;
      default:
        return;
    }
  }
}

export default getBranchIcon;