import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';

const AddRegion = ({view, data, admin}) => {
  const [parentEnt, setParentEnt] = useState("");
  const [directEnt, setdirEnt] = useState("");
  let navigate = useNavigate();

  const handleParentChoice = () => {
    if (parentEnt) {
      navigate('add', {state: {parent: parentEnt,directent:directEnt}});
    }
  }
  
  useEffect(()=>{
   return parentEnt == "no"? setdirEnt("no"):setdirEnt(directEnt)
  },[parentEnt]);
  

  return (
    <>
      <div className='regionMainContainer'>
        <p className='regionHeader'>Add New Region/Area</p>
        <div className='inputBottomSpacing'>
          <p className='radioHeaderBottomSpacing'>Does this new entity have a parent entity?</p>
          <div>
            <input type='radio' name='parent' id='yesParent' className='branchRadioButton radioButtonBottomSpacing' onChange={(e) => setParentEnt('yes')}/>
            <label htmlFor='yesParent'>Yes</label>
          </div>
          <div>
            <input type='radio' name='parent' id='noParent' className='branchRadioButton' onChange={(e) => setParentEnt('no')}/>
            <label htmlFor='noParent'>No</label>
          </div>
        </div>
        <div className='inputBottomSpacing'>
          <p className={`radioButtonBottomSpacing ${(parentEnt === 'no') && 'disabledInputs'}`}>Does this new entity have a direct entity?</p>
          <div>
            <input type='radio' name='directEnt' id='yesDirect' className={`branchRadioButton radioButtonBottomSpacing ${(parentEnt === 'no') && 'disabledInputs'}`} onChange={(e) => setdirEnt('yes')} />
            <label htmlFor='yesParent'className={`${(parentEnt === 'no') && 'disabledInputs'}`}>Yes</label>
          </div>
          <div>
            <input type='radio' name='directEnt' id='noDirect' className={`branchRadioButton ${(parentEnt === 'no') && 'disabledInputs'}`} onChange={(e) => setdirEnt('no')} />
            <label htmlFor='noParent' className={`branchRadioButton ${(parentEnt === 'no') && 'disabledInputs'}`}>No</label>
          </div>
        </div>
      </div>
      <div className='branchButtonContainer'>
        <div>
          <button className='nextButton' onClick={handleParentChoice}>Next</button>
          <button className='cancelButton' onClick={() => navigate(-1)}>Cancel</button>
        </div>
      </div>
    </>
  )
}

export default AddRegion;