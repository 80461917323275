import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getSingleSSAccount } from '../../../functions/dataCalls';
import OniniteCard from '../../OniniteCard';
import {ORG_API_KEY, ORG_API_URL} from '../../../config/constants';

const EditOninite = ({view, admin, data}) => {
  const [submit, setSubmit] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showSSFailureModal, setShowSSFailureModal] = useState(false);

  const [searchField, setSearchField] = useState("");
  const [listDisplay, setListDisplay] = useState("none");
  const [filteredList, setFilteredList] = useState("");
  const [homeBranch, setHomeBranch] = useState('');
  const [homeBranchErr, setHomeBranchErr] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [branchArr, setBranchArr] = useState([]);
  const [ssAccount, setSSAccount] = useState('');
  const [ssAccountErr, setSSAccountErr] = useState(false);
  const [ssAccountName, setSSAccountName] = useState('');

  let navigate = useNavigate();
  const emp = useLocation().state.emp;
  let empHomeBranch;
  let empAssocBranchList;

  //Re-filter the associated branch dropdown as the input field changes
  useEffect(() => {
    filterBranchData();
  }, [searchField]);

  //Filter logic for the associated branches dropdown
  const filterBranchData = () => {
    if (searchField !== "" && data.branchData !== null) {
      let list = data.branchData.filter(branch => {
        if (branch.name?.toLowerCase().includes(searchField.toLowerCase()) && !branch.inactive_ind) {
          return branch;
        }else if (branch.code?.toLowerCase().includes(searchField.toLowerCase()) && !branch.inactive_ind) {
          return branch;
        }
      });

      const setValue = (branch) => {
        setSearchField(branch.name + " " + (branch.code && `(${branch.code})`));
        setSelectedBranch(branch.id);
      }
      
      if (list.length > 0) {
        setListDisplay("block");
        setFilteredList(list.map(branch => {
          if (branch.name?.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' onClick={() => setValue(branch)}>{branch.name} {branch.code && `(${branch.code})`}</p>;
          }else if (branch.code?.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' onClick={() => setValue(branch)}>{branch.name} {branch.code && `(${branch.code})`}</p>;
          }
        }))
      }else  {
        setListDisplay("none");
        setFilteredList("");
      }
    } else {
      setListDisplay("none");
      setFilteredList("");
    }
  }

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  //Generate Home Branch Dropdown list data
  const fullBranchList = data.branchData && data.branchData.map(branch => {
    if (!branch.inactive_ind && branch.display_ind === 1) {
      return <option value={branch.id}>{branch.name}</option>
    }
  });
  //Generate Dropdown list of unused StaffSuite accounts
  const fullSSAccountList = data.ssAccountData && data.ssAccountData.map(account => {
    return <option value={account.account_id}>{account.username}</option>
  });

  
  //Logic that applies to when the component first mounts
  useEffect(() => {
    //Setting the default assoc branch array list to be used later
    if (emp.branches) {
      let arr = []
      for (let branch of emp.branches) {
        if (!branch.home_branch_ind) {
          arr.push({branch_id: branch.branch_id})
        }
      }
      setBranchArr(arr);
    }
    //Determining if the oninite already has a staffsuite account in order to add it to the option list of SS accounts
    if (emp.staffsuite_user_id !== 0) {
      getSingleSSAccount(emp.staffsuite_user_id).then((e) => {
        fullSSAccountList.push(<option value={e.account_id}>{e.username}</option>);
        setSSAccount(e.account_id);
        setSSAccountName(e.username);
      });
    }
  }, []);
  
  //Adding a new branch to the assoc array
  const handleAddition = () => {
    if (searchField !== "" && !branchArr.some(br => br.branch_id === selectedBranch)) {
      if (branchArr.length !== 0) {
        setBranchArr([...branchArr, {branch_id: selectedBranch}]);
      }else {
        setBranchArr([{branch_id: selectedBranch}]);
      }
      setSearchField("");
    }
  }

  //Remove a branch from the assoc array
  const handleRemoval = (branch) => {
    let filteredArr = branchArr.filter(br => br.branch_id !== branch.id);
    setBranchArr(filteredArr);
  }

  //Handling the change of an oninite's home branch from the select
  const handleHomeBranchChange = (value) => {
    setHomeBranch(value);
  }

  //Handling the change of an oninite's staffsuite account from the select
  const handleSSAccountChange = (id, name) => {
    setSSAccount(id);
    setSSAccountName(name);
  }

  //Setting home branch
  let empHomeBranchID = emp?.branches?.find(emp => emp.home_branch_ind === true);
  empHomeBranch = data.branchData && data.branchData.find((branch) => branch.id === empHomeBranchID?.branch_id);
  if (homeBranch === '') {
    if (empHomeBranch?.id) {
      setHomeBranch(empHomeBranch?.id);
    }
  }

  empAssocBranchList = data.branchData && data.branchData.map(branch => {
    for (let empBranch of branchArr) {
      if (branch.id === empBranch.branch_id && !branch.inactive_ind) {
        return (
          <div className='editOniniteBranchSubtractContainer'>
            <p>{branch.nickname?branch.nickname:branch.name}</p>
            <svg id="delete-icon-btn" className="subtractionButton" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={() => handleRemoval(branch)}>
              <path id="Icon_ionic-ios-remove-circle" className="subtractionButton" data-name="Icon ionic-ios-remove-circle" d="M13.375,3.375a10,10,0,1,0,10,10A10,10,0,0,0,13.375,3.375Zm4.351,10.769h-8.7a.745.745,0,0,1-.769-.769.737.737,0,0,1,.769-.769h8.7a.769.769,0,1,1,0,1.538Z" transform="translate(-3.375 -3.375)" fill="#aa182c"/>
            </svg>
          </div>
        )
      }
    }
  })

  //Handling the Updating Logic
  const handleSuccess = () => {
    navigate(`/Oninite/${emp.id}`);
    window.location.reload();
  }

  const handleFailure = () => {
    window.location.reload();
  }

  //Handling the sending of the API payload data and displaying error/success modals
  const sendUpdateOniniteAPI = async () => {
    let finalArr = [];
    finalArr.push({node_id: Number(homeBranch), home_ind: true})
    for (let branch of branchArr) {
      if (!finalArr.some(arrBranch => arrBranch.node_id === branch.branch_id)) {
        finalArr.push({node_id: branch.branch_id, home_ind: false});
      }
    }

    let recordError = false;
    let ssError = false;
    //Update the Oninite's Employee Record
    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/empnode`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          employee_id: emp.id,
          nodes: finalArr
        })
      });
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 || response.status === 404 ? recordError = true : recordError = false;
    } catch (error) {
      console.error(error);
    }

    //Update the Oninite's Staffsuite Account Record
    try {
      //Determine which API method to use
      let methodType = '';
      if (emp.staffsuite_user_id === 0 && Number(ssAccount) !== 0) {
        methodType = 'POST';
      }else if (Number(ssAccount) === 0) {
        methodType = 'DELETE';
      }else {
        methodType = 'PUT';
      }

      let response = await fetch(`${ORG_API_URL}/v1/org/staffsuite/account`, {
        method: methodType,
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          employee_id: emp.id,
          ss_account_id: methodType === 'DELETE' ? emp.staffsuite_user_id : Number(ssAccount)
        })
      });
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 || response.status === 404 ? ssError = true : ssError = false;
    } catch (error) {
      console.error(error);
    }

    //If there were any errors with the API calls, display appropriate error modals
    if (recordError) {
      setShowFailureModal(true);
    } else if (ssError) {
      setShowSSFailureModal(true);
    }else {
      setShowSuccessModal(true);
    }
  }

  //Handling final error check before send to API
  useEffect(() => {
    if (submit) {
      if (homeBranchErr === 'none' && ssAccountErr === 'none') {
        sendUpdateOniniteAPI();
      }
    }
  }, [submit]);

  //Handling error check upon submit
  const handleSubmit = () => {
    homeBranch === '' ? setHomeBranchErr('yes') : setHomeBranchErr('none');
    ssAccount === '' ? setSSAccountErr('yes') : setSSAccountErr('none');

    if (submit) {
      (async () => {
        setSubmit(false);
      })().then(() => setSubmit(true))
    }else {
      setSubmit(true);
    }
  }

  //Handling error check upon input change
  useEffect(() => {
    if (homeBranchErr) {
      homeBranch !== "" ? setHomeBranchErr('none') : setHomeBranchErr('yes');
    }
    if (ssAccountErr) {
      ssAccount !== "" ? setSSAccountErr('none') : setSSAccountErr('yes');
    }
  }, [homeBranch, ssAccount]);

  return (
    <>
      <div className='editOniniteHeaderCard'>
        <OniniteCard view={view} emp={emp} data={data} increment={1}/>
      </div>
      <div className='mainOniniteContainer' onClick={() => listDisplay !== 'none' ? setListDisplay('none') : ""}>
        <p className='editOniniteHeader editOniniteBottomSpacing'>Edit Ōninite Information</p>
        <div className='editOniniteColumnContainer'>
          <label htmlFor='homeBranch' className='subHeaderBottomSpacing'>StaffSuite Account</label>
          <div className='inputBottomSpacing'>
            <select id='ssAccount' className={`branchInput ${ssAccountErr === 'yes' && 'errorInputBorder'}`} defaultValue={ssAccount} value={ssAccount} onChange={(e) => handleSSAccountChange(e.target.value, e.target.options[e.target.selectedIndex].label)}>
              <option value="">Select</option>
              <option value={0}>None</option>
              {emp.staffsuite_user_id !== 0 && emp.staffsuite_user_id === ssAccount && <option value={ssAccount}>{ssAccountName}</option>}
              {fullSSAccountList}
            </select>
            {ssAccountErr === 'yes' && <p className='errInputSubText'>Please select a StaffSuite account or select None</p>}
          </div>
          <label htmlFor='homeBranch' className='subHeaderBottomSpacing'>Home Branch</label>
          <div className='inputBottomSpacing'>
            <select id='homeBranch' className={`branchInput ${homeBranchErr === 'yes' && 'errorInputBorder'}`} defaultValue={homeBranch} value={homeBranch} onChange={(e) => handleHomeBranchChange(e.target.value)}>
              <option value="">Select</option>
              {fullBranchList}
            </select>
            {homeBranchErr === 'yes' && <p className='errInputSubText'>Please select a home branch</p>}
          </div>
          <label htmlFor='additionalBranch' className='subHeaderBottomSpacing'>Additional Associated Branches (optional)</label>
          <div className='addAssocBranchContainer inputBottomSpacing'>
              <input 
              className='branchInput editOniniteSearchBranchInput'
              type='text'
              placeholder='Search Branches'
              value={searchField}
              onChange={handleChange}
              />
            <svg id="add-icon-btn" className='additionButton' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={handleAddition}>
              <path id="Icon_ionic-ios-add-circle" className='additionButton' data-name="Icon ionic-ios-add-circle" d="M13.375,3.375a10,10,0,1,0,10,10A10,10,0,0,0,13.375,3.375Zm4.351,10.769H14.144v3.582a.769.769,0,0,1-1.538,0V14.144H9.024a.769.769,0,0,1,0-1.538h3.582V9.024a.769.769,0,0,1,1.538,0v3.582h3.582a.769.769,0,1,1,0,1.538Z" transform="translate(-3.375 -3.375)" fill="#004987"/>
            </svg>
          </div>
          <div className='searchBranchResultList' style={{ 'display': listDisplay, 'top': view === 'mobile' ? '735px' : empAssocBranchList.some(br => br) ? '670px' : '690px' }}>
            {filteredList}
          </div>
          {empAssocBranchList}
        </div>
        {showSuccessModal && 
          <div className='supportConfirmModal'>
            <div className='supportConfirmModalContent'>
              <p className='supportConfirmModalText'>Ōninite information successfully updated!</p>
              <button className='supportConfirmModalButton' onClick={handleSuccess}>Ok</button>
            </div>
          </div>
        }
        {showFailureModal && 
          <div className='supportConfirmModal'>
            <div className='supportConfirmModalContent'>
              <p className='supportConfirmModalText'>Ōninite branch information unsuccessfully updated. Please try again.</p>
              <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
            </div>
          </div>
        }
        {showSSFailureModal && 
          <div className='supportConfirmModal'>
            <div className='supportConfirmModalContent'>
              <p className='supportConfirmModalText'>Ōninite StaffSuite account information unsuccessfully updated. Please try again.</p>
              <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
            </div>
          </div>
        }
        <div className='branchButtonContainer'>
          <div>
            <button className='updateButton' onClick={handleSubmit}>Update</button>
            <button className='cancelButton' onClick={() => navigate(-1)}>Cancel</button>
          </div>
        </div>
        <div style={{ 'marginBottom': '25px' }}></div>
      </div>
    </>
  )
}

export default EditOninite;