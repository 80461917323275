import { useState, useEffect } from "react";
import { getCookie, redirect, authorize } from "./functions";
import { permissionState, nameState, photoState, emailState, loggedInState } from "../../data/atoms";
import { useSetRecoilState, useRecoilState } from "recoil";

//This component wraps around all of the views of the application
// Serves to authorize and authenticate the user before hitting the application, and redirect to the login pages if not logged in
const AuthRoute = ({children}) => {
  const [permission, setPermissionState] = useRecoilState(permissionState);
  const [name,setNameState] = useRecoilState(nameState);
  const [photo, setPhotoState] = useRecoilState(photoState);
  const [email, setEmailState] = useRecoilState(emailState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loggedInState);
  const allowedUsers = ['USR', 'ADM', 'DEV', 'SUP'];
  const thisURL = window.location.href;
  let token = getCookie('sessionid');

  //If no cookie token is present, re-authorize the user
  if (token === '') {
    process.env.NODE_ENV !== 'development' && redirect(thisURL);
  }else {
    authorize(thisURL,token, allowedUsers, permission, setPermissionState, name, setNameState, photo, setPhotoState, email, setEmailState, isLoggedIn, setIsLoggedIn);
  }

  //Only display the views if the user is logged in successfully
  return (isLoggedIn || process.env.NODE_ENV === 'development') ? children : '';
}

export default AuthRoute;