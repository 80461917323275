import React, {useState, useEffect} from 'react';
import { useRecoilValue } from "recoil";
import {permissionState, nameState, photoState, emailState, loggedInState} from './data/atoms';
import NavBar from './components/NavBar';
import MobileNavBar from './components/MobileNavBar';
import Views from './routes/Views';
import AuthRoute from './components/auth/AuthRoute';
import './styles/styles.css';
import loadingIcon from './assets/PhoninBook-Loading-Icon.svg';

import { getOrgStruct, getEntities, getOninites, getBranches, getSSBranches, getSSAccounts, getDepts, getCorps, getJobTitles, getSearchData,getNodeTypes } from './functions/dataCalls';
import {useQuery } from 'react-query'

function App() {
  let permissionLevel = useRecoilValue(permissionState);
  const isLoggedIn = useRecoilValue(loggedInState);

  if (process.env.NODE_ENV === 'development') {
    permissionLevel = 'ADM';
  }

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;

  //Handling screen resizing
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  });

  //Data Queries
  const oninites = useQuery('oninites', getOninites);
  const branches = useQuery('branches', getBranches);
  const org = useQuery('org', getOrgStruct);
  const entities = useQuery('entities', getEntities);
  const departments = useQuery('departments', getDepts);
  const search = useQuery('search', () => getSearchData(permissionLevel));
  const ssbranches = useQuery('ssbranches', getSSBranches);
  const ssAccounts = useQuery('ssAccounts', getSSAccounts);
  const corps = useQuery('corps', getCorps);
  const jobs = useQuery('jobs', getJobTitles);
  const nodeTypes = useQuery('nodeTypes',getNodeTypes);

  //Default loading screen if the data isnt done loading yet
  if ((oninites.isLoading || branches.isLoading || org.isLoading || entities.isLoading || departments.isLoading || search.isLoading || ssbranches.isLoading || ssAccounts.isLoading || corps.isLoading || jobs.isLoading)) {
    return (
      <div className='loadingScreen'>
        <img src={loadingIcon} height="125px" width="125px" className='spinningLoadingIcon'/>
      </div>
    )
  }

  //Setting all of the app data into one object to be used throughout the app
  let data = { orgData: org.data || [], entData: entities.data || [], corpData: corps.data || [], oniniteData: oninites.data || [], branchData: branches.data || [], ssBranchData: ssbranches.data || [], ssAccountData: ssAccounts.data || [], deptData: departments.data || [], jobData: jobs.data || [], searchData: search.data || [], nodeTypeData: nodeTypes.data || []}
  
  return (
    <>
      <AuthRoute>
        {(isLoggedIn || process.env.NODE_ENV === 'development') &&
          <>
          {width > breakpoint ? <NavBar data={data}/> : <MobileNavBar />}
          {width > breakpoint ? <Views view="desktop" data={data} admin={(permissionLevel === 'ADM' || permissionLevel === 'DEV' || permissionLevel === 'SUP' || process.env.NODE_ENV == 'development') ? 'yes' : 'no'} userType={(permissionLevel === 'ADM' || permissionLevel === 'DEV' || process.env.NODE_ENV == 'development') ? 'admin' : 'support'}/> : <Views view="mobile" data={data} admin={'no'}/>}
          </>
        }
      </AuthRoute>
    </>
  );
}

export default App;
