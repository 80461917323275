import React, {useState, useEffect} from "react";
import { useLocation, useNavigate, useParams } from 'react-router';
import BranchCard from "./BranchCard";
import OniniteCard from "./OniniteCard";
import SearchBar from "./SearchBar";
import Header from "./Header";
import getBranchIcon from "../functions/branchIcon";

import inactiveFilter from '../assets/sort-icon-deactivate.svg';
import backButton from '../assets/desktopBackButton.svg';

const OniniteDetail = ({view, data, admin}) => {
  let state = useLocation().state;
  let navigate = useNavigate();
  let params = useParams();
  let increment = 0;
  let branchCardList;
  let regionObject;
  let subEntityObject;
  let empObject;
  let empID;

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  if (!state) {
    for (let emp of data.oniniteData) {
      if (emp.id == params.emp) {
        empObject = emp;
      }
    }
  }else {
    empObject = state.emp;
  }

  state ? empID = state.emp.id : empID = empObject.id; //Need this in order to determine if current emp is Rick Orcutt

  let branchList = [];
  for (let branch of data.branchData) {
    if (state && state.emp?.branches?.some(empBranch => empBranch.branch_id === branch.id) && !branch.inactive_ind && branch.display_ind === 1) {
      branchList.push(branch);
    }else if (empObject && empObject?.branches?.some(empBranch => empBranch.branch_id === branch.id) && !branch.inactive_ind && branch.display_ind === 1) {
      branchList.push(branch);
    }
  }
  
  branchCardList = branchList.map(empBranch => {
    let homeBranchInd;
    increment++;
    let branchIcon = [];

    if (empBranch.corps?.length > 0) {
      branchIcon = empBranch.corps.map(corp => {
        return getBranchIcon(empBranch.name, corp?.corp_ss_id);
      })
    }else {
      branchIcon.push(getBranchIcon(empBranch.name, ""));
    }

    for (let branch of (state && state?.emp?.branches) || (empObject && empObject?.branches)) {
      if (branch.branch_id === empBranch.id && branch.home_branch_ind) {
        homeBranchInd = true;
      }
    }
  
    for (let region of data.orgData) {
      if (region.nodes) {
        for (let branch of region.nodes) {
          if (branch.id == empBranch.id) {
            regionObject = region;
          }
        }
      }
      if (region.entities) {
        for (let entity of region.entities) {
          if (entity.nodes) {
            for (let branch of entity.nodes) {
              if (branch.id == empBranch.id) {
                regionObject = region;
                subEntityObject = entity;
              }
            }
          }
          if (entity.entities) {
            for (let subEntity of entity.entities) {
              if (subEntity.nodes) {
                for (let branch of subEntity.nodes) {
                  if (branch.id == empBranch.id) {
                    regionObject = region;
                    subEntityObject = subEntity;
                  }
                }
              }
            }
          }
        }
      }
    }
    if (!empBranch.inactive_ind && empBranch.display_ind === 1) {
      return (
        <BranchCard view={view} admin={admin} data={data} region={regionObject} subEntity={subEntityObject} branch={empBranch} oninites={data.oniniteData} increment={increment} branchIcon={branchIcon} key={empBranch.id} homeBranchInd={homeBranchInd}/>
      )
    }
  });

  return (
    <>
      {view === 'mobile' &&
        <>
          <div className='mobileSearchFilterContainerDetailPage'>
            <SearchBar data={data}/>
            <img src={inactiveFilter} className='mobileFilterButtonInactive' alt='filter'/>
          </div>
          <div className={(empID === 1 || empID === 2271) ? 'rickOrcutt' : ''}>
            <OniniteCard view={view} admin={admin} onOniniteDetail={true} emp={empObject} data={data} increment={1} key={empID}/>
          </div>
        </>
      }
      {view === 'desktop' &&
        <>
          <div className="desktopBackButtonContainer">
            <img src={backButton} alt='back button' style={{ cursor: "pointer" }} onClick={() => navigate(-1)}/>
          </div>
          <div className={`detailHeaderStickyContainer ${(empID === 1 || empID === 2271) ? 'rickOrcutt' : ''}`}>
            <OniniteCard view={view} admin={admin} onOniniteDetail={true} emp={empObject} data={data} increment={1}/>
          </div>
        </>
      }
      <Header type={empObject.display_phone ? "oniniteDetailPhone" : "oniniteDetail"} view={view} empID={empID}>Branches</Header>
      <div className={(empID === 1 || empID === 2271) ? 'rickOrcutt' : ''}>
        {branchCardList}
      </div>
    </>
  )
}

export default OniniteDetail;