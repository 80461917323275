import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useRecoilValue } from "recoil";

import EditButton from "./subcomponents/EditButton";
import {emailState} from '../data/atoms';

import phoneIcon from '../assets/phone.svg';
import cellIcon from '../assets/cell-phone-icon.svg';
import managerIcon from '../assets/manager.svg';
import homeIcon from '../assets/homeBranch.svg';
import emailIcon from '../assets/email.svg';
import blobFish from '../assets/blobFish2.jpg';
import monkey from '../assets/monkey.jpg';
import tapir from '../assets/tapir1.jpg';
import molerat from '../assets/molerat.jpg';
import potoo from '../assets/potoo2.jpg';
import deer from '../assets/deer.jpg';

const OniniteCard = ({view, admin, emp, data, increment, onOniniteDetail}) => {
  const [badPhoto, setBadPhoto] = useState('');
  let empPhone;
  let empBranchPhone;
  let empHomeBranch;
  let empHomeBranchID;
  let empDept;
  let email = useRecoilValue(emailState);
  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(`/Oninite/${emp.id}`, {state: {emp: emp}});
  }
  const goToHomeBranchDetail = () => {
    navigate(`/Branch/${empHomeBranchID?.branch_id}`);
  }
  const goToManagerDetail = () => {
    navigate(`/Oninite/${emp.manager.id}`)
  }
  const goToEdit = () => {
    navigate(`/EditOninite/${emp.id}`, {state: {emp: emp}});
  }

  if (emp.phone) {
    if (emp.phone.includes('.')) {
      empPhone = <a href={'tel:' + emp.phone}>({emp.phone.substr(0,3)}) {emp.phone.substr(4,3)}-{emp.phone.substr(8,4)}</a>
    }else {
      empPhone = <a href={'tel:' + emp.phone}>({emp.phone.substr(0,3)}) {emp.phone.substr(3,3)}-{emp.phone.substr(6,4)}</a>
    }
  }

  if (emp.branches) {
    empHomeBranchID = emp.branches.find(emp => emp.home_branch_ind === true);
    empHomeBranch = data.branchData.find((branch) => branch.id === empHomeBranchID?.branch_id);
  }

  if (empHomeBranch?.phone) {
    if (empHomeBranch.phone.includes('.')) {
      empBranchPhone = <a href={'tel:' + empHomeBranch.phone}>({empHomeBranch.phone.substr(0,3)}) {empHomeBranch.phone.substr(4,3)}-{empHomeBranch.phone.substr(8,4)}</a>
    }else {
      empBranchPhone = <a href={'tel:' + empHomeBranch.phone}>({empHomeBranch.phone.substr(0,3)}) {empHomeBranch.phone.substr(3,3)}-{empHomeBranch.phone.substr(6,4)}</a>
    }
  }


  if (emp.department_id) {
    empDept = data.deptData.find(dept => dept.id === emp.department_id);
  }

  //Set the funny default photo upon first render
  useEffect(() => {
    const badPhotoArr = [molerat, tapir, monkey, blobFish, potoo, deer];
    let photo = badPhotoArr[Math.floor(Math.random()*badPhotoArr.length)];
    setBadPhoto(photo);
  }, []);

  return (
    <div className={`itemContainer ${increment % 2 === 0 ? 'evenNumBranchBackColor' : 'oddNumBranchBackColor'}`} key={emp.id}>
      <div className="oniniteImageNameOuterContainer">
        {/* "https://resources.bamboohr.com/images/photo_person_150x150.png" */}
        {badPhoto && <img key={emp.id} src={(!emp.photo?.includes('resources') && emp.photo !== "" && emp.photo?.includes('http')) ? emp?.photo : badPhoto} onError={(e) => e.target.src = badPhoto} height="64px" width="64px" className="bambooPhoto"/>}
        <div className="oniniteNameContainer">
          {!onOniniteDetail && <p className="oniniteName" onClick={goToDetails}>{emp.first_name} {emp.last_name}</p>}
          {onOniniteDetail && <p className="oniniteNameDetail">{emp.first_name} {emp.last_name}</p>}
          <div className="oniniteTitleDeptContainer">
            <p>{emp?.title}</p>
            {emp.title && empDept?.name && <p style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</p>}
            <p>{empDept?.name}</p>
          </div>
        </div>
      </div>
      <div className="oniniteAdminContactEditContainer">
        <div className={view === 'desktop' ? 'desktopOniniteContactContainer' : 'mobileOniniteContactContainer'}>
          <div className={`oniniteLineItemContainer ${view === 'desktop' && 'homeBranchGridCell'}`}>
            <img src={homeIcon} alt="home icon" className='oniniteContactIcon' width="20" height="18"/>
            {empHomeBranchID &&
              <a onClick={goToHomeBranchDetail} className="branchListItem">{empHomeBranch?.nickname?empHomeBranch.nickname:empHomeBranch.name} ({empHomeBranch?.code})</a>
            }
          </div>
            <div className={`oniniteLineItemContainer ${view === 'desktop' && 'managerGridCell'}`}>
              {emp?.manager?.id && emp?.manager?.id !== 0 &&
                <>
                  <img src={managerIcon} alt="manager icon" className='oniniteContactIcon' width="18" height="18"/>
                  <a onClick={goToManagerDetail}>Reports to: {emp.manager?.first_name} {emp.manager?.last_name}</a>
                </>
              }
            </div>
          {emp.email &&
            <div className={`oniniteLineItemContainer ${view === 'desktop' && 'emailGridCell'}`}>
              <img src={emailIcon} alt="email icon" className='oniniteContactIcon' width="18.395" height="18"/>
              {/* <a href={`mailto:${emp.email}`} className='branchListItem'>{emp.email}</a> */}
              {view === 'desktop' && <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${emp.email}&from=${email}`} target="_blank" className='branchListItem'>{emp.email}</a>}
              {view === 'mobile' && <a href={`mailto:${emp.email}`} className='branchListItem'>{emp.email}</a>}
            </div>
          }
          {empHomeBranch?.phone && 
            <div className={`oniniteLineItemContainer ${view === 'desktop' && 'branchPhoneGridCell'}`}>
              <img src={phoneIcon} alt="phone icon" className='oniniteContactIcon' width="18.395" height="18"/>
              {empBranchPhone}
            </div> 
          }
          {emp.phone && emp.display_phone && 
            <div className={`oniniteLineItemContainer ${view === 'desktop' && 'phoneGridCell'}`}>
              <img src={cellIcon} alt="phone icon" className='oniniteContactIcon' width="18.395" height="18"/>
              {empPhone}
            </div> 
          }
        </div>
        {admin === 'yes' &&
          <EditButton clickRoute={goToEdit} type='card' key={emp.id + emp.department_id}/>
        }
      </div>
    </div>
  )
}

export default OniniteCard;