import React, {useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router';

import DeactivateButton from '../../subcomponents/DeactivateButton';

import deactivate from '../../../assets/deactivate.svg';
import { ORG_API_URL, ORG_API_KEY } from '../../../config/constants';

const EditRegion = ({ view, data, admin }) => {
  let navigate = useNavigate();
  let state = useLocation().state;
  let has_parent = state.has_parent;
  let region = state.region;
  let entity = state.entity;
  let has_direct_ent = state.has_direct_ent
  let direct_entity = state.direct_ent

  //For checking if a region has anything under it still to be used later for deactivation
  let regionNodeArr = [];
  let regionEntityArr = [];
  let entityArr = [];

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const [submit, setSubmit] = useState(false);
  // const [searchField, setSearchField] = useState(entity ? entity.manager.first_name + " " + entity.manager.last_name : region.manager.first_name + " " + region.manager.last_name);
  const [searchField, setSearchField] = useState(entity.manager.first_name + " " + entity.manager.last_name);
  const [listDisplay, setListDisplay] = useState("none");
  const [filteredList, setFilteredList] = useState("");
  const [hideList, setHideList] = useState(false);

  const [parentEntChoice, setParentEntChoice] = useState(has_parent);
  const [directEntChoice, setdirectEntChoice] = useState(has_direct_ent);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);

  const [deactivateSubmit, setDeactivateSubmit] = useState(false);
  const [transferEntity, setTransferEntity] = useState("");
  const [transferEntityErr, setTransferEntityErr] = useState(false);

  // const [geoName, setGeoName] = useState(entity ? entity.name : region.name);
  const [geoName, setGeoName] = useState(entity.name);
  const [geoNameErr, setGeoNameErr] = useState(false);
  const [parEnt, setParEnt] = useState(has_parent === 'yes' ? region.id : '');
  const [parEntErr, setParEntErr] = useState(false);
  const [directEnt, setDirectEnt] = useState(has_direct_ent === 'yes' ? direct_entity.id : '');
  const [directEntErr, setDirectEntErr] = useState(false);
  const [directEntOpt, setDirectEntOpt] = useState(has_direct_ent === 'yes' ? region.entities : []);
  const [leader, setLeader] = useState(entity.manager.id);
  const [leaderErr, setLeaderErr] = useState(false);
  const [nickname, setNickname] = useState(entity.nickname??"");

  //List of a parent entity's direct nodes
  let regionNodes = region.nodes?.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1).map(branch => {
    if (!branch.inactive_ind) {
      regionNodeArr.push(branch.id);
      return (
        <p className='editRegionBranch'>{branch.nickname?branch.nickname:branch.name} ({branch.code}) {branch.display_ind === 2 && ' - Virtual'}</p>
      )
    }
  });
  //List of the nodes underneath a parent entity's lvl2(s)
  let regionEntities = region.entities?.map(entity => {
    let regionEntityNodes = entity.nodes && entity.nodes.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1).map(branch => {
      if (!branch.inactive_ind) {
        regionEntityArr.push(branch.id);
        return <p className='editRegionBranch'>{branch.nickname?branch.nickname:branch.name} ({branch.code}) {branch.display_ind === 2 && ' - Virtual'}</p>
      }
    })

    if (entity.nodes?.some(node => !node.inactive_ind)) {
      return (
        <>
          <p className='regionHeaderList'>{entity.name}</p>
          {regionEntityNodes}
          <br />
        </>
      )
    }
  })
  //List of a SubEntity's nodes (if an area has been clicked on)
  let entityNodes = entity?.nodes?.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1).map(branch => {
    if (!branch.inactive_ind) {
      entityArr.push(branch.id);
      return (
        <p className='editRegionBranch'>{branch.nickname?branch.nickname:branch.name} ({branch.code}) {branch.display_ind === 2 && ' - Virtual'}</p>
      )
    }
  })
  
  let entitiesOptions = data.orgData.map(region => {
    let opt = <option value={region.id} key={region.id}>{region.name}</option>
      if (region.id === entity.id){
        opt =  <option value={region.id} key={region.id} disabled>{region.name}</option>
      }
    return opt
  });

  let allEntitiesOptions = data.entData.map(ent => {
    let opt = <option value={ent.id} key={ent.id}>{ent.name}</option>
      if (ent.id === entity.id){
        opt = <option value={ent.id} key={ent.id} disabled>{ent.name}</option>
      }
    return opt
  });

  useEffect(() => {
    // Add check so if sub entity is entity (don't pull in own sub entities)
    if(parentEntChoice ==='yes' && directEntChoice =='no'){
      setDirectEnt(parEnt)
      // console.log(`direct ent: ${directEnt}`)
      // console.log(`parent: ${parEnt}`)
    }

    // Set directParent_opt to parEnt subentities
     data.orgData.forEach(lvl1 => {
      if(parseInt(lvl1.id) === parseInt(parEnt)){
        if(lvl1.entities!==null && entity.id !== lvl1.id){
        let subentities = lvl1.entities
        
        // Loop through sub-entities
        subentities.forEach( lvl2 =>{
            if(lvl2.entities !== null && entity.id !== lvl2.id){
              
              // Add any nested entities 
              lvl2.entities.forEach(lvl3 => {
                subentities.push(lvl3)
                
                if(lvl3.entities !== null && entity.id !== lvl3.id){
                  lvl3.entities.forEach(lvl4 => {
                    subentities.push(lvl4)
                    
                    if(lvl4.entities !== null && entity.id !== lvl4.id){
                      lvl4.entities.forEach(lvl5 => {
                        subentities.push(lvl5)
                     })

                    }
                 })
                }
                
              }) 
            }
            // return lvl2.entities
          })
        setDirectEntOpt(subentities)
        // console.log(`UseEffect Value:${JSON.stringify(subentities)}`)
        }else{
          setdirectEntChoice('no')
        }
      }
     })  
  }, [parEnt]);

  useEffect(()=>{
    if(directEntChoice ==="no"){
      setDirectEnt(parEnt)
    }
  },[directEntChoice])

  useEffect(()=>{
    console.log(`parent choice: ${parentEntChoice} direct choice ${directEntChoice}`)
    if(parentEntChoice ==="no"){
      setParEnt("")
      setDirectEnt("")
    }
  },[parentEntChoice])

  //Generate search list for oninites for use in select
  const notInitialRender = useRef(false);
  useEffect(() => {
    if (notInitialRender.current) {
      setHideList(false);
      filterOniniteData();
    }else {
      notInitialRender.current = true;
    }
  }, [searchField]);

  const filterOniniteData = () => {
    if (searchField !== "" && data.oniniteData !== null && !hideList) {
      let list = data.oniniteData.filter(emp => {
        if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.title && emp.title.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }
      });

      const setValue = (emp) => {
        // console.log(emp)
        setSearchField(emp.first_name + " " + emp.last_name);
        setLeader(emp.id);
        setHideList(true);
      }
      
      if (list.length > 0) {
        setListDisplay("block");
        setFilteredList(list.map(emp => {
          if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.first_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.last_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase()) && emp.first_name) {
            return <p className='searchBranchItem' id={emp.first_name + "space"} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.title && emp.title.toLowerCase().includes(searchField.trim().toLowerCase())) {
            return <p className='searchBranchItem' id={emp.title} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name} <span className='searchHelperText'>({emp.title})</span></p>
          }
        }))
      }else  {
        setListDisplay("none");
        setFilteredList("");
        setLeader('');
      }
    } else {
      setHideList(false)
      setListDisplay("none");
      setFilteredList("");
      // setLeader(''); 
      // was causing causing issues when editing region. Could not update manager. Hopefully this doesn't break anything! - TP
    }
  }

  const handleSuccess = () => {
    navigate(`/ManageRegion`);
    window.location.reload();
  }

  const handleFailure = () => {
    window.location.reload();
  }

  const sendToApi = async () => {
    // Type ids:
      // 1 = Region
      // 2 = Area
      // 3 = Sub-Area
      // 4 = Sub-Region
    
    let type = 4;
    // console.log(`parent entity choice: ${parentEntChoice}`)
    // console.log(`Entity type: ${entity.type }`)
    if (parentEntChoice === 'no') {
      // If it does not have a parent default to Region Type unless specified as Area Type
      // setParEnt('')
      if(entity.type === "Area"){
        type = 2
      }else{
        type = 1
      }
    // If it does have a parent and region has not changed assign sub entity type based on parent type
    }else if (parentEntChoice === 'yes' && parEnt == region.id) {
      if (region.type === 'Area') {
        type = 3
      }else {
        type = 4
      }
    }else if (parentEntChoice === 'yes' && parEnt != region.id) {
      for (let org_region of data.orgData) {
        if (parEnt == org_region.id) {
          if (org_region.type === 'Area') {
            type = 3;
          }else{
            type = 4;
          }
        }
      }
    }
    
    
    // console.log(`Type: ${type}`)

    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/ent`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          // id: entity ? entity.id : region.id,
          id: entity.id,
          name: geoName,
          nickname: nickname,
          type_id: type,
          parent_entity_id: Number(parEnt) ?? 0,
          direct_entity_id: Number(directEnt) ?? 0,
          manager_id: Number(leader)
        })
      });
      console.log(`var parEnt: ${parEnt} var directEnt ${directEnt}`)
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  //Handling final error check before send to API
  useEffect(() => {
    if (submit) {
      if (geoNameErr === 'none' && parEntErr === 'none' && leaderErr === 'none') {
        sendToApi();
      }
    }
  }, [submit]);

  //Handle error check upon submit
  const handleSubmit = () => {
    geoName === "" ? setGeoNameErr('yes') : setGeoNameErr('none');
    parEnt === "" && parentEntChoice === 'yes' ? setParEntErr('yes') : setParEntErr('none');
    leader === "" ? setLeaderErr('yes') : setLeaderErr('none');

    if (submit) {
      (async () => {
        setSubmit(false);
      })().then(() => setSubmit(true))
    }else {
      setSubmit(true);
    }
  }

  //Handle error check upon input change
  useEffect(() => {
    if (geoNameErr) {
      geoName !== "" ? setGeoNameErr('none') : setGeoNameErr('yes');
    }
    if (parEntErr) {
      parEnt !== "" ? setParEntErr('none') : setParEntErr('yes');
    }
    if (leaderErr) {
      leader !== "" ? setLeaderErr('none') : setLeaderErr('yes');
    }
  }, [geoName, parEnt, leader]);


  // --------------------------------- Handling the Deactivation Logic ------------------------------------- \\
  const handleDeactivateInitial = () => {
    if ( has_parent = state.has
      === 'no' && (regionNodeArr.length !== 0 || regionEntityArr.length !== 0)) {
      setTransferEntity("");
      setTransferEntityErr(false);
      setShowDeactivateModal(true);
    }else if (has_parent === 'yes' && entityArr.length !== 0) {
      setTransferEntity("");
      setTransferEntityErr(false);
      setShowDeactivateModal(true);
    }else {
      //indicates that all total branches have been reassigned
      setShowDeactivateConfirmModal(true);
    }
  }

  const handleDeactivateConfirmation = () => {
    setShowDeactivateModal(false);
    setShowDeactivateConfirmModal(true);
  }

  //Logic for if branches need to be reassigned
  useEffect(() => {
    if (transferEntityErr) {
      transferEntity !== "" ? setTransferEntityErr('none') : setTransferEntityErr('yes');
    }
  }, [transferEntity]);

  const handleDeactivateSubmit = () => {
    transferEntity === "" ? setTransferEntityErr('yes') : setTransferEntityErr('none');

    if (deactivateSubmit) {
      (async () => {
        setDeactivateSubmit(false);
      })().then(() => setDeactivateSubmit(true))
    }else {
      setDeactivateSubmit(true);
    }
  }

  useEffect(() => {
    if (deactivateSubmit) {
      if (transferEntityErr === 'none') {
        handleDeactivateConfirmation();
      }
    }
  }, [deactivateSubmit]);

  const handleDeactivateFinal = () => {
    setShowDeactivateConfirmModal(false);
    sendDeactivateEntityAPI();
  }

  const sendDeactivateEntityAPI = async () => {
    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/ent`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          // old_id: entity ? entity.id : region.id,
          old_id: entity.id,
          new_id: Number(transferEntity) ?? 0
        })
      });
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className='regionMainContainer' onClick={() => listDisplay !== 'none' ? setListDisplay('none') : ""}>
        <div className='editRegionHeaderDeactivateContainer'>
          <p className='regionHeader'>Edit { entity.name}</p>
          <DeactivateButton clickRoute={handleDeactivateInitial}>Deactivate</DeactivateButton>
        </div>
        <div className='manageRegionInputOuterContainer'>
          <div className='manageRegionInputColumnContainer'>
            <div className='editBranchBottomSpacing'>
              <p className='radioHeaderBottomSpacing'>Does this entity have a parent entity?</p>
              <div>
                <input type='radio' name='parEnt' id='yesParent' defaultChecked={has_parent === 'yes'} className='branchRadioButton radioButtonBottomSpacing' onChange={(e) => setParentEntChoice('yes')}/>
                <label htmlFor='yesParent'>Yes</label>
              </div>
              <div>
                <input type='radio' name='parEnt' id='noParent' defaultChecked={has_parent === 'no'} className='branchRadioButton' onChange={(e) => setParentEntChoice('no')}/>
                <label htmlFor='noParent'>No</label>
              </div>
            </div>
            <label htmlFor='geoName' className='subHeaderBottomSpacing'>Geographical Name</label>
            <p className='subHeaderBottomSpacing subHeaderHelperText'>Please use abbreviations for states</p>
            <div className='inputBottomSpacing'>
              <input type="text" id='geoName' className={`branchInput ${geoNameErr === 'yes' && 'errorInputBorder'}`} value={geoName} onChange={(e) => setGeoName(e.target.value)}/>
              {geoNameErr === 'yes' && <p className='errInputSubText'>Please enter a geographical name</p>}
            </div>
            <label htmlFor='parentEnt' className={`subHeaderBottomSpacing ${(parentEntChoice === 'no') && 'disabledInputs'}`}>Parent Entity</label>
            <div className='editBranchBottomSpacing'>
              <select className={`branchInput ${(parentEntChoice === 'no') && 'disabledInputs'} ${parentEntChoice === 'yes' && parEntErr === 'yes' && 'errorInputBorder'}`} id='parentEnt' value={parEnt} onChange={(e) => setParEnt(e.target.value)} onBlur={(e) => setParEnt(e.target.value)}>
                <option value="">Select</option>
                {entitiesOptions }
              </select>
              {parentEntChoice === 'yes' && parEntErr === 'yes' && <p className='errInputSubText'>Please select a parent entity</p>}
            </div>
            <div className='editBranchBottomSpacing'>
              <p className='radioHeaderBottomSpacing'>Does this entity have a direct entity?</p>
              <div>
                <input type='radio' name='dirEnt' id='yesDirect' defaultChecked={has_direct_ent === 'yes'} className='branchRadioButton radioButtonBottomSpacing' onChange={(e) => setdirectEntChoice('yes')}/>
                <label htmlFor='yesDir'>Yes</label>
              </div>
              <div>
                <input type='radio' name='dirEnt' id='noDir' defaultChecked={has_direct_ent === 'no'} className='branchRadioButton' onChange={(e) => setdirectEntChoice('no')}/>
                <label htmlFor='noDir'>No</label>
              </div>
            </div>
            <label htmlFor='parentEnt' className={`subHeaderBottomSpacing ${(parentEntChoice === 'no') && 'disabledInputs'}`}>Direct Entity</label>
            <div className='editBranchBottomSpacing'>
              <select className={`branchInput ${(directEntChoice === 'no') && 'disabledInputs'} ${directEntChoice === 'yes' && directEntErr === 'yes' && 'errorInputBorder'}`} id='directEnt' value={directEnt} onChange={(e) => setDirectEnt(e.target.value)} onBlur={(e) => setDirectEnt(e.target.value)}>
                <option value="">Select</option>
                { directEntOpt.map(ent => {
                  let opt = <option value={ent.id} key={ent.id}>{ent.name}</option>
                  if (ent.id === entity.id){
                    opt = <option value={ent.id} key={ent.id} disabled>{ent.name}</option>
                  }
                  return opt
                }) }
              </select>
              {directEntChoice === 'yes' && directEntErr === 'yes' && <p className='errInputSubText'>Please select a direct entity</p>}
            </div>
            <label htmlFor='leader' className={`subHeaderBottomSpacing`}>Leader Name</label>
            <div className='editBranchBottomSpacing'>
              <input 
              className={`branchInput ${leaderErr === 'yes' && 'errorInputBorder'}`}
              type='text'
              placeholder='Search Ōninites'
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              />
              <div className='searchOniniteResultList' style={{ 'display': listDisplay, 'top': view === 'mobile' ? '357px' : geoNameErr === 'none' || !geoNameErr ? '612px' : geoNameErr ? '632px' : '460px' }}>
                {filteredList}
              </div>
              {leaderErr === 'yes' && <p className='errInputSubText'>Please select a leader</p>}
            </div>
            <label htmlFor='nickName' className={`subHeaderBottomSpacing ${(parentEntChoice === 'yes') && 'disabledInputs'}`}>Nickname (optional)</label>
            <input type="text" id='nickName' className={`branchInput inputBottomSpacing ${(parentEntChoice === 'yes') && 'disabledInputs'}`} value={nickname} onChange={(e) => setNickname(e.target.value)}/>
          </div>
          <div className='manageRegionInputColumnContainer'>
            <p className='regionHeaderList'>Branches</p>
            {has_parent === 'no' && regionNodes}
            <br />
            {has_parent === 'no' && regionEntities}
            {has_parent === 'yes' && entityNodes}
          </div>
        </div>
        {showSuccessModal && 
          <div className='supportConfirmModal'>
            <div className='supportConfirmModalContent'>
              <p className='supportConfirmModalText'>Region/Area successfully {deactivateSubmit ? 'deactivated' : 'updated'}!</p>
              <button className='supportConfirmModalButton' onClick={handleSuccess}>Ok</button>
            </div>
          </div>
        }
        {showFailureModal && 
          <div className='supportConfirmModal'>
            <div className='supportConfirmModalContent'>
              <p className='supportConfirmModalText'>Region/Area unsuccessfully {deactivateSubmit ? 'deactivated' : 'updated'}. Please try again.</p>
              <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
            </div>
          </div>
        }
        {showDeactivateModal &&
          <div className='editRegionDeactivateModal'>
            <div className='editRegionDeactivateModalContent'>
              <p className='deactivateHeader'>Deactivate Region/Area</p>
              <svg id="modal-close-btn" className='xClose' xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" onClick={() => setShowDeactivateModal(false)}>
                <path id="Icon_material-close" className='xClose' data-name="Icon material-close" d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z" transform="translate(7.5 7.5)" fill="#a1a3a6"/>
              </svg>
              <p className='deactivateMainText'>There are currently still branches attached to this entity. Please remove and reassign branches or select a region/area to reassign all branches to below.</p>
              <label htmlFor='deactivateRegionsSelect'>Regions/Areas:</label>
              <div className='deactivateRegionsSelect'>
                <select id='deactivateRegionsSelect' className={`branchInput ${transferEntityErr === 'yes' && 'errorInputBorder'}`} value={transferEntity} onChange={(e) => setTransferEntity(e.target.value)} onBlur={(e) => setTransferEntity(e.target.value)}>
                  <option value="">Select</option>
                  {has_parent === 'no' ? entitiesOptions : allEntitiesOptions}
                </select>
                {transferEntityErr === 'yes' && <p className='errInputSubText'>Please select a region or area</p>}
              </div>
              <p className='deactivateMainText'>Once { entity.name} is deactivated it will no longer be available to attach branches to.</p>
              <p className='deactivateMainText'>Please make sure the proper departments are aware or have been notified of the deactivation.</p>
              <button className='deactivateModalButton' onClick={handleDeactivateSubmit}>Deactivate</button>
            </div>
          </div>
        }
        {showDeactivateConfirmModal &&
          <div className='editRegionDeactivateModal'>
            <div className='editRegionDeactivateModalContent'>
              <p className='deactivateHeader'>Deactivate Region/Area</p>
              <svg id="modal-close-btn" className='xClose' xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" onClick={() => setShowDeactivateConfirmModal(false)}>
                <path id="Icon_material-close" className='xClose' data-name="Icon material-close" d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z" transform="translate(7.5 7.5)" fill="#a1a3a6"/>
              </svg>
              <p className='deactivateMainText'>You are about to deactivate {entity.name}</p>
              <p className='deactivateMainText'>Once this region is deactivated it will no longer be available to attach branches to.</p>
              <p className='deactivateMainText'>Please make sure the proper departments are aware or have been notified of the deactivation.</p>
              <button className='deactivateModalButton' onClick={handleDeactivateFinal}>Deactivate</button>
            </div>
          </div>
        }
      </div>
      <div className='branchButtonContainer'>
        <div>
          <button className='updateButton' onClick={handleSubmit}>Update</button>
          <button className='cancelButton' onClick={() => navigate(-1)}>Cancel</button>
        </div>
      </div>
    </>
  )
}

export default EditRegion;