const getDate = (startDate) => {
  let currentDate = new Date();
  let currentDateFormatted = currentDate.toISOString().split('T')[0];
  
  if (startDate > currentDateFormatted) {
    return 'future';
  }else {
    return 'now';
  }
}

export default getDate;