import { useState, useEffect } from "react";
import { getActiveBranchList, getCombinedBranchList } from "./combinedBranches";
import {ORG_API_KEY, ORG_API_URL} from "../config/constants";

const getOrgStruct = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/orgstruct`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return Object.values(data);
  } catch (error) {
    console.error(error);
  }
}

const getNodeTypes = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/node_types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return Object.values(data);
  } catch (error) {
    console.error(error);
  }
}

const getEntities = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/ent`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}
const getOninites = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/emp`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${ORG_API_KEY}`
      }
    });
    let dataRaw = await response.json();
    let data = dataRaw.filter(emp => !emp.inactive_ind);
    return data;
  } catch (error) {
    console.error(error);
  }
}
const getBranches = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/node/v2`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
    // return data.sort((a,b) => ((a.nickname?a.nickname.toLowerCase():a.name.toLowerCase()) < (b.nickname?b.nickname.toLowerCase:b.name.toLowerCase())) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}
const getSSBranches = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/ssbranch`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data.sort((a,b) => (a.branch_name.toLowerCase() < b.branch_name.toLowerCase()) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}
const getSSAccounts = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/staffsuite/account`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data.sort((a,b) => (a.username.toLowerCase() < b.username.toLowerCase()) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}
const getSingleSSAccount = async (id) => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/staffsuite/account/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}
const getDepts = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/dept`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}
const getCorps = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/corp`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    return data.sort((a,b) => (a.corp_name.toLowerCase() < b.corp_name.toLowerCase()) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}
const getJobTitles = async () => {
  try {
    let response = await fetch(`${ORG_API_URL}/v1/org/emp`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ORG_API_KEY}`
      }
    });
    let data = await response.json();
    let jobArr = [];
    for (let emp of data) {
      if (jobArr.includes(emp.title)) {
        continue;
      }else {
        jobArr.push(emp.title);
      }
    }
    return jobArr.sort((a,b) => (a.toLowerCase() < b.toLowerCase()) ? -1 : 1);
  } catch (error) {
    console.error(error);
  }
}


function merge(branches, oninites, depts) {
  var i = 0;
  var j = 0;
  var k = 0;
  var result = [];
  while (i < branches.length && j < oninites.length) {
    if (branches[i].branchKey.name < oninites[j].last_name) {
      result.push(branches[i]);
      i++;
    }else {
      result.push(oninites[j]);
      j++;
    }
  }
  while (j < oninites.length) {
    result.push(oninites[j]);
    j++;
  }
  while (k < depts.length) {
    result.push(depts[k]);
    k++;
  }
  return result;
}
const getSearchData = async (adminLevel) => {
  let org = await getOrgStruct();
  let branchList = await getCombinedBranchList(org, (adminLevel === 'ADM' || adminLevel === 'DEV' || adminLevel === 'SUP') ? 'yes' : 'no', (adminLevel === 'ADM' || adminLevel === 'DEV' || adminLevel === 'SUP') ? 'adminSearch' : 0);
  let oninites = await getOninites();
  let depts = await getDepts();
  let searchList = merge(branchList || [], oninites || [], depts || []);
  return searchList;
}
export {getOrgStruct, getEntities, getOninites, getBranches, getSSBranches, getSSAccounts, getSingleSSAccount, getDepts, getCorps, getJobTitles, getSearchData,getNodeTypes}