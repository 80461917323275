import React, {useState, useEffect} from 'react'
import { useLocation, useParams } from 'react-router';
import SearchBar from './SearchBar';
import Header from './Header';
import OniniteCard from './OniniteCard';
import BranchCard from './BranchCard';
import getBranchIcon from '../functions/branchIcon';
import getDate from '../functions/getDate';

import filterButton from '../assets/sort-icon.svg';
import '../styles/styles.css';

const Search = ({view, data, admin}) => {
  const [sortValue, setSortValue] = useState('All');
  const [selectListDisplay, setSelectListDisplay] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let searchList = useLocation().state.list;
  let deptState = useLocation().state.dept;
  let params = useParams();
  let increment = 0;
  let searchListCards;
  let deptListCards;
  let deptStateArr = [];
  deptStateArr.push(deptState);

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  if (sortValue === 'All') {
    if (searchList) {
      searchListCards = searchList.map(entry => {
        if (entry.branchKey) {
          increment++;
          // let branchIcon = getBranchIcon(entry.branchKey.name, entry.branchKey.corp.corp_ss_id);
          let branchIcon = [];
          if (entry.branchKey.corps?.length > 0) {
            branchIcon = entry.branchKey.corps.map(corp => {
              return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
            })
          }else {
            branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
          }
          return <BranchCard view={view} admin={admin} data={data} region={entry.regionKey} subEntity={entry.entityKey} branch={entry.branchKey} increment={increment} branchIcon={branchIcon}/>
        }else if (entry.first_name) {
          let startDateStatus = getDate(entry?.start_date);
          if (startDateStatus === 'now' && !entry.inactive_ind) {
            increment++;
            return <OniniteCard view={view} admin={admin} emp={entry} data={data} increment={increment}/>
          }
        }else if (entry.name) {
          deptListCards = data.oniniteData.map(emp => {
            for (let dept of data.deptData) {
              if (emp.department_id == dept.id) {
                if (dept.name.toLowerCase() == entry.name?.toLowerCase()) {
                  let startDateStatus = getDate(entry?.start_date);
                  if (startDateStatus === 'now' && !entry.inactive_ind) {
                    increment++;
                    return <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment}/>
                  }
                }
              }
            }
          })
          return deptListCards;
        }
      })
    }else if (deptState) {
      searchListCards = deptStateArr.map(entry => {
          deptListCards = data.oniniteData.map(emp => {
            for (let dept of data.deptData) {
              if (emp.department_id == dept.id) {
                if (dept.name.toLowerCase() == entry.name?.toLowerCase()) {
                  let startDateStatus = getDate(entry?.start_date);
                  if (startDateStatus === 'now' && !entry.inactive_ind) {
                    increment++;
                    return <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment}/>
                  }
                }
              }
            }
          })
          return deptListCards;
      })
    }
  }else if (sortValue === 'Branches') {
    searchListCards = searchList.map(entry => {
      if (entry.branchKey) {
        increment++;
        // let branchIcon = getBranchIcon(entry.branchKey.name, entry.branchKey.corp.corp_ss_id);
        let branchIcon = [];
        if (entry.branchKey.corps?.length > 0) {
          branchIcon = entry.branchKey.corps.map(corp => {
            return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
          })
        }else {
          branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
        }
        return <BranchCard view={view} admin={admin} data={data} region={entry.regionKey} subEntity={entry.entityKey} branch={entry.branchKey} increment={increment} branchIcon={branchIcon}/>
      }
    })
  }else if (sortValue === 'Oninites') {
    searchListCards = searchList.map(entry => {
      if (entry.first_name) {
        let startDateStatus = getDate(entry?.start_date);
        if (startDateStatus === 'now' && !entry.inactive_ind) {
          increment++;
          return <OniniteCard view={view} admin={admin} emp={entry} data={data} increment={increment}/>
        }
      }
    })
  }

  if ((searchList && searchList.length == 0) || (deptStateArr && deptStateArr.length == 0)) {
    searchListCards = <p className='noSearchResultsText'>We're sorry, we couldn't find any results for "{params.search}".</p>
  }

  return (
    <div onClick={() => selectListDisplay===true ? setSelectListDisplay(false) : ''}>
      {view === 'desktop' &&
        <div className='sortContainer'>
          <div className='sortInnerContainer'>
            <a></a>
            <div className='select-wrapper'>
              <div className='selectButtonContainer' onClick={() => setSelectListDisplay(!selectListDisplay)} style={{ boxShadow: selectListDisplay ? "0px 0px 6px rgb(0,0,0,16%)" : '' , borderRadius: selectListDisplay ? "5px 5px 0px 0px" : "5px"}}>
                <p>Sort: {sortValue}</p>
                <svg style={{ transform: selectListDisplay ? "rotate(180deg)" : ""}} xmlns="http://www.w3.org/2000/svg" width="12" height="7.787" viewBox="0 0 12 7.787">
                  <path id="Path_36" data-name="Path 36" d="M8,9.787l-6-6L3.787,2,8,6.213,12.213,2,14,3.787Z" transform="translate(-2 -2)" fill="#a2a2a2"/>
                </svg>
              </div>
              <div className='selectList' style={{ "display": !selectListDisplay ? 'none' : 'block' }}>
                <p className='selectListItem' onClick={function () {setSortValue('All'); setSelectListDisplay(false)}}>All</p>
                <p className='selectListItem' onClick={function () {setSortValue('Branches'); setSelectListDisplay(false)}}>Branches</p>
                <p className='selectListItem' onClick={function () {setSortValue('Oninites'); setSelectListDisplay(false)}}>Ōninites</p>
              </div>
            </div>
          </div>
        </div>
      }
      {view === 'mobile' &&
        <>
          <div className='mobileSearchFilterContainerDetailPage'>
            <SearchBar data={data}/>
            <img src={filterButton} className='mobileFilterButton' alt='filter' onClick={() => setShowModal(!showModal)}/>
          </div>
          {showModal &&
            <div className='modal' onClick={() => setShowModal(false)}>
              <div className='modalContentEmp'>
                <p className='modalLineItem'>Sort Results</p>
                <p className='modalLineItem' onClick={() => setSortValue('All')}>All</p>
                <p className='modalLineItem' onClick={() => setSortValue('Branches')}>Branches</p>
                <p className='modalLineItem' onClick={() => setSortValue('Oninites')}>Ōninites</p>
              </div>
            </div>
          }
        </>
      }
      <Header type='primary' view={view}>{increment} Result(s) for "{params.search}"</Header>
      {searchListCards}
    </div>
  )
}

export default Search;