import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import Header from './Header';
import OniniteCard from './OniniteCard';
import { handleAuthClick,gapiLoaded,gisLoaded } from '../functions/sheetDownload';
import getDate from '../functions/getDate';

import filterButton from '../assets/sort-icon.svg';
import '../styles/styles.css';

const Oninites = ({view, data, admin, userType}) => {
  const [sortValue, setSortValue] = useState(sessionStorage.getItem('oSort') ??'Name');
  const [selectListDisplay, setSelectListDisplay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showGoogleModal, setShowGoogleModal] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [downloadMsg, setDownloadMsg] = useState("");
  
  let increment = 0;
  let oniniteListItems;

  const notInitialRender = useRef(false);
  useEffect(() => {
    if (notInitialRender.current) {
      window.scrollTo(0,0);
    }else {
      notInitialRender.current = true;
    }
  }, [sortValue]);

   // Initialize Google Client Libraries on load
  useEffect(() => {
    gisLoaded();
    gapiLoaded();
  }, []);

  if (sortValue === 'Name') {

    let oniniteMap = new Map();
    for (let emp of data.oniniteData) {
      if (!oniniteMap.get(emp.last_name[0].toUpperCase())) {
        let startDateStatus = getDate(emp?.start_date);
        if (startDateStatus === 'now' || admin === 'yes') {
          oniniteMap.set(emp.last_name[0].toUpperCase(), [emp])
        }
      }else {
        let startDateStatus = getDate(emp?.start_date);
        if (startDateStatus === 'now' || admin === 'yes') {
          oniniteMap.get(emp.last_name[0].toUpperCase()).push(emp)
        }
      }
    }

    oniniteListItems = Array.from(oniniteMap).map(([letter, empList]) => {
      let cardList = empList.map((emp, key) => {
        increment++;
        return (
          <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment} key={key}/>
        )
      })

      return (
        <>
          <Header type="primary" view={view} key={letter}>{letter}</Header>
          {cardList}
        </>
      )
    }) 
  }else if (sortValue === 'Job Title') {
    oniniteListItems = data.jobData.map(job => {
      let okHeaderStatus = false;

      let fullOniniteList = data.oniniteData.map((emp, key) => {
        let startDateStatus = getDate(emp?.start_date);
        if (emp.title === job && (startDateStatus === 'now' || admin === 'yes')) {
          increment++;
          okHeaderStatus = true;
          return (
            <>
              <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment} key={key}/>
            </>
          )
        }
      })

      return (
        <>
          {okHeaderStatus && <Header type="primary" view={view} key={job}>{job !== "" ? job : "No Title Found"}</Header>}
          {fullOniniteList}
        </>
      )
    })
  }
  // else if (sortValue === 'Department') {
  //   oniniteListItems = data.deptData.map(dept => {

  //     let fullOniniteList = data.oniniteData.map(emp => {
        
  //       if (emp.department_id === dept.id) {
  //         increment++;
  //         return (
  //           <>
  //             <OniniteCard view={view} admin={admin} emp={emp} data={data} increment={increment} key={emp.id}/>
  //           </>
  //         )
  //       }
  //     })
      
  //     let deptHeaderOkStatus = false;
  //     if (data.oniniteData.some(emp => emp.department_id === dept.id)) {
  //       deptHeaderOkStatus = true;
  //     }
  //     return (
  //       <>
  //         {deptHeaderOkStatus && <Header type="primary" view={view} key={dept.name}>{dept.name}</Header>}
  //         {fullOniniteList}
  //       </>
  //     )
  //   })
  // }

  const handleSelects = () => {
    if (selectListDisplay) setSelectListDisplay(false);
    if (showGoogleModal) setShowGoogleModal(false)
  }
  
  const handleGoogleDownload = async() => {
    try{
      let link = await handleAuthClick(data,'oninites');
      // console.log(`link: ${link}`)
      // currently this isn't working
      setDownloadLink(link)
      // console.log(`download link: ${downloadLink}`)
      setDownloadMsg("Success! Your new gsheet will open shortly!")
      setShowGoogleModal(true);
    }catch(error){
      console.log(error);
      setDownloadMsg("OOPS! Something went wrong!")
      setShowGoogleModal(true);
    }
  }

  return (
    <div onClick={handleSelects}>
      {view === 'desktop' &&
        <>
          <div className='sortContainer'>
            <div className='sortInnerContainer'>
              <div className='downloadButtonContainer' onClick={handleGoogleDownload}>
                <a className='downloadButton'>Download Ōninite List (Google Sheet)</a>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.4" viewBox="0 0 12 14.4">
                  <path id="Icon_ionic-md-download" data-name="Icon ionic-md-download" d="M18.75,9.581H15.322V4.5H10.177V9.581H6.75l6,5.929ZM6.75,17.2V18.9h12V17.2Z" transform="translate(-6.75 -4.5)" fill="#004987"/>
                </svg>
              </div>
              <div className='select-wrapper'>
                <div className='selectButtonContainer' onClick={() => setSelectListDisplay(!selectListDisplay)} style={{ boxShadow: selectListDisplay ? "0px 0px 6px rgb(0,0,0,16%)" : '' , borderRadius: selectListDisplay ? "5px 5px 0px 0px" : "5px"}}>
                  <p>Sort: {sortValue}</p>
                  <svg style={{ transform: selectListDisplay ? "rotate(180deg)" : ""}} xmlns="http://www.w3.org/2000/svg" width="12" height="7.787" viewBox="0 0 12 7.787">
                    <path id="Path_36" data-name="Path 36" d="M8,9.787l-6-6L3.787,2,8,6.213,12.213,2,14,3.787Z" transform="translate(-2 -2)" fill="#a2a2a2"/>
                  </svg>
                </div>
                <div className='selectList' style={{ "display": !selectListDisplay ? 'none' : 'block' }}>
                  <p className='selectListItem' onClick={function () {sessionStorage.setItem('oSort', "Name"); setSortValue(sessionStorage.getItem('oSort')); setSelectListDisplay(false)}}>Name</p>
                  <p className='selectListItem' onClick={function () {sessionStorage.setItem('oSort', "Job Title"); setSortValue(sessionStorage.getItem('oSort')); setSelectListDisplay(false)}}>Job Title</p>
                  {/* <p className='selectListItem' onClick={function () {setSortValue('Department'); setSelectListDisplay(false)}}>Department</p> */}
                </div>
              </div>
            </div>
          </div>
          {showGoogleModal && 
            <div className='googleModal'>
              <div className='googleModalContent'>
                <p className='googleModalText'>Thank you! Your download will open up in a new tab soon!</p>
                <button className='googleModalButton' onClick={() => setShowGoogleModal(false)}>Ok</button>
              </div>
            </div>
          }
        </>
      }
      {view === 'mobile' &&
        <>
          <div className='mobileSearchFilterContainer'>
            <SearchBar data={data}/>
            <img src={filterButton} className='mobileFilterButton' alt='filter' onClick={() => setShowModal(!showModal)}/>
          </div>
          {showModal &&
            <div className='modal' onClick={() => setShowModal(false)}>
              <div className='modalContentEmp'>
                <p className='modalLineItem'>Sort Ōninites</p>
                <p className={`modalLineItem ${sortValue === 'Name' && 'modalLineItemActive'}`} onClick={function () {sessionStorage.setItem('oSort', "Name"); setSortValue(sessionStorage.getItem('oSort')); setSelectListDisplay(false)}}>Name</p>
                <p className={`modalLineItem ${sortValue === 'Job Title' && 'modalLineItemActive'}`} onClick={function () {sessionStorage.setItem('oSort', "Job Title"); setSortValue(sessionStorage.getItem('oSort')); setSelectListDisplay(false)}}>Job Title</p>
                {/* <p className='modalLineItem' onClick={() => setSortValue('Department')}>Department</p> */}
              </div>
            </div>
          }
        </>
      }
      {oniniteListItems}
    </div>
  )
}

export default Oninites;