import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilValue, useResetRecoilState } from "recoil";
import {nameState, photoState, emailState, permissionState, loggedInState} from '../data/atoms';
import logo from '../assets/PhoninBook-Light-Mobile.svg';
import mobileBackButton from '../assets/mobileBackButton.svg';
import gizmos from '../assets/gizmosLogo.svg';
import logoutImage from '../assets/logout.svg';
import '../styles/styles.css';

const MobileNavBar = () => {
  const [oActive, setOActive] = useState("");
  const [bActive, setBActive] = useState("activeLink");
  const [photoClicked, setPhotoClicked] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  const userName = useRecoilValue(nameState);
  const userPhoto = useRecoilValue(photoState);
  const userEmail = useRecoilValue(emailState);
  const resetName = useResetRecoilState(nameState);
  const resetEmail = useResetRecoilState(emailState);
  const resetPerms = useResetRecoilState(permissionState);
  const resetLogged = useResetRecoilState(loggedInState);
  const resetPhoto = useResetRecoilState(photoState);

  useEffect(() => {
    if (location.pathname === '/Branches' || location.pathname === '/') {
      setBActive("activeLink");
      setOActive("");
    }else if (location.pathname === '/Oninites') {
      setOActive("activeLink");
      setBActive("");
    }else {
      setOActive("");
      setBActive("");
    }

  }, [location.pathname]);

  const travel = (location) => {
    navigate(location);
    window.scrollTo(0,0);
  }

  const goHome = () => {
    navigate('/Branches');
    window.scrollTo(0,0);
  }

  const logout = () => {
    resetEmail();
    resetLogged();
    resetName();
    resetPerms();
    resetPhoto();
    window.location.href = `https://auth.oningizmos.com/accounts/logout/?next=${window.location.href}`;
  }

  return (
    <>
    <div className="gizmosHeader">
      <img src={gizmos} className="logo"/>
      <div className={'logoutContainer'}>
        <img src={userPhoto} className={'userPhoto'} onClick={() => setPhotoClicked(!photoClicked)}/>
        {photoClicked &&
          <div className={'userInfoContainer'}>
            <div style={{ display: 'flex', padding: '11px 16px' }}>
              <img src={userPhoto} className={'userPhotoNonClick'}/>
              <div>
                <p style={{ fontWeight: 'bold', marginBottom: '3px' }}>{userName}</p>
                <p>{userEmail}</p>
              </div>
            </div>
            <div className={'logoutInnerContainer'}>
              <div className={'logoutInnerInnerContainer'} onClick={logout}>
                <img src={logoutImage}/>
                <p className={'logoutText'}>Logout</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <nav className='mobileNav'>
      <div className='mobileNavBackButtonContainer'>
        {(location.pathname.includes('/Branch/') || location.pathname.includes('/Oninite/') || location.pathname.includes('/Search/')) && <img src={mobileBackButton} alt='back button' width='18px' height='18px' className='mobileBackButton' onClick={() => navigate(-1)}/>}
        <img src={logo} className='mainLogo' width="150px" height='32px' alt='logo' onClick={goHome}/>
      </div>
      <div className='navLinkContainer'>
        <a className='navItem' id={bActive} onClick={() => travel('/Branches')}>Branches</a>
        <a className='navItem' id={oActive} onClick={() => travel('/Oninites')}>Ōninites</a>
      </div>
    </nav>
    </>
  )
}

export default MobileNavBar;