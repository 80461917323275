import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ORG_API_URL, ORG_API_KEY } from '../../../../config/constants';


const AddRegionForm = ({view, data, admin}) => {
  let parent = useLocation().state.parent;
  let direct_ent = useLocation().state.directent;
  
  const [submit, setSubmit] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [listDisplay, setListDisplay] = useState("none");
  const [filteredList, setFilteredList] = useState("");
  const [hideList, setHideList] = useState(false);

  const [geoName, setGeoName] = useState("");
  const [geoNameErr, setGeoNameErr] = useState(false);
  const [parEnt, setParEnt] = useState("");
  const [parEntErr, setParEntErr] = useState(false);
  const [dirEnt, setDirEnt] = useState(direct_ent==="no" && parent === "no"? 0: "");
  const [directEntChoice, setdirectEntChoice] = useState(direct_ent);
  const [directEntErr, setDirectEntErr] = useState(false);
  const [directEntOpt, setDirectEntOpt] = useState([]);
  const [leader, setLeader] = useState("");
  const [leaderErr, setLeaderErr] = useState(false);
  const [nickname, setNickname] = useState("");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  let navigate = useNavigate();


  useEffect(() => {
    setHideList(false);
    filterOniniteData();
  }, [searchField]);
 
// Set Direct Entity if it does not have Direct Entity 
  useEffect(() => {
    if(direct_ent === "no"){
      setDirEnt(parEnt)
    }
  }, [parEnt]);

// Conditionlly set Direct Entity options based on selected Parent Entity
  useEffect(() => {
    if (direct_ent === "yes"){ 
    // Set directParent_opt to parEnt subentities
     data.orgData.forEach(orgRegion => {
      if(parseInt(orgRegion.id) === parseInt(parEnt)){
        if(orgRegion.entities!==null){
        let subentities = orgRegion.entities
        setdirectEntChoice("yes")
        // Loop through sub-entities
        subentities.forEach( subentity =>{
            if(subentity.entities !== null){
              
              //add any nested entities 
              subentity.entities.forEach(nestedEntity => {
                subentities.push(nestedEntity)
                return nestedEntity
              }) 
            }
            return subentity.entities
          })
        setDirectEntOpt(subentities)
        // console.log(`UseEffect Value:${JSON.stringify(subentities)}`)
        }else{
          // console.log(`UseEffect Region:${JSON.stringify(orgRegion)}`)
          setdirectEntChoice("no")
          setDirEnt(parEnt)
        }
      }
     })
    }  
  }, [parEnt]);

  const filterOniniteData = () => {
    if (searchField !== "" && data.oniniteData !== null && !hideList) {
      let list = data.oniniteData.filter(emp => {
        if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.title && emp.title.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }
      });

      const setValue = (emp) => {
        setSearchField(emp.first_name + " " + emp.last_name);
        setLeader(emp.id);
        setHideList(true);
      }
      
      if (list.length > 0) {
        setListDisplay("block");
        setFilteredList(list.map(emp => {
          if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.first_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.last_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase()) && emp.first_name) {
            return <p className='searchBranchItem' id={emp.first_name + "space"} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.title && emp.title.toLowerCase().includes(searchField.trim().toLowerCase())) {
            return <p className='searchBranchItem' id={emp.title} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name} <span className='searchHelperText'>({emp.title})</span></p>
          }
        }))
      }else  {
        setListDisplay("none");
        setFilteredList("");
        setLeader('');
      }
    } else {
      setListDisplay("none");
      setFilteredList("");
      // setLeader('');
    }
  }

  // let entitiesOptions = data.entData.map(ent => {
  //   return <option value={ent.id} key={ent.id}>{ent.name}</option>
  // });
  // let leaderOptions = data.orgData.map(region => {
  //   return <option value={region.manager?.id} key={region.id}>{region.manager?.first_name} {region.manager?.last_name} -  ({(region.name)})</option>
  // });
  let entitiesOptions = data.orgData.map(region => {
    return <option value={region.id} key={region.id}>{region.name}</option>
  });

  const handleSuccess = () => {
    navigate(`/ManageRegion`);
    window.location.reload();
  }

  const handleFailure = () => {
    window.location.reload();
  }

  const sendToApi = async () => {
    let type = 1;
    for (let region of data.orgData) {
      if (parEnt == region.id) {
        if (region.type === 'Region') {
          type = 4;
        }else if (region.type === 'Area') {
          type = 3;
        }
      }
    }

    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/ent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          id: 0,
          name: geoName,
          nickname: nickname,
          type_id: type,
          parent_entity_id: Number(parEnt) ?? 0,
          direct_entity_id: Number(dirEnt) ?? 0,
          manager_id: Number(leader)
        })
      });
      let resp = await response && response.json();
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  //handling final error check before send to API
  useEffect(() => {
    if (submit) {
      if (geoNameErr === 'none' && parEntErr === 'none' && leaderErr === 'none') {
        sendToApi();
      }
    }
  }, [submit]);

  //handling error check upon submit
  const handleSubmit = () => {
    geoName === "" ? setGeoNameErr('yes') : setGeoNameErr('none');
    parEnt === "" && parent === 'yes' ? setParEntErr('yes') : setParEntErr('none');
    dirEnt === "" && directEntChoice === 'yes' ? setDirectEntErr('yes') : setDirectEntErr('none');
    leader === "" ? setLeaderErr('yes') : setLeaderErr('none');
    
    if (submit) {
      (async () => {
        setSubmit(false);
      })().then(() => setSubmit(true))
    }else {
      setSubmit(true);
    }
  }

  //handling error check upon input change
  useEffect(() => {
    if (geoNameErr) {
      geoName !== "" ? setGeoNameErr('none') : setGeoNameErr('yes');
    }
    if (parEntErr) {
      parEnt !== "" ? setParEntErr('none') : setParEntErr('yes');
    }
    if (leaderErr) {
      leader !== "" ? setLeaderErr('none') : setLeaderErr('yes');
    }
  }, [geoName, parEnt, leader])

  return (
    <>
      <div className='regionMainContainer' onClick={() => listDisplay !== 'none' ? setListDisplay('none') : ""}>
        <p className='regionHeader'>Add New Region/Area</p>
        <div className='manageRegionInputColumnContainer'>
          <label htmlFor='geoName' className='subHeaderBottomSpacing'>Geographical Name</label>
          <p className='subHeaderBottomSpacing subHeaderHelperText'>Do not name as rollup</p>
          <div className='editBranchBottomSpacing'>
            <input type="text" id='geoName' className={`branchInput ${geoNameErr === 'yes' && 'errorInputBorder'}`} value={geoName} onChange={(e) => setGeoName(e.target.value)}/>
            {geoNameErr === 'yes' && <p className='errInputSubText'>Please enter a geographical name</p>}
          </div>
          {parent === 'yes' && 
            <>
              <label htmlFor='parentEntity' className={`subHeaderBottomSpacing`}>Parent Entity</label>
              <div className='editBranchBottomSpacing'>
                <select className={`branchInput ${parEntErr === 'yes' && 'errorInputBorder'}`} id='parentEntity' value={parEnt} onChange={(e) => setParEnt(e.target.value)} onBlur={(e) => setParEnt(e.target.value)}>
                  <option value="">Select</option>
                  {entitiesOptions}
                </select>
                {parEntErr === 'yes' && <p className='errInputSubText'>Please select a parent entity</p>}
              </div>
            </>
          }
          {directEntChoice === 'yes' && parEnt !== "" &&
            <>
              <label htmlFor='parentEntity' className={`subHeaderBottomSpacing`}>Direct Entity</label>
              <div className='editBranchBottomSpacing'>
                <select className={`branchInput ${directEntErr === 'yes' && 'errorInputBorder'}`} id='dirEntity' value={dirEnt} onChange={(e) => setDirEnt(e.target.value)} onBlur={(e) => setDirEnt(e.target.value)}>
                  <option value="">Select</option>
                  { directEntOpt.map(ent => {
                  let opt = <option value={ent.id} key={ent.id}>{ent.name}</option>
                  
                  return opt
                }) }
                </select>
                {directEntErr === 'yes' && <p className='errInputSubText'>Please select a direct entity</p>}
              </div>
            </>
          }
          {directEntChoice === 'yes' && parEnt === "" &&
          <>
          <div className='editBranchBottomSpacing'>
              <p className='subHeaderBottomSpacing subHeaderHelperText'>Selected Parent to view available Direct Entities.</p>
          </div>
          </>
          }
          {directEntChoice === 'no' && parent ==='yes' &&
          <>
          <div className='editBranchBottomSpacing'>
              <p className='subHeaderBottomSpacing subHeaderHelperText'>Selected Parent does not have any Sub-Entities. Direct Entity will default to Parent Entity.</p>
          </div>
          </>
          }
          <label htmlFor='leaderName' className={`subHeaderBottomSpacing`}>Leader Name</label>
          <div className='editBranchBottomSpacing'>
            <input 
            className={`branchInput ${leaderErr === 'yes' && 'errorInputBorder'}`}
            type='text'
            placeholder='Search Ōninites'
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            />
            <div className='searchOniniteResultList' style={{ 'display': listDisplay, 'top': view === 'mobile' ? '357px' : (geoNameErr === 'none' || !geoNameErr) && parent === 'no' ? '377px' : geoNameErr ? '395px' : '510px' }}>
              {filteredList}
            </div>
            {leaderErr === 'yes' && <p className='errInputSubText'>Please select a leader</p>}
          </div>
          {parent === 'no' &&
            <>
              
              <label htmlFor='nickname' className='subHeaderBottomSpacing'>Nickname (optional)</label>
              <input type="text" id='nickname' className='branchInput inputBottomSpacing' onChange={(e) => setNickname(e.target.value)} value={nickname}/>
              
            </>
          }
        </div>
      </div>
      {showSuccessModal && 
        <div className='supportConfirmModal'>
          <div className='supportConfirmModalContent'>
            <p className='supportConfirmModalText'>Region/Area successfully created!</p>
            <button className='supportConfirmModalButton' onClick={handleSuccess}>Ok</button>
          </div>
        </div>
      }
      {showFailureModal && 
        <div className='supportConfirmModal'>
          <div className='supportConfirmModalContent'>
            <p className='supportConfirmModalText'>Region/Area unsuccessfully created. Please try again.</p>
            <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
          </div>
        </div>
      }
      <div className='branchButtonContainer'>
        <div>
          <button className='updateButton' onClick={handleSubmit}>Add</button>
          <button className='cancelButton' onClick={() => navigate(-1)}>Back</button>
        </div>
      </div>
    </>
  )
}

export default AddRegionForm;