import React, {useState, useEffect, useRef} from 'react'
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import getBranchIcon from '../functions/branchIcon';
import { getSearchData } from '../functions/dataCalls';
import getDate from '../functions/getDate';
import '../styles/styles.css';

const SearchBar = ({data}) => {
  // const [searchData, setSearchData] = useState(null);
  const [searchField, setSearchField] = useState("");
  const [filteredList, setFilteredList] = useState("");
  const [unFormatList, setUnformatList] = useState("");
  const [listDisplay, setListDisplay] = useState("none");
  const [cursor, setCursor] = useState(-1);
  const navigate = useNavigate();
  let location = useLocation();
  let inputSelect = useRef();

  window.onclick = function() {
    setListDisplay("none");
    setCursor(-1);
  }

  useEffect(() => {
    setSearchField("");
  }, [location.pathname]);

  //Filter when arrow keys are pressed while there are search results present
  useEffect(() => {
    if (listDisplay !== 'none') {
      filterData();
    }
  }, [cursor]);

  //Filter after every input change
  useEffect(() => {
    filterData();
    setCursor(-1);
  }, [searchField]);

  //Generate search list results
  const filterData = () => {
    if (searchField !== "" && data.searchData !== null) {
      let list = data.searchData.filter(entry => {
        if (entry.branchKey?.name && (entry.branchKey.name.toLowerCase().startsWith(searchField.toLowerCase()) || entry.branchKey.name.toLowerCase().includes(searchField.toLowerCase()))) {
          return entry;
        }else if (entry.branchKey?.nickname && (entry.branchKey.nickname.toLowerCase().startsWith(searchField.toLowerCase()) || entry.branchKey.nickname.toLowerCase().includes(searchField.toLowerCase()))) {
          return entry;
        }else if (entry.branchKey?.code && entry.branchKey.code.toLowerCase().startsWith(searchField.toLowerCase())) {
          return entry;
        }else if (entry.branchKey?.email && entry.branchKey.email.toLowerCase().startsWith(searchField.toLowerCase())) {
          return entry;
        }else if (entry.branchKey?.phone && entry.branchKey.phone.replaceAll('.', '').startsWith(searchField.replaceAll('-', ''))) {
          return entry;
        }else if (entry.name?.toLowerCase().startsWith(searchField.toLowerCase())) {
          return entry;
        }else if (entry.last_name && entry.last_name.toLowerCase().startsWith(searchField.toLowerCase())) {
          return entry;
        }else if (entry.first_name && entry.first_name.toLowerCase().startsWith(searchField.toLowerCase())) {
          return entry;
        }else if ((entry.first_name?.toLowerCase() + " " + entry.last_name?.toLowerCase()).startsWith(searchField.toLowerCase())) {
          return entry;
        }else if (entry.title && (entry.title.toLowerCase().startsWith(searchField.toLowerCase()) || entry.title.toLowerCase().includes(searchField.toLowerCase()))) {
          return entry;
        }else if (entry.email && entry.email.toLowerCase().startsWith(searchField.toLowerCase())) {
          return entry;
        }else if (entry.phone && entry.display_phone && entry.phone.replaceAll('.', '').startsWith(searchField.replaceAll('-', ''))) {
          return entry;
        }
      });
      setUnformatList(list);

      if (list.length > 0) {
        setListDisplay("block");
        setFilteredList(list.slice(0,10).map((entry, index) => {
          if (entry.branchKey?.name && (entry.branchKey.name.toLowerCase().startsWith(searchField.toLowerCase()) || entry.branchKey.name.toLowerCase().includes(searchField.toLowerCase()))) {
            // let branchIcon = getBranchIcon(entry.branchKey.name, entry.branchKey?.corp?.corp_ss_id);
            let branchIcon = [];
            if (entry.branchKey.corps?.length > 0) {
              branchIcon = entry.branchKey.corps.map(corp => {
                return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
              })
            }else {
              branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
            }
            return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.branchKey?.name} data-type="branch" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Branch/${entry.branchKey.id}`, {state: {branch: entry.branchKey, region: entry.regionKey, subEntity: entry.entityKey, branchIcon: branchIcon}})}>{entry.branchKey.name} ({entry.branchKey.code})</p>
          }else if (entry.branchKey?.nickname && (entry.branchKey.nickname.toLowerCase().startsWith(searchField.toLowerCase()) || entry.branchKey.nickname.toLowerCase().includes(searchField.toLowerCase()))) {
            // let branchIcon = getBranchIcon(entry.branchKey.name, entry.branchKey?.corp?.corp_ss_id);
            let branchIcon = [];
            if (entry.branchKey.corps?.length > 0) {
              branchIcon = entry.branchKey.corps.map(corp => {
                return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
              })
            }else {
              branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
            }
            return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.branchKey?.name} data-type="branch" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Branch/${entry.branchKey.id}`, {state: {branch: entry.branchKey, region: entry.regionKey, subEntity: entry.entityKey, branchIcon: branchIcon}})}>{entry.branchKey.name} ({entry.branchKey.code})</p>
          }else if (entry.branchKey?.code && entry.branchKey.code.toLowerCase().startsWith(searchField.toLowerCase())) {
            let branchIcon = [];
            if (entry.branchKey.corps?.length > 0) {
              branchIcon = entry.branchKey.corps.map(corp => {
                return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
              })
            }else {
              branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
            }
            return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.branchKey?.code} data-type="branch" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Branch/${entry.branchKey.id}`, {state: {branch: entry.branchKey, region: entry.regionKey, subEntity: entry.entityKey, branchIcon: branchIcon}})}>{entry.branchKey.name} ({entry.branchKey.code})</p>
          }else if (entry.branchKey?.email && entry.branchKey.email.toLowerCase().startsWith(searchField.toLowerCase())) {
            let branchIcon = [];
            if (entry.branchKey.corps?.length > 0) {
              branchIcon = entry.branchKey.corps.map(corp => {
                return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
              })
            }else {
              branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
            }
            return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.branchKey?.code} data-type="branch" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Branch/${entry.branchKey.id}`, {state: {branch: entry.branchKey, region: entry.regionKey, subEntity: entry.entityKey, branchIcon: branchIcon}})}>{entry.branchKey.email} ({entry.branchKey.name})</p>
          }else if (entry.branchKey?.phone && entry.branchKey.phone.replaceAll('.', '').startsWith(searchField.replaceAll('-', ''))) {
            let branchIcon = [];
            if (entry.branchKey.corps?.length > 0) {
              branchIcon = entry.branchKey.corps.map(corp => {
                return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
              })
            }else {
              branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
            }
            return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.branchKey?.code} data-type="branch" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Branch/${entry.branchKey.id}`, {state: {branch: entry.branchKey, region: entry.regionKey, subEntity: entry.entityKey, branchIcon: branchIcon}})}>{entry.branchKey.phone.replaceAll('.', '-')} ({entry.branchKey.name})</p>
          } else if (entry.name?.toLowerCase().startsWith(searchField.toLowerCase())) {
            let startDateStatus = getDate(entry?.start_date);
            if (startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.name} data-type="dept" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Search/${encodeURIComponent(searchField)}`, {state: {dept: entry}})}>{entry.name} (Department)</p>
            }
          }else if (entry.last_name && entry.last_name.toLowerCase().startsWith(searchField.toLowerCase())) {
            let startDateStatus = getDate(entry?.start_date);
            if (startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.first_name + " " + entry.last_name} data-type="name" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Oninite/${entry.id}`, {state: {emp: entry}})}>{entry.last_name}, {entry.first_name}</p>
            }
          }else if (entry.first_name && entry.first_name.toLowerCase().startsWith(searchField.toLowerCase())) {
            let startDateStatus = getDate(entry?.start_date);
            if (startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.first_name + " " + entry.last_name} data-type="name" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Oninite/${entry.id}`, {state: {emp: entry}})}>{entry.first_name} {entry.last_name}</p>
            }
          }else if ((entry.first_name?.toLowerCase() + " " + entry.last_name?.toLowerCase()).startsWith(searchField.toLowerCase()) && entry.first_name) {
            let startDateStatus = getDate(entry?.start_date);
            if(startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.first_name + " " + entry.last_name} data-type="name" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Oninite/${entry.id}`, {state: {emp: entry}})}>{entry.first_name} {entry.last_name}</p>
            }
          }else if (entry.title && (entry.title.toLowerCase().startsWith(searchField.toLowerCase()) || entry.title.toLowerCase().includes(searchField.toLowerCase()))) {
            let startDateStatus = getDate(entry?.start_date);
            if (startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.title} data-type="name" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Oninite/${entry.id}`, {state: {emp: entry}})}>{entry.first_name} {entry.last_name} <span className='searchHelperText'>({entry.title})</span></p>
            }
          }else if (entry.email && entry.email.toLowerCase().startsWith(searchField.toLowerCase())) {
            let startDateStatus = getDate(entry?.start_date);
            if (startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.email} data-type="name" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Oninite/${entry.id}`, {state: {emp: entry}})}>{entry.email} ({entry.first_name} {entry.last_name})</p>
            }
          }else if (entry.phone && entry.display_phone && entry.phone.replaceAll('.', '').startsWith(searchField.replaceAll('-', ''))) {
            let startDateStatus = getDate(entry?.start_date);
            if (startDateStatus === 'now' && !entry.inactive_ind) {
              return <p className={`searchItem ${cursor === index ? 'active' : ''}`} ref={cursor === index ? inputSelect : null} id={entry.phone} data-type="name" data-entry={JSON.stringify(entry)} onClick={() => navigate(`/Oninite/${entry.id}`, {state: {emp: entry}})}>{entry.phone.replaceAll('.', '-')} ({entry.first_name} {entry.last_name})</p>
            }
          }
        }))
      }else {
        setListDisplay("none");
        setFilteredList("");
        setCursor(-1);
      }

    }else {
      setListDisplay("none");
      setFilteredList("");
      setCursor(-1);
    }
  }

  const handleChange = e => {
    setSearchField(e.target.value);
  };
  const handleKeyPress = e => {
    if ((e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) && searchField.trim() !== "" && cursor <= -1) {
      navigate(`/Search/${encodeURIComponent(searchField)}`, {state: {list: unFormatList}});
      setSearchField("");
    } else if ((e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) && cursor > -1 && inputSelect.current.id !== "") {
      //Handling clicking enter if hovering over search item
      let entry = JSON.parse(inputSelect.current.dataset.entry);
      switch (inputSelect.current.dataset.type) {
        case 'branch':
          let branchIcon = [];
          if (entry.branchKey.corps?.length > 0) {
            branchIcon = entry.branchKey.corps.map(corp => {
              return getBranchIcon(entry.branchKey.name, corp?.corp_ss_id);
            })
          }else {
            branchIcon.push(getBranchIcon(entry.branchKey.name, ""));
          }
          navigate(`/Branch/${entry.branchKey.id}`, {state: {branch: entry.branchKey, region: entry.regionKey, subEntity: entry.entityKey, branchIcon: branchIcon}});
          setSearchField("");
          setCursor(-1);
          break;
        case 'name':
          navigate(`/Oninite/${entry.id}`, {state: {emp: entry}});
          setSearchField("");
          setCursor(-1);
          break;
        case 'dept':
          navigate(`/Search/${encodeURIComponent(searchField)}`, {state: {dept: entry}});
          setSearchField("");
          setCursor(-1);
          break;
      }
    }
  }
  const handleButtonPress = () => {
    if (searchField.trim() !== "") {
      navigate(`/Search/${encodeURIComponent(searchField)}`, {state: {list: unFormatList}});
      setSearchField("");
      setCursor(-1);
    }
  }


  //Handling up/down arrow keys through search results
  const handleKeyDown = (e) => {
    if (filteredList.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          if (cursor === filteredList.length - 1) {
            setCursor(0);
          }else {
            setCursor(cursor + 1);
          }
          break;
        case 'ArrowUp' :
          if (cursor === 0) {
            setCursor(filteredList.length - 1);
          }else if (cursor > 0) {
            setCursor(cursor - 1);
          }
          break;
      }
    }
  }

  return (
    <>
      <div className='outerSearchBarContainer' style={{ "borderRadius": listDisplay === 'block' ? '5px 5px 0px 0px' : '5px' }}>
        <div className='innerSearchBarContainer'>
          <input 
            className='searchBar'
            type='text'
            placeholder='Search'
            value={searchField}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
          />
          <img className='searchIcon' alt='search button' onClick={handleButtonPress}/>
        </div>
        <div className='searchResultList' style={{ 'display': listDisplay }}>
            {filteredList}
        </div>
      </div>
    </>
  )
}

export default SearchBar;