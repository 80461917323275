import BranchCard from '../components/BranchCard';
import Header from '../components/Header';
import getBranchIcon from './branchIcon';

const EntityStructure = ({entity, adminSortValue, admin, data, view, region, increment, levelsDeep}) => {
    let subHeaderStatus;
    if (admin === 'yes' && adminSortValue === 'Physical') {
        subHeaderStatus = entity.nodes && entity.nodes.some((entity) => !entity.inactive_ind && entity.display_ind === 1);
    }else if (admin === 'yes' && adminSortValue === 'Virtual') {
        subHeaderStatus = entity.nodes && entity.nodes.find((entity) => (entity.node_type_id===3) && !entity.inactive_ind);
    }else if (admin === 'yes' && adminSortValue === 'Open') {
        subHeaderStatus = entity.nodes && entity.nodes.some((entity) => !entity.inactive_ind);
    }else if (admin === 'yes' && adminSortValue === 'Closed') {
        subHeaderStatus = entity.nodes && entity.nodes.some((entity) => entity.inactive_ind);
    }else {
        subHeaderStatus = entity.nodes && entity.nodes.some((entity) => !entity.inactive_ind && entity.display_ind === 1 && (entity.addr ? !Object.values(entity.addr).every(addr => addr === '') : false));
    }

    //Determine if the entity header needs to be that darker blue
    let entityStatus;
    if (entity.entities) {
        for (let subEntity of entity.entities) {
            if (subEntity.nodes) {
                if (subEntity.nodes.some((node) => !node.inactive_ind)) {
                    entityStatus = 'yes';
                }
            }
        }
    }
    
    // type  == levelsDeep+2
    // Update css with lvl classes
    // first map entity = Lvl 1
    // second map == lvl 2
    // third map == lvl 3
    // fourth map == lvl 4
    // fifth map == lvl 5

    return (
        <>
            {subHeaderStatus && 
                <Header type={`lvl${levelsDeep+2}`} view={view} key={entity.id}>{entity.nickname ? entity.nickname : entity.name} {entity.manager ? '-' : ''} {entity.manager ? entity.manager.first_name : ''} {entity.manager ? entity.manager.last_name : ''}</Header>
            }
            {entity.nodes && entity.nodes.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1).map((branch) => {
                let branchIcon = [];
                let inactiveStatus;
                let displayStatus;
                let addressStatus = false;
                if (branch.corps?.length > 0) {
                branchIcon = branch.corps.map(corp => {
                    return getBranchIcon(branch.name, corp?.corp_ss_id)
                })
                }else {
                branchIcon.push(getBranchIcon(branch.name, ""));
                }

                if (admin === 'yes' && adminSortValue === 'Physical') {
                inactiveStatus = !branch.inactive_ind;
                displayStatus = branch.display_ind === 1;
                }else if (admin === 'yes' && adminSortValue === 'Virtual') {
                inactiveStatus = !branch.inactive_ind;
                displayStatus = 1;
                //Include branches that do not have physical addresses
                if (branch.node_type_id===3) {
                    addressStatus = true;
                }
                }else if (admin === 'yes' && adminSortValue === 'Open') {
                inactiveStatus = !branch.inactive_ind; //to denote that all locations should be true
                displayStatus = 1;
                }else if (admin === 'yes' && adminSortValue === 'Closed') {
                inactiveStatus = branch.inactive_ind;
                displayStatus = 1;
                }else {
                inactiveStatus = !branch.inactive_ind;
                displayStatus = branch.display_ind === 1;
                if (!Object.values(branch.addr).every(addr => addr === '')) {
                    addressStatus = true;
                }
                }
                
                if (inactiveStatus && displayStatus && ((adminSortValue === 'Virtual' || admin !== 'yes') ? addressStatus : 1)) {
                increment++;
                return (
                    <BranchCard view={view} admin={admin} data={data} region={region} subEntity={entity} branch={branch} increment={increment} branchIcon={branchIcon} key={branch.id}/>
                )
                }
            })}
            {entity.entities && entity.entities.map(entity => {
                let newLevelsDeep = levelsDeep + 1;
                return (<div><EntityStructure entity={entity} adminSortValue={adminSortValue} admin={admin} data={data} view={view} region={region} increment={increment} levelsDeep={newLevelsDeep}/></div>)
            })}
        </>
    )
}

export {EntityStructure}