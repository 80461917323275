import React from 'react';

const DeactivateButton = ({clickRoute, children}) => {

  return  (
    <>
      <div className='deactivateButtonContainer' onClick={clickRoute}>
        <p>{children}</p>
        <svg className="deactivateIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <path id="Icon_metro-cancel" className="deactivateIcon" data-name="Icon metro-cancel" d="M9.571,1.928a7,7,0,1,0,7,7,7,7,0,0,0-7-7Zm0,12.688a5.688,5.688,0,1,1,5.688-5.688A5.688,5.688,0,0,1,9.571,14.616Zm2.187-9.188L9.571,7.616,7.383,5.428,6.071,6.741,8.258,8.928,6.071,11.116l1.312,1.313,2.187-2.187,2.187,2.188,1.313-1.312L10.883,8.928l2.188-2.187Z" transform="translate(-2.571 -1.928)" fill="#aa182c"/>
        </svg>
      </div>
    </>
  )
}

export default DeactivateButton;