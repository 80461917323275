import React, {useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router';
import BranchCard from '../../BranchCard';
import Select from 'react-select';
import {ORG_API_KEY, ORG_API_URL} from '../../../config/constants';

const EditBranch = ({view, data, admin, userType}) => {
  let routeState = useLocation().state;
  let branch = routeState.branch;
  let navigate = useNavigate();

  let managerName;
  for (let emp of data.oniniteData) {
    if (emp.id === branch.manager_id) {
      managerName = emp.first_name + " " + emp.last_name;
    }
  }
  const stateArr = [{name: "Alabama", abbrv: "AL"},{name: "Alaska", abbrv: "AK"},{name: "Arizona", abbrv: "AZ"},{name: "Arkansas", abbrv: "AR"},
  {name: "California", abbrv: "CA"},{name: "Colorado", abbrv: "CO"},{name: "Connecticut", abbrv: "CT"},{name: "Deleware", abbrv: "DE"},
  {name: "Florida", abbrv: "FL"},{name: "Georgia", abbrv: "GA"},{name: "Hawaii", abbrv: "HI"},{name: "Idaho", abbrv: "ID"},
  {name: "Illinois", abbrv: "IL"},{name: "Indiana", abbrv: "IN"},{name: "Iowa", abbrv: "IA"},{name: "Kansas", abbrv: "KS"},
  {name: "Kentucky", abbrv: "KY"},{name: "Louisiana", abbrv: "LA"},{name: "Maine", abbrv: "ME"},{name: "Maryland", abbrv: "MD"},
  {name: "Massachusetts", abbrv: "MA"},{name: "Michigan", abbrv: "MI"},{name: "Minnesota", abbrv: "MN"},{name: "Mississippi", abbrv: "MS"},
  {name: "Missouri", abbrv: "MO"},{name: "Montana", abbrv: "MT"},{name: "Nebraska", abbrv: "NE"},{name: "Nevada", abbrv: "NV"},
  {name: "New Hampshire", abbrv: "NH"},{name: "New Jersey", abbrv: "NJ"},{name: "New Mexico", abbrv: "NM"},{name: "New York", abbrv: "NY"},
  {name: "North Carolina", abbrv: "NC"},{name: "North Dakota", abbrv: "ND"},{name: "Ohio", abbrv: "OH"},{name: "Oklahoma", abbrv: "OK"},
  {name: "Oregon", abbrv: "OR"},{name: "Pennsylvania", abbrv: "PA"},{name: "Rhode Island", abbrv: "RI"},{name: "South Carolina", abbrv: "SC"},
  {name: "South Dakota", abbrv: "SD"},{name: "Tennessee", abbrv: "TN"},{name: "Texas", abbrv: "TX"},{name: "Utah", abbrv: "UT"},
  {name: "Vermont", abbrv: "VT"}, {name: "Virginia", abbrv: "VA"},{name: "Washington", abbrv: "WA"},{name: "West Virginia", abbrv: "WV"},
  {name: "Wisconsin", abbrv: "WI"},{name: "Wyoming", abbrv: "WY"}
  ];
  let stateOptions = stateArr.map(state => {
    return <option value={state.abbrv}>{state.name}</option>
  });
  let defaultStateOption = stateArr.find(ent => {
    return ent.abbrv === branch.addr?.state_abbrv;
  });

  let entitiesOptions = data.entData.map(ent => {
    return <option value={ent.id}>{ent.name}</option>
  });

  let corpOptions = [];
  if(Array.isArray(data.corpData)){
    for (let corp of data.corpData) {
      corpOptions.push({value: corp.corp_ss_id, label: corp.corp_name})
    }
  }else{
    corpOptions.push({value: corp.corp_ss_id, label: corp.corp_name})
  }

  // let nodeTypeOptions = [];
  //   for(let nodeType of data.nodeTypeData){
  //     if(!nodeType.inactive_ind) {
  //       nodeTypeOptions.push({value:nodeType.id,label:nodeType.name})
  //     } 
  //   }

    let nodeTypeOptions = data.nodeTypeData.map(type=>{
      if(!type.inactive_ind){
        return <option value = {type.id}>{type.name}</option>
      }  
    });
    
  
  let defaultCorpOptions = [];

  if (branch.corps) {
    if(Array.isArray(branch.corps)){
      for (let corp of branch.corps) {
        defaultCorpOptions.push({value: corp.corp_ss_id, label: corp.corp_name});
      }
    }else{
      defaultCorpOptions.push({value: corp.corp_ss_id, label: corp.corp_name});
    }
  }



  let ssBranchOptions = data.ssBranchData.map(ssbranch => {
    if (!data.branchData.some(branch => branch.ss_id === ssbranch.branch_id) || ssbranch.branch_id === branch.ss_id) {
      return <option value={ssbranch.branch_id}>{ssbranch.branch_name}</option>
    }
  });

  // // Generate list of all staffsuite branches that are active and that dont already have a rollup branch
  let associatedSSBranchOptions = [];
  for (let ssbranch of data.ssBranchData) {
    let node = data.branchData.find(branch => branch.ss_id === ssbranch.branch_id);
    if (node) {
      if (!data.branchData.some(branch => branch.rollup_node_id === node?.id) && !node?.inactive_ind && (node?.addr && Object.values(node?.addr).every(addr => addr === ''))) {
        associatedSSBranchOptions.push({value: ssbranch.branch_id, label: ssbranch.branch_name, ss_id: ssbranch.branch_id, id: node?.id});
      }
    }else {
      associatedSSBranchOptions.push({value: ssbranch.branch_id, label: ssbranch.branch_name, ss_id: ssbranch.branch_id, id: 0});
    }
  }

  // //Generate prefilled list of a branches associated SS branches
  
  let defaultAssociatedSSBranchOptions = [];
  for (let node of data.branchData) {
    if (node.rollup_node_id === branch.id) {
      // Updated this to exlude roll up nodes with different addresses than the parent
      let node_addr_string = Object.values(node.addr).join('')
      let branch_addr_string = Object.values(branch.addr).join('')
      
      if ((node.ss_id && node_addr_string === branch_addr_string) || ( node.ss_id && node.addr?.address_1 ==="")) {
        defaultAssociatedSSBranchOptions.push({value: node.ss_id, label: node.name, ss_id: node.ss_id, id: node.id,"node":node})
      }
    }
  }

  let rollupBranchOptions = data.branchData.map(rollBranch => {
    if (!rollBranch.inactive_ind && rollBranch.display_ind === 1 && !rollBranch.rollup_node_id) {
      return <option value={rollBranch.id}>{rollBranch.name}</option>
    }
  });

  const [submit, setSubmit] = useState(false);
  const [searchField, setSearchField] = useState(branch?.manager_id ? managerName : "");
  const [listDisplay, setListDisplay] = useState("none");
  const [filteredList, setFilteredList] = useState("");
  const [hideList, setHideList] = useState(false);

  const [branchName, setBranchName] = useState(branch.name ?? "");
  const [branchErr, setBranchErr] = useState(false);
  const [branchCode, setBranchCode] = useState(branch.code ?? "");
  const [branchCodeErr, setBranchCodeErr] = useState(false);
  const [address1, setAddress1] = useState(branch.addr?.address_1);
  const [address1Err, setAddress1Err] = useState(false);
  const [address2, setAddress2] = useState(branch.addr?.address_2);
  const [city, setCity] = useState(branch.addr?.city);
  const [cityErr, setCityErr] = useState("");
  const [state, setState] = useState(defaultStateOption?.abbrv ?? '');
  const [stateErr, setStateErr] = useState(false);
  const [zip, setZip] = useState(branch.addr?.zip);
  const [zipErr, setZipErr] = useState(false);
  const [phone, setPhone] = useState(branch?.phone);
  const [phoneErr, setPhoneErr] = useState(false);
  const [fax, setFax] = useState(branch?.fax);
  const [faxErr, setFaxErr] = useState(false);
  const [email, setEmail] = useState(branch?.email);
  const [emailErr, setEmailErr] = useState(false);
  const [region, setRegion] = useState(routeState.subEntity?.id ?? routeState.region?.id ?? '');
  const [regionErr, setRegionErr] = useState(false);
  const [manager, setManager] = useState(branch?.manager_id ?? "");
  const [managerErr, setManagerErr] = useState(false);
  const [locationChoice, setLocationChoice] = useState(branch?.node_type_id!=="3" ? true : false);
  const [ssBranchName, setSSBranchName] = useState(branch.ss_id ?? "");
  const [ssBranchNameErr, setSSBranchNameErr] = useState(false);
  const [associatedSSBranches, setAssociatedSSBranches] = useState(defaultAssociatedSSBranchOptions ?? []);
  const [rollupChoice, setRollupChoice] = useState(branch?.rollup_node_id ? 'yes' : 'no');
  const [rollup, setRollup] = useState(branch?.rollup_node_id ?? 0);
  const [rollupErr, setRollupErr] = useState(false);
  const [corp, setCorp] = useState(defaultCorpOptions ?? []);
  const [corpErr, setCorpErr] = useState(false);
  const [nodeType, setNodeType] = useState(branch?.node_type_id ?? 1);
  const [nodeTypeErr, setNodeTypeErr] = useState(false);
  const [branchNickname,setNickname] = useState(branch.nickname ??"")

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);

  // console.log(branch)

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);


  // Handling Searching For Manager Name
  const notInitialRender = useRef(false);
  useEffect(() => {
    if (notInitialRender.current) {
      setHideList(false);
      filterOniniteData();
    }else {
      notInitialRender.current = true;
    }
  }, [searchField]);

  // Handle Location Choice if set to Virtual Rollup type
  useEffect(()=>{
    if (nodeType == 3){
      setLocationChoice(false)
    }else{
      setLocationChoice(true)
    }
  },[nodeType])

  const filterOniniteData = () => {
    if (searchField !== "" && data.oniniteData !== null && !hideList) {
      let list = data.oniniteData.filter(emp => {
        if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.title && emp.title.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }
      });

      const setValue = (emp) => {
        setSearchField(emp.first_name + " " + emp.last_name);
        setManager(emp.id);
        setHideList(true);
      }
      
      if (list.length > 0) {
        setListDisplay("block");
        setFilteredList(list.map(emp => {
          if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.first_name} onClick={function () {setValue(emp)}}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.last_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase()) && emp.first_name) {
            return <p className='searchBranchItem' id={emp.first_name + "space"} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.title && emp.title.toLowerCase().includes(searchField.trim().toLowerCase())) {
            return <p className='searchBranchItem' id={emp.title} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name} <span className='searchHelperText'>({emp.title})</span></p>
          }
        }))
      }else  {
        setListDisplay("none");
        setFilteredList("");
        setManager('');
      }
    } else {
      setListDisplay("none");
      setFilteredList("");
      // setManager('');
    }
  }

  const handleSuccess = () => {
    navigate(`/Branch/${branch.id}`);
    window.location.reload();
  }

  const handleFailure = () => {
    window.location.reload();
  }

  const sendToApi = async () => {
    let corpArr = [];
    if(Array.isArray(corp)){
      for (let corpInd of corp) {
        corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label})
      }

    }else{
      corpArr.push({corp_ss_id: Number(corp.value), corp_name: corp.label})
    }
    // for (let corpInd of corp) {
    //   // 
    //   if (corp.length === 1) {
    //     corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label, passthrough_ind: false});
    //   }else if (corpInd.label === 'Onin Staffing') {
    //     corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label, passthrough_ind: true});
    //   }else {
    //     corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label, passthrough_ind: false});
    //   }
    // }
    let request_body = {
          id: branch.id,
          ss_id: Number(ssBranchName),
          name: branchName,
          display_name: branchNickname,
          code: locationChoice ? branchCode : "",
          phone: locationChoice ? phone : "",
          fax: locationChoice ? fax : "",
          email: locationChoice ? email : "",
          corps: corpArr,
          entity_id: Number(region),
          rollup_id: rollupChoice === 'yes' && ssBranchName !== "" ? Number(rollup) : 0,
          address_1: locationChoice ? address1 : "",
          address_2: locationChoice ? address2 : "",
          city: locationChoice ? city : "",
          state_abbrv: locationChoice ? state : "",
          zip_code: locationChoice ? zip : "",
          display_ind: locationChoice === false ? 2 : branch?.display_ind,
          inactive_ind: branch?.inactive_ind ?? false,
          manager_id: locationChoice ? Number(manager) : 0,
          node_type_id: Number(nodeType)

    }

    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify(request_body)
      });
      let resp = await response && response.json();
    
      resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }

    //----------------------------------- REMOVED ASSOCIATED SS BRANCH BOX-----------------------------------//
    
    // Handle Associated SS Branches logic
    //If all associated SS branches are removed, loop through and default all of their rollup_node_ids
    // if (associatedSSBranches.length === 0 && defaultAssociatedSSBranchOptions.length !== 0) {
  
    //   for (let assocBranch of defaultAssociatedSSBranchOptions) {
    //     try {
    //       let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
    //         method: 'PUT',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization' : `Bearer ${ORG_API_KEY}`
    //         },
    //         body: JSON.stringify({
    //           id: assocBranch.id,
    //           ss_id: Number(assocBranch.ss_id),
    //           name: assocBranch.label,
    //           display_name: assocBranch.label,
    //           code: locationChoice ? branchCode : "",
    //           phone: locationChoice ? phone : "",
    //           fax: locationChoice ? fax : "",
    //           email: locationChoice ? email : "",
    //           corps: corpArr,
    //           entity_id: locationChoice ? Number(region) : 0,
    //           rollup_id: 0,
    //           address_1: locationChoice ? address1 : "",
    //           address_2: locationChoice ? address2 : "",
    //           city: locationChoice ? city : "",
    //           state_abbrv: locationChoice ? state : "",
    //           zip_code: locationChoice ? zip : "",
    //           display_ind: 2,
    //           inactive_ind: branch?.inactive_ind ?? false,
    //           manager_id: locationChoice ? Number(manager) : 0
    //         })
    //       });
    //       let resp = await response && response.json();
    //       resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    // }else {
    //   for (let assocBranch of associatedSSBranches) {
    //     //If the SS branch is not in the node tables in org, make that branch; otherwise, just update it
    //     if (!data.branchData.some(branch => branch.ss_id === assocBranch.ss_id)) {
    //       try {
    //         let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
    //           method: 'POST',
    //           headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization' : `Bearer ${ORG_API_KEY}`
    //           },
    //           body: JSON.stringify({
    //             id: 0,
    //             ss_id: Number(assocBranch.ss_id),
    //             name: assocBranch.label,
    //             display_name: assocBranch.label,
    //             code: locationChoice ? branchCode : "",
    //             phone: locationChoice ? phone : "",
    //             fax: locationChoice ? fax : "",
    //             email: locationChoice ? email : "",
    //             corps: corpArr,
    //             entity_id: locationChoice ? Number(region) : 0,
    //             rollup_id: branch.id,
    //             address_1: locationChoice ? address1 : "",
    //             address_2: locationChoice ? address2 : "",
    //             city: locationChoice ? city : "",
    //             state_abbrv: locationChoice ? state : "",
    //             zip_code: locationChoice ? zip : "",
    //             display_ind: 2,
    //             inactive_ind: branch?.inactive_ind ?? false,
    //             manager_id: locationChoice ? Number(manager) : 0
    //           })
    //         });
    //         let resp = await response && response.json();
    //         resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }else {
    //       //If one of the associated branches has been removed from the list, remove it, otherwise update it
    //       if (defaultAssociatedSSBranchOptions.some(defBranch => defBranch.ss_id === assocBranch.ss_id) && !associatedSSBranches.some(assBranch => assBranch.ss_id === assocBranch.ss_id)) {
    //         try {
    //           let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
    //             method: 'PUT',
    //             headers: {
    //               'Content-Type': 'application/json',
    //               'Authorization' : `Bearer ${ORG_API_KEY}`
    //             },
    //             body: JSON.stringify({
    //               id: assocBranch.id,
    //               ss_id: Number(assocBranch.ss_id),
    //               name: assocBranch.label,
    //               display_name: assocBranch.label,
    //               code: locationChoice ? branchCode : "",
    //               phone: locationChoice ? phone : "",
    //               fax: locationChoice ? fax : "",
    //               email: locationChoice ? email : "",
    //               corps: corpArr,
    //               entity_id: locationChoice ? Number(region) : 0,
    //               rollup_id: 0,
    //               address_1: locationChoice ? address1 : "",
    //               address_2: locationChoice ? address2 : "",
    //               city: locationChoice ? city : "",
    //               state_abbrv: locationChoice ? state : "",
    //               zip_code: locationChoice ? zip : "",
    //               display_ind: 2,
    //               inactive_ind: branch?.inactive_ind ?? false,
    //               manager_id: locationChoice ? Number(manager) : 0
    //             })
    //           });
    //           let resp = await response && response.json();
    //           resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    //         } catch (error) {
    //           console.error(error);
    //         }
    //       }else {
    //         try {
    //           let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
    //             method: 'PUT',
    //             headers: {
    //               'Content-Type': 'application/json',
    //               'Authorization' : `Bearer ${ORG_API_KEY}`
    //             },
    //             body: JSON.stringify({
    //               id: assocBranch.id,
    //               ss_id: Number(assocBranch.ss_id),
    //               name: assocBranch.label,
    //               display_name: assocBranch.label,
    //               code: locationChoice ?branchCode : "",
    //               phone: locationChoice ? phone : "",
    //               fax: locationChoice ? fax : "",
    //               email: locationChoice ? email : "",
    //               corps: corpArr,
    //               entity_id: locationChoice ? Number(region) : 0,
    //               rollup_id: branch.id,
    //               address_1: locationChoice ? address1 : "",
    //               address_2: locationChoice ? address2 : "",
    //               city: locationChoice ? city : "",
    //               state_abbrv: locationChoice ? state : "",
    //               zip_code: locationChoice ? zip : "",
    //               display_ind: 2,
    //               inactive_ind: branch?.inactive_ind ?? false,
    //               manager_id: locationChoice ? Number(manager) : 0
    //             })
    //           });
    //           let resp = await response && response.json();
    //           resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
    //         } catch (error) {
    //           console.error(error);
    //         }
    //       }
    //     }
    //   }
    // } 
  }

  //Checking inputs before sending to API
  useEffect(() => {
    if (submit) {
      //No errors present -> proceed to endpoint call
      if (branchErr === 'none' && (locationChoice ? branchCodeErr === 'none' : branchCodeErr === 'yes' || branchCodeErr === 'none') && (locationChoice ? address1Err === 'none' : address1Err === 'yes' || address1Err === 'none') && (locationChoice ? cityErr === 'none' : cityErr === 'yes' || cityErr === 'none') && (locationChoice ? zipErr === 'none' : zipErr === 'yes' || zipErr === 'none') && (locationChoice ? phoneErr === 'none' : phoneErr === 'yes' || phoneErr === 'none') && (locationChoice ? faxErr === 'none' : faxErr === 'none' || faxErr === 'yes') && (locationChoice ? emailErr === 'none' : emailErr === 'yes' || emailErr === 'none') && (locationChoice ? managerErr === 'none' : managerErr === 'none' || managerErr === 'yes') && (locationChoice ? regionErr === 'none' : regionErr === 'yes' || regionErr === 'none') && (locationChoice ? ssBranchNameErr === 'none' : ssBranchNameErr === 'none' || ssBranchNameErr === '') && (locationChoice ? stateErr === 'none' : stateErr === 'yes' || stateErr === 'none')  && (rollupChoice === 'yes' || ssBranchName === '' ? rollupErr === 'none' : rollupErr === 'yes' || rollupErr === 'none') && corpErr === 'none') {
        sendToApi();
      }
    }
  }, [submit])

  //Handling error checking upon submit
  const handleSubmit = () => {
    let zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    let zipRes = zipReg.test(zip);
    let emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let emailRes = emailReg.test(email);
    let phoneReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
    let phoneRes = phoneReg.test(phone);
    let faxReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
    let faxRes = faxReg.test(fax);

    branchName.trim() === "" && userType === 'admin' ? setBranchErr('yes') : setBranchErr('none');
    (branchCode.trim() === "" || branchCode.length < 4) && locationChoice && userType === 'admin' ? setBranchCodeErr('yes') : setBranchCodeErr('none');
    address1?.trim() === "" && locationChoice ? setAddress1Err('yes') : setAddress1Err('none');
    city?.trim() === "" && locationChoice ? setCityErr('yes') : setCityErr('none');
    zip?.trim() === "" || !zipRes && locationChoice ? setZipErr('yes') : setZipErr('none');
    (phone?.trim() === "" || phoneRes) && locationChoice ? setPhoneErr('yes') : setPhoneErr('none');
    (fax && fax?.trim() !== "" && faxRes) ? setFaxErr('yes') : setFaxErr('none');
    email?.trim() === "" || !emailRes && locationChoice ? setEmailErr('yes') : setEmailErr('none');
    region === '' && locationChoice && userType === 'admin' ? setRegionErr('yes') : setRegionErr('none');
    manager === '' && locationChoice ? setManagerErr('yes') : setManagerErr('none');
    ssBranchName === '' && locationChoice && userType === 'admin' ? setSSBranchNameErr('yes') : setSSBranchNameErr('none');
    state === '' && locationChoice ? setStateErr('yes') : setStateErr('none');
    rollup == 0 && ssBranchName !== "" && userType === 'admin' ? setRollupErr('yes') : setRollupErr('none');
    corp.length === 0 && userType === 'admin' ? setCorpErr('yes') : setCorpErr('none');

    if (submit) {
      (async () => {
        setSubmit(false);
      })().then(() => setSubmit(true))
    }else {
      setSubmit(true);
    }
  }

  //Handling error checking on input change
  useEffect(() => {
    if (cityErr) {
      city?.trim() !== "" && city ? setCityErr('none'): setCityErr('yes');
    }
    if(address1Err) {
      address1?.trim() !== "" && address1 ? setAddress1Err('none') : setAddress1Err('yes')
    }
    if (branchErr) {
      branchName?.trim() !== "" ? setBranchErr('none') : setBranchErr('yes'); 
    }
    if (branchCodeErr) {
      branchCode?.trim() !== "" ? setBranchCodeErr('none') : setBranchCodeErr('yes'); 
    }
    if (zipErr) {
      let zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      let zipRes = zipReg.test(zip);

      zip?.trim() !== "" && zip && zipRes ? setZipErr('none') : setZipErr('yes');
    }
    if(phoneErr) {
      let phoneReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
      let phoneRes = phoneReg.test(phone);

      phone?.trim() !== "" && phone && !phoneRes ? setPhoneErr('none') : setPhoneErr('yes');
    }
    if (faxErr) {
      let faxReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
      let faxRes = faxReg.test(fax);

      if (fax && fax.trim() !== "") {
        !faxRes ? setFaxErr('none') : setFaxErr('yes');
      }else {
        setFaxErr('none');
      }
    }
    if (emailErr) {
      let emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let emailRes = emailReg.test(email);

      email?.trim() !== "" && emailRes ? setEmailErr('none') : setEmailErr('yes');
    }
    if (regionErr) {
      region !== "" ? setRegionErr('none') : setRegionErr('yes');
    }
    if(ssBranchNameErr) {
      ssBranchName !== "" ? setSSBranchNameErr('none') : setSSBranchNameErr('yes');
    }
    if (stateErr) {
      state !== "" ? setStateErr('none') : setStateErr('yes');
    }
    if (rollupErr) {
      rollup !== 0 || ssBranchName === "" ? setRollupErr('none') : setRollupErr('yes');
    }
    if(managerErr) {
      manager !== "" ? setManagerErr('none') : setManagerErr('yes');
    }
    if (corpErr) {
      corp.length !== 0 ? setCorpErr('none') : setCorpErr('yes');
    }
    if (!locationChoice) {
      setAddress1Err('none');
      setCityErr('none');
      setStateErr('none');
      setZipErr('none');
      setPhoneErr('none');
      setEmailErr('none');
      setRegionErr('none');
      setSSBranchNameErr('none');
      setManagerErr('none');
      setBranchCodeErr('none');
      setFaxErr('none');
    }
    if (!locationChoice && ssBranchName === "") {
      setRollupErr('none');
    }
    if (nodeType === "") {
      setNodeTypeErr('yes');
    }
  }, [city, address1, branchCode, branchName, zip, phone, email, region, ssBranchName, state, rollupChoice, rollup, manager, locationChoice, corp, fax,nodeType]);

  return (
    <>
      <div className='editBranchHeaderCard'>
        <BranchCard view={view} data={data} region={routeState.region} subEntity={routeState.subEntity} branch={branch} branchIcon={routeState.branchIcon} increment={1}/>
      </div>
      <div className='mainBranchContainer' onClick={() => listDisplay !== 'none' ? setListDisplay('none') : ""}>
        <div className='editBranchLocationHeaderContainer editBranchBottomSpacing'>
          <p className='editBranchHeader editBranchBottomSpacing'>Edit {branch.name}</p>
          {userType === 'admin' &&
          <>
           <label htmlFor='editnodeType' className={`subHeaderBottomSpacing`}>Branch Type</label>
              <div className='editBranchBottomSpacing'>
                <select className={`branchInput ${nodeTypeErr === 'yes' && 'errorInputBorder'}`} id='editNodeType' value={nodeType} onChange={(e) => setNodeType(e.target.value)} onBlur={(e) => setNodeType(e.target.value)}>
                  <option value="">Select</option>
                  {nodeTypeOptions}
                </select>
                {nodeTypeErr === 'yes' && <p className='errInputSubText'>Please select a Branch Type</p>}
              </div>
           </>
          }
          {/* {userType === 'admin' &&
            <>
              <p className='radioHeaderBottomSpacing'>Physical Location?</p>
              <div>
                <input type='radio' name='location' id='yesLocation' defaultChecked={locationChoice} className='branchRadioButton radioButtonBottomSpacing' onChange={(e) => setLocationChoice(true)}/>
                <label htmlFor='yesLocation'>Yes</label>
              </div>
              <div>
                <input type='radio' name='location' id='noLocation' defaultChecked={!locationChoice} className='branchRadioButton' onChange={(e) => setLocationChoice(false)}/>
                <label htmlFor='noLocation'>No</label>
              </div>
            </>
          } */}
        </div>
        <div className='branchInputOuterContainer'>
          <div className={`branchInputColumnContainer`}>
            {userType === 'admin' &&
              <>
                <label htmlFor='branchName' className='subHeaderBottomSpacing'>Branch Name</label>
                <div className='inputBottomSpacing'>
                  <input type="text" id='branchName' maxLength='50' className={`branchInput ${branchErr === 'yes' && 'errorInputBorder'}`} value={branchName} onChange={(e) => setBranchName(e.target.value)}/>
                  {branchErr === 'yes' && <p className='errInputSubText'>Please enter a branch name</p>}
                </div>
              </>
            }
            <div className={`${!locationChoice && 'disabledInputs'}`}>
              {userType === 'admin' &&
                <>
                  <label htmlFor='branchCode' className='subHeaderBottomSpacing'>Branch Code</label>
                  <div className='inputBottomSpacing'>
                    <input type="text" id='branchCode' maxLength='4' className={`branchInput ${branchCodeErr === 'yes' && locationChoice && 'errorInputBorder'}`} value={branchCode} onChange={(e) => setBranchCode(e.target.value)}/>
                    {branchCodeErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter a branch code</p>}
                  </div>
                </>
              }
              <label htmlFor='editBranchAddress1' className='subHeaderBottomSpacing'>Address</label>
              <div className='subHeaderBottomSpacing'>
                <input type="text" id='editBranchAddress1' className={`branchInput ${address1Err === 'yes' && locationChoice && 'errorInputBorder'}`} value={address1} onChange={(e) => setAddress1(e.target.value)} placeholder='Street address'/>
                {address1Err === 'yes' && locationChoice && <p className='errInputSubText'>Please enter an address</p>}
              </div>
              <input type="text" id='editBranchAddress2' className='inputBottomSpacing branchInput' value={address2} onChange={(e) => setAddress2(e.target.value)} placeholder='Suite, unit, building (optional)'/>
              <label htmlFor='editBranchCity' className='subHeaderBottomSpacing'>City</label>
              <div className='inputBottomSpacing'>
                <input type="text" id='editBranchCity' className={`branchInput ${cityErr === 'yes' && locationChoice && 'errorInputBorder'}`} value={city} onChange={(e) => setCity(e.target.value)}/>
                {cityErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter a city</p>}
              </div>
              <div className='stateZipContainer inputBottomSpacing'>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchState' className='subHeaderBottomSpacing'>State</label>
                  <select value={state} className={`branchInputSmall ${stateErr === 'yes' && locationChoice && 'errorInputBorder'}`} onChange={(e) => setState(e.target.value)} onBlur={(e) => setState(e.target.value)}>
                    <option value="">Select</option>
                    {stateOptions}
                  </select>
                  {stateErr === 'yes' && locationChoice && <p className='errInputSubText'>Please select a state</p>}
                </div>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchZip' className='subHeaderBottomSpacing'>Zip Code</label>
                  <input type="text" id='editBranchZip' className={`branchInputSmall ${zipErr === 'yes' && locationChoice && 'errorInputBorder'}`} value={zip} onChange={(e) => setZip(e.target.value)}/>
                  {zipErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter a zip code</p>}
                </div>
              </div>
              <div className='phoneFaxContainer inputBottomSpacing'>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchPhone' className='subHeaderBottomSpacing'>Phone</label>
                  <input type="text" id='editBranchPhone' className={`branchInputSmall ${phoneErr === 'yes' && locationChoice && 'errorInputBorder'}`} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                  {phoneErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter a phone number</p>}
                </div>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchFax' className='subHeaderBottomSpacing'>Fax (optional)</label>
                  <input type="text" id='editBranchFax' className={`branchInputSmall ${faxErr === 'yes' && locationChoice && 'errorInputBorder'}`} value={fax} onChange={(e) => setFax(e.target.value)}/>
                  {faxErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter a valid fax number</p>}
                </div>
              </div>
              <label htmlFor='editBranchEmail' className='subHeaderBottomSpacing'>Email</label>
              <div className='inputBottomSpacing'>
                <input type="text" id='editBranchEmail' className={`branchInput ${emailErr === 'yes' && locationChoice && 'errorInputBorder'}`} value={email} onChange={(e) => setEmail(e.target.value)}/>
                {emailErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter an email</p>}
              </div>
            </div>
          </div>
          <div className='branchInputColumnContainer'>
            <label htmlFor='branchNickname' className='subHeaderBottomSpacing'>Branch Nickname</label>
            <div className='editBranchBottomSpacing'>
              <input type="text" id='nickname' maxLength='50' className={`branchInput`} value={branchNickname} onChange={(e) => setNickname(e.target.value)}/>
            </div>
            <label htmlFor='editBranchRegion' className={`subHeaderBottomSpacing ${!locationChoice && 'disabledInputs'}`}>Manager Name</label>
            <div className='editBranchBottomSpacing'>
              <input 
              className={`branchInput ${managerErr === 'yes' && 'errorInputBorder'} ${!locationChoice && 'disabledInputs'}`}
              type='text'
              placeholder='Search Ōninites'
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              />
              <div className='searchOniniteResultList' style={{ 'display': listDisplay, 'top': view === 'mobile' ? userType === 'admin' ? '605px' : '572px' : userType === 'admin' ? '617px' : '542px' }}>
                {filteredList}
              </div>
              {managerErr === 'yes' && locationChoice && <p className='errInputSubText'>Please enter a manager name</p>}
            </div>
            {userType === 'admin' &&
              <>
              {/* //--------------- MAKE REGION EDITABLE ON NODES WITHOUT PHYSICAL LOCATION ---------------//  */}
                {/* {!branch.rollup_node_id &&
                  <>
                    <label htmlFor='editBranchRegion' className={`subHeaderBottomSpacing ${!locationChoice && 'disabledInputs'}`}>Region/Area</label>
                    <div className='editBranchBottomSpacing'>
                      <select className={`branchInput ${!locationChoice && 'disabledInputs'} ${regionErr === 'yes' && locationChoice && 'errorInputBorder'}`} id='editBranchRegion' value={region} onChange={(e) => setRegion(e.target.value)} onBlur={(e) => setRegion(e.target.value)}>
                        <option value="">Select</option>
                        {entitiesOptions}
                      </select>
                      {regionErr === 'yes' && locationChoice && <p className='errInputSubText'>Please select a region or area</p>}
                    </div>
                  </>
                } */}
                 <>
                    <label htmlFor='editBranchRegion' className={`subHeaderBottomSpacing`}>Region/Area</label>
                    <div className='editBranchBottomSpacing'>
                      <select className={`branchInput ${regionErr === 'yes' && 'errorInputBorder'}`} id='editBranchRegion' value={region} onChange={(e) => setRegion(e.target.value)} onBlur={(e) => setRegion(e.target.value)}>
                        <option value="">Select</option>
                        {entitiesOptions}
                      </select>
                      {regionErr === 'yes' && <p className='errInputSubText'>Please select a region or area</p>}
                    </div>
                  </>
                <label htmlFor='corp' className='subHeaderBottomSpacing'>Corporation</label>
                <div className='editBranchBottomSpacing'>
                  <Select 
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#004987",
                        parimary75: "#004987"
                      }
                    })}
                    styles={{ 
                      control: base => ({
                        ...base,
                        border: corpErr === 'yes' ? '2px solid #AA182C' : '1px solid',
                        '&:hover' : {
                          border: corpErr === 'yes' ? '2px solid #AA182C' : '1px solid',
                        }
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: 'black'
                      })
                    }}
                    value={corp}
                    options={corpOptions}
                    isMulti={false}
                    onChange={(selectedOption) => setCorp(selectedOption)}
                  />
                  {corpErr === 'yes' && <p className='errInputSubText'>Please select a corporation</p>}
                </div>
                <label htmlFor='editBranchName' className='subHeaderBottomSpacing'>Primary StaffSuite Branch</label>
                <div className='editBranchBottomSpacing'>
                  <select className={`branchInput ${ssBranchNameErr === 'yes' && locationChoice && 'errorInputBorder'}`} id='editBranchName' onChange={(e) => setSSBranchName(e.target.value)} onBlur={(e) => setSSBranchName(e.target.value)} value={ssBranchName}>
                    <option value=''>Select</option>
                    <option value={0}>None</option>
                    {ssBranchOptions}
                  </select>
                  {ssBranchNameErr === 'yes' && locationChoice && <p className='errInputSubText'>Please select a StaffSuite branch name</p>}
                </div>
                {/* <label htmlFor='editBranchName' className='subHeaderBottomSpacing'>Associated StaffSuite Branches (optional)</label> */}
                {/* <div className='editBranchBottomSpacing'> */}
                  {/* <Select 
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#004987",
                        parimary75: "#004987"
                      }
                    })}
                    styles={{ 
                      control: base => ({
                        ...base,
                        border: '1px solid',
                        '&:hover' : {
                          border: '1px solid',
                        }
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: 'black'
                      })
                    }}
                    value={associatedSSBranches}
                    options={associatedSSBranchOptions}
                    isMulti={true}
                    onChange={(selectedOption) => setAssociatedSSBranches(selectedOption)}
                  /> */}
                {/* </div> */}
                <div className={`editBranchBottomSpacing ${ssBranchName === "" && 'disabledInputs'}`}>
                  <p className='radioHeaderBottomSpacing'>Rollup revenue to standard branch?</p>
                  <div>
                    <input type='radio' name='rollup' id='yesRollup' className='branchRadioButton radioButtonBottomSpacing' onChange={(e) => setRollupChoice('yes')} defaultChecked={branch.rollup_node_id}/>
                    <label htmlFor='yesRollup'>Yes</label>
                  </div>
                  <div>
                    <input type='radio' name='rollup' id='noRollup' className='branchRadioButton' onChange={(e) => setRollupChoice('no')} defaultChecked={!branch.rollup_node_id}/>
                    <label htmlFor='noRollup'>No</label>
                  </div>
                </div>
                <label htmlFor='editBranchRevenue' className={`subHeaderBottomSpacing ${rollupChoice === 'no' && 'disabledInputs'} ${ssBranchName === '' && 'disabledInputs'}`}>Rollup revenue to</label>
                <div className='inputBottomSpacing'>
                  <select className={`branchInput ${rollupChoice === 'no' && 'disabledInputs'} ${ssBranchName === "" && 'disabledInputs'} ${rollupErr === 'yes' && rollupChoice === 'yes' && 'errorInputBorder'}`} id='editBranchRevenue' value={rollup} onChange={(e) => setRollup(e.target.value)} onBlur={(e) => setRollup(e.target.value)}>
                    <option value={0}>Select</option>
                    {rollupBranchOptions}
                  </select>
                  {rollupErr === 'yes' && rollupChoice === 'yes' &&  <p className='errInputSubText'>Please select a rollup branch</p>}
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {showSuccessModal && 
        <div className='supportConfirmModal'>
          <div className='supportConfirmModalContent'>
            <p className='supportConfirmModalText'>Updates have been successfully made!</p>
            <button className='supportConfirmModalButton' onClick={handleSuccess}>Ok</button>
          </div>
        </div>
      }
      {showFailureModal && 
        <div className='supportConfirmModal'>
          <div className='supportConfirmModalContent'>
            <p className='supportConfirmModalText'>Updates were unsuccessfully saved. Please try again.</p>
            <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
          </div>
        </div>
      }
      <div className='branchButtonContainer'>
        <div>
          <button className='updateButton' onClick={handleSubmit}>Update</button>
          <button className='cancelButton' onClick={() => navigate(-1)}>Cancel</button>
        </div>
      </div>
      <div style={{ 'marginBottom': '25px' }}></div>
    </>
  )
}

export default EditBranch;