//Grab cookie token from the browser
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//Redirect to login page
function redirect(url) {
  window.location.href = `https://auth.oningizmos.com/accounts/login/?next=${url}`;
}

//Authenication logic
async function authorize(thisURL,token, allowedUsers, permissionState, setPermissionState, nameState, setNameState, photoState, setPhotoState, emailState,setEmailState, isLoggedIn, setIsLoggedIn) {
  fetch(`https://auth.oningizmos.com/api/session/${token}?format=json`).then(response => {
    if (!response.ok || response.status !== 200) {
      process.env.NODE_ENV !== 'development' && redirect(thisURL);
      return;
    }
    return response.json();
  }).then(data => {
    let email = data.user_email;
    let photo = data?.user_image;
    let name = data?.user_name;
    let expDate = data.expire_date;
    let permission_list = data.scopes;
    let date = new Date();
    let todayUTC = date.toISOString();

    //Token is expired
    if (expDate < todayUTC) {
      process.env.NODE_ENV !== 'development' && redirect(thisURL);
      return;
    }

    //User does not have access to phoninbook
    if (!permission_list?.PB || !allowedUsers.includes(permission_list?.PB)) {
      if (process.env.NODE_ENV !== 'development'){
        window.location.href = 'https://errorz.oningizmos.com/403.html'; 
      }
    }

    //Set the user's info in global state
    if (!permissionState) {
      setPermissionState(permission_list?.PB);
    }
    if (!nameState) {
      setNameState(name);
    }
    if(!photoState) {
      setPhotoState(photo);
    }
    if(!emailState) {
      setEmailState(email);
    }
    if (!isLoggedIn) {
      setIsLoggedIn(true);
    }
  });
}

export {getCookie, redirect, authorize}