import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router';
import Select from 'react-select';
import { ORG_API_URL, ORG_API_KEY } from '../../../config/constants';

const AddBranchForm = ({ view, data, admin }) => {
  // Generate list of all staffsuite branches that are active and that dont already have a rollup branch
  let associatedSSBranchOptions = [];
  for (let ssbranch of data.ssBranchData) {
    let node = data.branchData.find(branch => branch.ss_id === ssbranch.branch_id);
    if (node) {
      if (!data.branchData.some(branch => branch.rollup_node_id === node?.id) && !node?.inactive_ind && (node?.addr && Object.values(node?.addr).every(addr => addr === ''))) {
        associatedSSBranchOptions.push({value: ssbranch.branch_id, label: ssbranch.branch_name, ss_id: ssbranch.branch_id, id: node?.id});
      }
    }else {
      associatedSSBranchOptions.push({value: ssbranch.branch_id, label: ssbranch.branch_name, ss_id: ssbranch.branch_id, id: 0});
    }
  }

  const [submit, setSubmit] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [listDisplay, setListDisplay] = useState("none");
  const [filteredList, setFilteredList] = useState("");
  const [hideList, setHideList] = useState(false);

  const [branchName, setBranchName] = useState("");
  const [branchErr, setBranchErr] = useState(false);
  const [branchCode, setBranchCode] = useState("");
  const [branchCodeErr, setBranchCodeErr] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address1Err, setAddress1Err] = useState(false);
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [state, setState] = useState("");
  const [stateErr, setStateErr] = useState(false);
  const [zip, setZip] = useState("");
  const [zipErr, setZipErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [fax, setFax] = useState("");
  const [faxErr, setFaxErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [region, setRegion] = useState("");
  const [regionErr, setRegionErr] = useState(false);
  const [manager, setManager] = useState("");
  const [managerErr, setManagerErr] = useState(false);
  const [ssBranchName, setSSBranchName] = useState("");
  const [ssBranchNameErr, setSSBranchNameErr] = useState(false);
  const [associatedSSBranches, setAssociatedSSBranches] = useState([]);
  const [rollupChoice, setRollupChoice] = useState(false);
  const [rollupChoiceErr, setRollupChoiceErr] = useState(false);
  const [rollup, setRollup] = useState(0);
  const [rollupErr, setRollupErr] = useState(false);
  const [corp, setCorp] = useState([]);
  const [corpErr, setCorpErr] = useState(false);
  const [locationChoice, setLocationChoice] = useState(true);
  const [corpName, setCorpName] = useState("");
  const [nodeType, setNodeType] = useState("");
  const [nodeTypeErr, setNodeTypeErr] = useState(false);
  const [branchNickname,setNickname] = useState("");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  
  let navigate = useNavigate();
  let ssLinkStatus = "yes";

  const stateArr = [{name: "Alabama", abbrv: "AL"},{name: "Alaska", abbrv: "AK"},{name: "Arizona", abbrv: "AZ"},{name: "Arkansas", abbrv: "AR"},
  {name: "California", abbrv: "CA"},{name: "Colorado", abbrv: "CO"},{name: "Connecticut", abbrv: "CT"},{name: "Deleware", abbrv: "DE"},
  {name: "Florida", abbrv: "FL"},{name: "Georgia", abbrv: "GA"},{name: "Hawaii", abbrv: "HI"},{name: "Idaho", abbrv: "ID"},
  {name: "Illinois", abbrv: "IL"},{name: "Indiana", abbrv: "IN"},{name: "Iowa", abbrv: "IA"},{name: "Kansas", abbrv: "KS"},
  {name: "Kentucky", abbrv: "KY"},{name: "Louisiana", abbrv: "LA"},{name: "Maine", abbrv: "ME"},{name: "Maryland", abbrv: "MD"},
  {name: "Massachusetts", abbrv: "MA"},{name: "Michigan", abbrv: "MI"},{name: "Minnesota", abbrv: "MN"},{name: "Mississippi", abbrv: "MS"},
  {name: "Missouri", abbrv: "MO"},{name: "Montana", abbrv: "MT"},{name: "Nebraska", abbrv: "NE"},{name: "Nevada", abbrv: "NV"},
  {name: "New Hampshire", abbrv: "NH"},{name: "New Jersey", abbrv: "NJ"},{name: "New Mexico", abbrv: "NM"},{name: "New York", abbrv: "NY"},
  {name: "North Carolina", abbrv: "NC"},{name: "North Dakota", abbrv: "ND"},{name: "Ohio", abbrv: "OH"},{name: "Oklahoma", abbrv: "OK"},
  {name: "Oregon", abbrv: "OR"},{name: "Pennsylvania", abbrv: "PA"},{name: "Rhode Island", abbrv: "RI"},{name: "South Carolina", abbrv: "SC"},
  {name: "South Dakota", abbrv: "SD"},{name: "Tennessee", abbrv: "TN"},{name: "Texas", abbrv: "TX"},{name: "Utah", abbrv: "UT"},
  {name: "Vermont", abbrv: "VT"}, {name: "Virginia", abbrv: "VA"},{name: "Washington", abbrv: "WA"},{name: "West Virginia", abbrv: "WV"},
  {name: "Wisconsin", abbrv: "WI"},{name: "Wyoming", abbrv: "WY"}
  ];
  let stateOptions = stateArr.map(state => {
    return <option value={state.abbrv}>{state.name}</option>
  });
  let entitiesOptions = data.entData.map(ent => {
    return <option value={ent.id}>{ent.name}</option>
  });
  let corpOptions = [];
  for (let corp of data.corpData) {
    corpOptions.push({value: corp.corp_ss_id, label: corp.corp_name})
  }

  let ssBranchOptions = data.ssBranchData.map(ssbranch => {
    if (!data.branchData.some(branch => branch.ss_id === ssbranch.branch_id)) {
      return <option value={ssbranch.branch_id}>{ssbranch.branch_name}</option>
    }
  });

  let rollupBranchOptions = data.branchData.map(rollBranch => {
    if (!rollBranch.inactive_ind && rollBranch.display_ind === 1 && !rollBranch.rollup_node_id) {
      return <option value={rollBranch.id}>{rollBranch.name}</option>
    }
  });

  let nodeTypeOptions = data.nodeTypeData.map(type=>{
    if(!type.inactive_ind){
      return <option value = {type.id}>{type.name}</option>
    }  
  });
  

  //Handling searching for Manager list
  useEffect(() => {
    setHideList(false);
    filterOniniteData();
  }, [searchField]);

  // Handle Location Choice if set to Virtual Rollup type
  useEffect(()=>{
    if (nodeType == 3){
      setLocationChoice(false)
    }else{
      setLocationChoice(true)
    }
  },[nodeType])

  const filterOniniteData = () => {
    if (searchField !== "" && data.oniniteData !== null && !hideList) {
      let list = data.oniniteData.filter(emp => {
        if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase())) {
          return emp;
        }else if (emp.title && emp.title.toLowerCase().includes(searchField.toLowerCase())) {
          return emp;
        }
      });

      const setValue = (emp) => {
        setSearchField(emp.first_name + " " + emp.last_name);
        setManager(emp.id);
        setHideList(true);
      }
      
      if (list.length > 0) {
        setListDisplay("block");
        setFilteredList(list.map(emp => {
          if (emp.last_name && emp.last_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.first_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.first_name && emp.first_name.toLowerCase().includes(searchField.toLowerCase())) {
            return <p className='searchBranchItem' id={emp.last_name} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if ((emp.first_name?.toLowerCase() + " " + emp.last_name?.toLowerCase()).includes(searchField.toLowerCase()) && emp.first_name) {
            return <p className='searchBranchItem' id={emp.first_name + "space"} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name}</p>
          }else if (emp.title && emp.title.toLowerCase().includes(searchField.trim().toLowerCase())) {
            return <p className='searchBranchItem' id={emp.title} onClick={() => setValue(emp)}>{emp.first_name} {emp.last_name} <span className='searchHelperText'>({emp.title})</span></p>
          }
        }))
      }else  {
        setListDisplay("none");
        setFilteredList("");
        setManager('');
      }
    } else {
      setListDisplay("none");
      setFilteredList("");
      // setManager('');
    }
  }

  const handleSuccess = () => {
    navigate(`/Branches`);
    window.location.reload();
  }

  const handleFailure = () => {
    window.location.reload();
  }

  const sendToApi = async () => {
    let corpArr = [];
    
    for (let corpInd of corp) {
      corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label})
    }  

    // for (let corpInd of corp) {
    //   if (corp.length === 1) {
    //     corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label, passthrough_ind: false});
    //   }else if (corpInd.label === 'Onin Staffing') {
    //     corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label, passthrough_ind: true});
    //   }else {
    //     corpArr.push({corp_ss_id: Number(corpInd.value), corp_name: corpInd.label, passthrough_ind: false});
    //   }
    // }

    //Create the branch
    try {
      let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${ORG_API_KEY}`
        },
        body: JSON.stringify({
          id: 0,
          ss_id: ssBranchName ? Number(ssBranchName) : 0,
          name: branchName,
          display_name: branchNickname,
          code: locationChoice ? branchCode : "",
          phone:locationChoice ? phone : "",
          fax: locationChoice ? fax : "",
          email: locationChoice ? email : "",
          corps: corpArr,
          entity_id: Number(region),
          rollup_id: rollup && rollupChoice === 'yes' ? Number(rollup) : 0,
          address_1: locationChoice ? address1 : "",
          address_2: locationChoice ? address2 :"",
          city: locationChoice ? city:"",
          state_abbrv: locationChoice ? state:"",
          zip_code: locationChoice ? zip:"",
          display_ind: 1,
          inactive_ind: false,
          manager_id: locationChoice ? Number(manager):0,
          node_type_id: Number(nodeType)
        })
      });
      let resp = await response && response.json();


      //------------------ Removed Assoc Branch Box ------------------//
      //Now handle the adding of a branch's associated StaffSuite branches
      // if (resp?.message !== 'an error has occured' && response.status !== 500 && response.status !== 400) {
      //   //Check if any associated branches have been selected
      //   if (associatedSSBranches.length !== 0) {
      //     try {
      //       let response = await fetch(`${ORG_API_URL}/v1/org/node/v2`, {
      //         method: 'GET',
      //         headers: {
      //           'Content-Type': 'application/json',
      //           'Authorization': `Bearer ${ORG_API_KEY}`
      //         }
      //       });
      //       let branchesResponse = await response.json();
      //       //Loop through all branches to find the branch id of the newly created branch above
      //       for (let branch of branchesResponse) {
      //         if (branch.code === branchCode && branch.name === branchName) {
      //           let node_id = branch.id;
      //             for (let assocBranch of associatedSSBranches) {
      //               //If the SS branch is not in the node tables in org, make that branch; otherwise, just update it
      //               if (!data.branchData.some(branch => branch.ss_id === assocBranch.ss_id)) {
      //                 try {
      //                   let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
      //                     method: 'POST',
      //                     headers: {
      //                       'Content-Type': 'application/json',
      //                       'Authorization' : `Bearer ${ORG_API_KEY}`
      //                     },
      //                     body: JSON.stringify({
      //                       id: 0,
      //                       ss_id: Number(assocBranch.ss_id),
      //                       name: assocBranch.label,
      //                       display_name: assocBranch.label,
      //                       code: branchCode,
      //                       phone: phone,
      //                       fax: fax,
      //                       email: email,
      //                       corps: corpArr,
      //                       entity_id: Number(region),
      //                       rollup_id: node_id,
      //                       address_1: address1,
      //                       address_2: address2,
      //                       city: city,
      //                       state_abbrv: state,
      //                       zip_code: zip,
      //                       display_ind: 2,
      //                       inactive_ind: false,
      //                       manager_id: Number(manager)
      //                     })
      //                   });
      //                   let resp = await response && response.json();
      //                   resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
      //                 } catch (error) {
      //                   console.error(error);
      //                 }
      //               }else {
      //                 try {
      //                   let response = await fetch(`${ORG_API_URL}/v1/org/node`, {
      //                     method: 'PUT',
      //                     headers: {
      //                       'Content-Type': 'application/json',
      //                       'Authorization' : `Bearer ${ORG_API_KEY}`
      //                     },
      //                     body: JSON.stringify({
      //                       id: assocBranch.id,
      //                       ss_id: Number(assocBranch.ss_id),
      //                       name: assocBranch.label,
      //                       display_name: assocBranch.label,
      //                       code: branchCode,
      //                       phone: phone,
      //                       fax: fax,
      //                       email: email,
      //                       corps: corpArr,
      //                       entity_id: Number(region),
      //                       rollup_id: node_id,
      //                       address_1: address1,
      //                       address_2: address2,
      //                       city: city,
      //                       state_abbrv: state,
      //                       zip_code: zip,
      //                       display_ind: 2,
      //                       inactive_ind: false,
      //                       manager_id: Number(manager)
      //                     })
      //                   });
      //                   let resp = await response && response.json();
      //                   resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
      //                 } catch (error) {
      //                   console.error(error);
      //                 }
      //               }
      //           }
      //         }
      //       }
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   }else {
      //     resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
      //   }
      // }else {
      //   resp?.message === 'an error has occured' || response.status === 500 || response.status === 400 ? setShowFailureModal(true) : setShowSuccessModal(true);
      // }
    } catch (error) {
      console.error(error);
    }
  }

  //Handling final error check before send to API
  useEffect(() => {
    if (submit) {
      if (branchErr === 'none' && branchCodeErr === 'none' && address1Err === 'none' && cityErr === 'none' && stateErr === 'none' && zipErr === 'none' && phoneErr === 'none' && faxErr === 'none' && emailErr === 'none' && regionErr === 'none' && corpErr === 'none' && managerErr === 'none' && (ssLinkStatus === 'yes' ? ssBranchNameErr === 'none' && rollupChoiceErr === 'none' && rollupErr === 'none' : ssBranchNameErr === 'yes' && rollupChoiceErr === 'yes' && (rollupErr === 'yes' || rollupErr === 'none'))) {
        sendToApi();
      }
    }
  }, [submit]);

  //Handling error check upon submit
  const handleSubmit = () => {
    let zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    let zipRes = zipReg.test(zip);
    let emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let emailRes = emailReg.test(email);
    let phoneReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
    let phoneRes = phoneReg.test(phone);
    let faxReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
    let faxRes = faxReg.test(fax);

    branchName.trim() === "" ? setBranchErr('yes') : setBranchErr('none');
    (branchCode.trim() === "" || branchCode.length < 4) ? setBranchCodeErr('yes') : setBranchCodeErr('none');
    address1.trim() === "" ? setAddress1Err('yes') : setAddress1Err('none');
    city.trim() === "" ? setCityErr('yes') : setCityErr('none');
    zip.trim() === "" || !zipRes ? setZipErr('yes') : setZipErr('none');
    (phone.trim() === "" || phoneRes) ? setPhoneErr('yes') : setPhoneErr('none');
    (fax && fax.trim() !== "" && faxRes) ? setFaxErr('yes') : setFaxErr('none');
    email.trim() === "" || !emailRes ? setEmailErr('yes') : setEmailErr('none');
    region === '' ? setRegionErr('yes') : setRegionErr('none');
    manager === '' ? setManagerErr('yes'): setManagerErr('none');
    ssBranchName === '' ? setSSBranchNameErr('yes') : setSSBranchNameErr('none');
    corp.length === 0 ? setCorpErr('yes') : setCorpErr('none');
    state === '' ? setStateErr('yes') : setStateErr('none');
    !rollupChoice ? setRollupChoiceErr('yes') : setRollupChoiceErr('none');
    rollup == 0 && (rollupChoice === 'yes') ? setRollupErr('yes') : setRollupErr('none');

    if (submit) {
      (async () => {
        setSubmit(false);
      })().then(() => setSubmit(true))
    }else {
      setSubmit(true);
    }
  }

  //Handling error check upon input change
  useEffect(() => {
    if (cityErr) {
      city.trim() !== "" ? setCityErr('none'): setCityErr('yes');
    }
    if (branchErr) {
      branchName.trim() !== "" ? setBranchErr('none') : setBranchErr('yes'); 
    }
    if (branchCodeErr) {
      (branchCode.trim() !== "" && branchCode.length === 4) ? setBranchCodeErr('none') : setBranchCodeErr('yes'); 
    }
    if(address1Err) {
      address1.trim() !== "" ? setAddress1Err('none') : setAddress1Err('yes')
    }
    if (zipErr) {
      let zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      let zipRes = zipReg.test(zip);

      zip.trim() !== "" && zipRes ? setZipErr('none') : setZipErr('yes');
    }
    if(phoneErr) {
      let phoneReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
      let phoneRes = phoneReg.test(phone);

      phone.trim() !== "" && !phoneRes ? setPhoneErr('none') : setPhoneErr('yes');
    }
    if (emailErr) {
      let emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let emailRes = emailReg.test(email);

      email.trim() !== "" && emailRes ? setEmailErr('none') : setEmailErr('yes');
    }
    if (regionErr) {
      region !== "" ? setRegionErr('none') : setRegionErr('yes');
    }
    if(ssBranchNameErr) {
      ssBranchName !== "" ? setSSBranchNameErr('none') : setSSBranchNameErr('yes');
    }
    if (corpErr) {
      corp.length !== 0 ? setCorpErr('none') : setCorpErr('yes');
    }
    if (stateErr) {
      state !== "" ? setStateErr('none') : setStateErr('yes');
    }
    if (rollupChoiceErr) {
      rollupChoice ? setRollupChoiceErr('none') : setRollupChoiceErr('yes');
    }
    if (rollupErr) {
      rollup == 0 && rollupChoice === 'yes' ? setRollupErr('yes') : setRollupErr('none');
    }
    if(managerErr) {
      manager !== "" ? setManagerErr('none') : setManagerErr('yes');
    }
    if (faxErr) {
      let faxReg = /[a-zA-z!@#$%^&*_+=\[\]{};:"\\|,<>\/?]+/;
      let faxRes = faxReg.test(fax);

      if (fax && fax.trim() !== "") {
        !faxRes ? setFaxErr('none') : setFaxErr('yes');
      }else {
        setFaxErr('none');
      }
    }
    if (nodeType === "") {
      setNodeTypeErr('yes');
    }
    if (!locationChoice) {
      setAddress1Err('none');
      setCityErr('none');
      setStateErr('none');
      setZipErr('none');
      setPhoneErr('none');
      setEmailErr('none');
      setRegionErr('none');
      setSSBranchNameErr('none');
      setManagerErr('none');
      setBranchCodeErr('none');
      setFaxErr('none');
    }
  }, [city, branchName, branchCode, address1, zip, phone, email, region, ssBranchName, corp, state, rollupChoice, rollup, manager, fax,nodeType]);

  return (
    <>
      <div className='mainBranchContainer' onClick={() => listDisplay !== 'none' ? setListDisplay('none') : ""}>
          <p className='addBranchHeader'>Add Branch</p>
        <div className='branchInputOuterContainer'>
          
          <div className={`branchInputColumnContainer`}>
          <label htmlFor='editnodeType' className={`subHeaderBottomSpacing`}>Branch Type</label>
                <div className='editBranchBottomSpacing'>
                  <select className={`branchInput ${nodeTypeErr === 'yes' && 'errorInputBorder'}`} id='editNodeType' value={nodeType} onChange={(e) => setNodeType(e.target.value)} onBlur={(e) => setNodeType(e.target.value)}>
                    <option value="">Select</option>
                    {nodeTypeOptions}
                  </select>
                  {nodeTypeErr === 'yes' && <p className='errInputSubText'>Please select a Branch Type</p>}
                </div>
            <label htmlFor='branchName' className='subHeaderBottomSpacing'>Branch Name</label>
            <p className='subHeaderBottomSpacing subHeaderHelperText'>Do not name as rollup</p>
            <div className='inputBottomSpacing'>
              <input type="text" maxLength='50' id='branchName' className={`branchInput ${branchErr === 'yes' && 'errorInputBorder'}`} value={branchName} onChange={(e) => setBranchName(e.target.value)}/>
              {branchErr === 'yes' && <p className='errInputSubText'>Please enter a branch name</p>}
            </div>
            <div className={`${!locationChoice && 'disabledInputs'}`}>
              <label htmlFor='branchCode' className='subHeaderBottomSpacing'>Branch Code</label>
              <div className='inputBottomSpacing'>
                <input type="text" maxLength='4' minLength='4' id='branchCode' className={`branchInput ${branchCodeErr === 'yes' && 'errorInputBorder'}`} value={branchCode} onChange={(e) => setBranchCode(e.target.value)}/>
                {branchCodeErr === 'yes' && <p className='errInputSubText'>Please enter a branch code</p>}
              </div>
              <label htmlFor='editBranchAddress1' className='subHeaderBottomSpacing'>Address</label>
              <div className='subHeaderBottomSpacing'>
                <input type="text" id='editBranchAddress1' className={`branchInput ${address1Err === 'yes' && 'errorInputBorder'}`} value={address1} onChange={(e) => setAddress1(e.target.value)} placeholder='Street address'/>
                {address1Err === 'yes' && <p className='errInputSubText'>Please enter an address</p>}
              </div>
              <input type="text" id='editBranchAddress2' className='inputBottomSpacing branchInput' value={address2} onChange={(e) => setAddress2(e.target.value)} placeholder='Suite, unit, building (optional)'/>
              <label htmlFor='editBranchCity' className='subHeaderBottomSpacing'>City</label>
              <div className='inputBottomSpacing'>
                <input type="text" id='editBranchCity' className={`branchInput ${cityErr === 'yes' && 'errorInputBorder'}`} value={city} onChange={(e) => setCity(e.target.value)}/>
                {cityErr === 'yes' && <p className='errInputSubText'>Please enter a city</p>}
              </div>
              <div className='stateZipContainer inputBottomSpacing'>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchState' className='subHeaderBottomSpacing'>State</label>
                  <div className='smallInputContainer'>
                    <select className={`branchInputSmall ${stateErr === 'yes' && 'errorInputBorder'}`} value={state} onChange={(e) => setState(e.target.value)} onBlur={(e) => setState(e.target.value)}>
                      <option value="">Select</option>
                      {stateOptions}
                    </select>
                    {stateErr === 'yes' && <p className='errInputSubText'>Please select a state</p>}
                  </div>
                </div>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchZip' className='subHeaderBottomSpacing'>Zip Code</label>
                  <input type="text" id='editBranchZip' className={`branchInputSmall ${zipErr === 'yes' && 'errorInputBorder'}`} value={zip} onChange={(e) => setZip(e.target.value)}/>
                  {zipErr === 'yes' && <p className='errInputSubText'>Please enter a zip code</p>}
                </div>
              </div>
              <div className='phoneFaxContainer inputBottomSpacing'>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchPhone' className='subHeaderBottomSpacing'>Phone</label>
                  <input type="text" id='editBranchPhone' className={`branchInputSmall ${phoneErr === 'yes' && 'errorInputBorder'}`} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                  {phoneErr === 'yes' && <p className='errInputSubText'>Please enter a phone number</p>}
                </div>
                <div className='smallInputContainer'>
                  <label htmlFor='editBranchFax' className='subHeaderBottomSpacing'>Fax (optional)</label>
                  <input type="text" id='editBranchFax' className={`branchInputSmall ${faxErr === 'yes' && 'errorInputBorder'}`} value={fax} onChange={(e) => setFax(e.target.value)}/>
                  {faxErr === 'yes' && <p className='errInputSubText'>Please enter a valid fax number</p>}
                </div>
              </div>
              <label htmlFor='editBranchEmail' className='subHeaderBottomSpacing'>Email</label>
              <div className='inputBottomSpacing'>
                <input type="text" id='editBranchEmail' className={`branchInput ${emailErr === 'yes' && 'errorInputBorder'}`} value={email} onChange={(e) => setEmail(e.target.value)}/>
                {emailErr === 'yes' && <p className='errInputSubText'>Please enter an email</p>}
              </div>
            </div>
            </div>
            <div className='branchInputColumnContainer addBranchFormSecondColumnAdj'>
              <div className='inputBottomSpacing'>
              <label htmlFor='editBranchRegion' className={`subHeaderBottomSpacing ${!locationChoice && 'disabledInputs'}`}>Nickname</label>
                <input type="text" maxLength='50' id='branchNickname' className={`branchInput ${branchErr === 'yes' && 'errorInputBorder'}`} value={branchNickname} onChange={(e) => setNickname(e.target.value)}/>
              </div>
              <label htmlFor='editBranchRegion' className={`subHeaderBottomSpacing ${!locationChoice && 'disabledInputs'}`}>Manager Name</label>
              <div className='editBranchBottomSpacing'>
                <input 
                className={`branchInput ${managerErr === 'yes' && 'errorInputBorder'} ${!locationChoice && 'disabledInputs'}`}
                type='text'
                placeholder='Search Ōninites'
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                />
                <div className='searchOniniteResultList' style={{ 'display': listDisplay, 'top': view === 'mobile' ? '325px' : '345px' }}>
                  {filteredList}
                </div>
                {managerErr === 'yes' && <p className='errInputSubText'>Please enter a manager name</p>}
              </div>
            
            <label htmlFor='editBranchRegion' className={`subHeaderBottomSpacing`}>Region/Area</label>
            <div className='editBranchBottomSpacing'>
              <select className={`branchInput ${regionErr === 'yes' && 'errorInputBorder'}`} id='editBranchRegion' value={region} onChange={(e) => setRegion(e.target.value)} onBlur={(e) => setRegion(e.target.value)}>
                <option value="">Select</option>
                {entitiesOptions}
              </select>
              {regionErr === 'yes' && <p className='errInputSubText'>Please select a region or area</p>}
            </div>
            <label htmlFor='corp' className='subHeaderBottomSpacing'>Corporation</label>
            <div className='editBranchBottomSpacing'>
              <Select 
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#004987",
                    parimary75: "#004987"
                  }
                })}
                styles={{ 
                  control: base => ({
                    ...base,
                    border: corpErr === 'yes' ? '2px solid #AA182C' : '1px solid',
                    '&:hover' : {
                      border: corpErr === 'yes' ? '2px solid #AA182C' : '1px solid',
                    }
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: 'black'
                  })
                }}
                value={corp}
                options={corpOptions}
                isMulti={true}
                onChange={(selectedOption) => setCorp(selectedOption)}
              />
              {corpErr === 'yes' && <p className='errInputSubText'>Please select a corporation</p>}
            </div>
            {ssLinkStatus === 'yes' &&
              <>
                <label htmlFor='editBranchName' className='subHeaderBottomSpacing'>StaffSuite Branch Name</label>
                <div className='editBranchBottomSpacing'>
                  <select className={`branchInput ${ssBranchNameErr === 'yes' && 'errorInputBorder'}`} id='editBranchName' value={ssBranchName} onChange={(e) => setSSBranchName(e.target.value)} onBlur={(e) => setSSBranchName(e.target.value)}>
                    <option value="">Select</option>
                    {ssBranchOptions}
                  </select>
                  {ssBranchNameErr === 'yes' && <p className='errInputSubText'>Please select a StaffSuite branch name</p>}
                </div>
                {/* <label htmlFor='editBranchName' className='subHeaderBottomSpacing'>Associated StaffSuite Branches (optional)</label> */}
                {/* <div className='editBranchBottomSpacing'>
                  <Select 
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#004987",
                        parimary75: "#004987"
                      }
                    })}
                    styles={{ 
                      control: base => ({
                        ...base,
                        border: '1px solid',
                        '&:hover' : {
                          border: '1px solid',
                        }
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: 'black'
                      })
                    }}
                    value={associatedSSBranches}
                    options={associatedSSBranchOptions}
                    isMulti={true}
                    onChange={(selectedOption) => setAssociatedSSBranches(selectedOption)}
                  />
                </div> */}

                <div className='editBranchBottomSpacing'>
                  <p className='radioHeaderBottomSpacing'>Rollup revenue to standard branch?</p>
                  <div>
                    <input type='radio' name='rollup' id='yesRollup' className='branchRadioButton radioButtonBottomSpacing' onChange={(e) => setRollupChoice('yes')}/>
                    <label htmlFor='yesRollup'>Yes</label>
                  </div>
                  <div>
                    <input type='radio' name='rollup' id='noRollup' className='branchRadioButton' onChange={(e) => setRollupChoice('no')}/>
                    <label htmlFor='noRollup'>No</label>
                  </div>
                  {rollupChoiceErr === 'yes' && <p className='errInputSubText'>Please select the rollup status</p>}
                </div>
                <label htmlFor='branchRevenue' className={`subHeaderBottomSpacing ${(rollupChoice === 'no' || !rollupChoice) && 'disabledInputs'}`}>Which branch will revenue roll up to?</label>
                <div className='inputBottomSpacing'>
                  <select className={`branchInput ${rollupErr === 'yes' && 'errorInputBorder'} ${(rollupChoice === 'no' || !rollupChoice) && 'disabledInputs'}`} id='branchRevenue' onChange={(e) => setRollup(e.target.value)} onBlur={(e) => setRollup(e.target.value)} value={rollup}>
                    <option value={0}>Select</option>
                    {rollupBranchOptions}
                  </select>
                  {rollupErr === 'yes' && <p className='errInputSubText'>Please select a rollup branch</p>}
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {showSuccessModal && 
        <div className='supportConfirmModal'>
          <div className='supportConfirmModalContent'>
            <p className='supportConfirmModalText'>Branch has been successfully created!</p>
            <button className='supportConfirmModalButton' onClick={handleSuccess}>Ok</button>
          </div>
        </div>
      }
      {showFailureModal && 
        <div className='supportConfirmModal'>
          <div className='supportConfirmModalContent'>
            <p className='supportConfirmModalText'>Branch has been unsuccessfully created. Please try again.</p>
            <button className='supportConfirmModalButton' onClick={handleFailure}>Ok</button>
          </div>
        </div>
      }
      <div className='branchButtonContainer'>
        <div>
          <button className='addBranchButton' onClick={handleSubmit}>Add Branch</button>
          <button className='cancelButton' onClick={() => navigate(-1)}>Back</button>
        </div>
      </div>
      <div style={{ 'marginBottom': '25px' }}></div>
    </>
  )
};

export default AddBranchForm;