import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Branches from '../components/Branches';
import Oninites from '../components/Oninites';
import BranchDetail from '../components/BranchDetail';
import OniniteDetail from '../components/OniniteDetail';
import Search from '../components/Search';

import EditBranch from '../components/support/editBranch/EditBranch';
import AddBranch from '../components/support/addBranch/AddBranch';
import AddBranchVirtual from '../components/support/addBranch/AddBranchVirtual';
import AddBranchSS from '../components/support/addBranch/AddBranchSS';
import AddBranchForm from '../components/support/addBranch/AddBranchForm';
import AddBranchRollup from '../components/support/addBranch/AddBranchRollup';

import EditOninite from '../components/support/editOninite/EditOninite';

import ManageRegion from '../components/support/manageRegion/ManageRegion';
import EditRegion from '../components/support/manageRegion/EditRegion';
import AddRegion from '../components/support/manageRegion/addRegion/AddRegion';
import AddRegionForm from '../components/support/manageRegion/addRegion/AddRegionForm';

const Views = ({view, data, admin, userType}) => {
  return (
    <>
      <Routes>
        {/* Branch, Oninite and Search Pages */}
        <Route exact path="/" element={<Branches view={view} data={data} admin={admin} key={'branches'} userType={userType}/>}/>
        <Route path="Branches" element={<Branches view={view} data={data} admin={admin} key={'branches'} userType={userType}/>}/>
        <Route path="Branch">
            <Route path=":branch" element={<BranchDetail view={view} data={data} admin={admin} key={'branchDetail'} userType={userType}/>}/>
        </Route>
        <Route path="Oninites" element={<Oninites view={view} data={data} admin={admin} key={'oninites'} userType={userType}/>}/>
        <Route path="Oninite">
            <Route path=":emp" element={<OniniteDetail view={view} data={data} admin={admin} key={'oniniteDetail'} userType={userType}/>}/>
        </Route>
        <Route path="Search">
            <Route path=':search' element={<Search view={view} admin={admin} data={data} key={'search'} userType={userType}/>}/>
        </Route>
        
        {/* Support Branch Pages */}
        <Route path="EditBranch">
            <Route path=":branch" element={<EditBranch view={view} data={data} admin={admin} userType={userType}/>}/>
        </Route>
        <Route path="AddBranch">
          <Route index element={<AddBranchForm view={view} data={data} admin={admin} userType={userType}/>}/>
            {/* <Route index element={<AddBranch view={view} data={data} admin={admin} userType={userType}/>}/>
            <Route path='physical'>
                <Route index element={<AddBranchSS view={view} data={data} admin={admin} userType={userType}/>}/>
                <Route path='form'>
                    
                    <Route path='rollup' element={<AddBranchRollup view={view} data={data} admin={admin} userType={userType}/>}/>
                </Route>
            </Route>
            <Route path='virtual'>
              <Route index element={<AddBranchVirtual view={view} data={data} admin={admin} userType={userType}/>}/>
            </Route> */}
        </Route>

        {/* Support Oninite Pages */}
        <Route path="EditOninite">
          <Route path=":emp" element={<EditOninite view={view} data={data} admin={admin} userType={userType}/>}/>
        </Route>

        {/* Support Region/Area Pages */}
        <Route path="ManageRegion">
          <Route index element={<ManageRegion view={view} data={data} admin={admin} userType={userType}/>}/>
          <Route path="edit">
            <Route index element={<EditRegion view={view} data={data} admin={admin} userType={userType}/>}/>
          </Route>
        </Route>
        <Route path="AddRegion">
          <Route index element={<AddRegion view={view} data={data} admin={admin} userType={userType}/>}/>
          <Route path="add">
            <Route index element={<AddRegionForm view={view} data={data} admin={admin} userType={userType}/>}/>
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default Views;