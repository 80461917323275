import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

//Persisting global state in local storage
const { persistAtom } = recoilPersist({
  key: "persist-atom",
  storage: localStorage
});

//Consolidating a user's permission level into a globally available piece of state
export const permissionState = atom({
  key: 'permissionState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value),
  effects_UNSTABLE: [persistAtom]
});

//Consolidating a user's name into a globally available piece of state
export const nameState = atom({
  key: 'nameState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value),
  effects_UNSTABLE: [persistAtom]
});

//Consolidating a user's photo into a globally available piece of state
export const photoState = atom({
  key: 'photoState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value),
  effects_UNSTABLE: [persistAtom]
});

//Consolidating a user's email into a globally available piece of state
export const emailState = atom({
  key: 'emailState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value),
  effects_UNSTABLE: [persistAtom]
});

//Consolidating a user's logged in status into a globally available piece of state
export const loggedInState = atom({
  key: 'loggedInState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value),
  effects_UNSTABLE: [persistAtom]
});